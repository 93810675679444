import { PLAN_TYPES, PLANS, PLANS_V2 } from "src/components/Constants";
import {
  setUserIdentified,
  setPricingModalData,
  setShowUpgradePlanPopupChatPage,
  setShowScheduleMeeting,
  legalReviewButtonClickedIntent,
  setShowSignupToUpgradeModal,
  resetRequestLegalReviewFailureReason,
} from "./onboarding/onboardingSlice";
import {
  Sparkle,
  FolderLock,
  Briefcase,
  Bank,
  Building,
  Certificate,
  Receipt,
  MagnifyingGlass,
  HandDeposit,
  Note,
  Users,
  Medal,
  Pen,
  GitDiff,
  Cpu,
  Signature,
  LockKey,
  CalendarDots,
  Scroll,
  Copyright,
  FileMagnifyingGlass,
  HouseLine,
  Key,
  Handshake,
  ChartLineUp,
  Clipboard,
  Scales,
  Gavel,
  Trademark,
  Lock,
  Envelope,
} from "@phosphor-icons/react";
import { useDispatch } from "react-redux";
import {
  loadThreadTryPage,
  resetThreadData,
  setAwaitingDraftQuestionsResponse,
  setAwaitingResponse,
  setMessagingDisabled,
} from "./chatpage/messageSlice";
import {
  getCheckoutUrl,
  resetChatPage,
  setPaymentCancellationScreen,
  setSelectedPaymentPlanName,
  setUserSelectedPlan,
} from "./chatpage/chatSlice";
import { resetData, resetReferenceDocDownloadUrl, setOpenUpgradeToProPopup } from "./chatpage/threadsSlice";
import { setSelectedParties } from "./Homepage/HomeSlice";
import { ErrorLogging } from "src/components/DocumentViewer/helpers";
import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

export function extractErrors(response) {
  const errors = {
    fieldErrors: {},
    genericErrors: [],
  };
  if (response && (response.status === 404 || response?.response?.status === 404)) {
    errors.genericErrors.push("The requested resource was not found.");
  }
  if (response && (response.status >= 500 || response?.response?.status >= 500)) {
    errors.genericErrors.push("Something went wrong. Please try again later.");
  } else if (
    response &&
    response.name &&
    response.name === "AxiosError" &&
    (response.status !== 422 || response?.response?.status !== 422)
  ) {
    errors.genericErrors.push(response.message);
  }
  if (response && response?.response?.status === 422) {
    const detail = response.response.data.detail;
    if (detail && detail.length > 0) {
      detail.forEach((error) => {
        if (error.loc && error.loc.length > 0) {
          if (!errors.fieldErrors[error.loc[1]]) {
            errors.fieldErrors[error.loc[1]] = [];
          }
          errors.fieldErrors[error.loc[1]].push(error.msg);
        } else {
          errors.genericErrors.push(error.msg);
        }
      });
    }
  } else {
    if (response && response.data && response.data.errors) {
      response.data.errors.forEach((error) => {
        if (error.field) {
          if (!errors.fieldErrors[error.field]) {
            errors.fieldErrors[error.field] = [];
          }
          errors.fieldErrors[error.field].push(error.message);
        } else {
          errors.genericErrors.push(error.message);
        }
      });
    }
  }

  return errors;
}

export const trackAnalytics = (event, data, metadata) => {
  data = data || {};
  data["user_id"] = metadata?.user_id;
  const analytics = getAnalytics();
  analytics.track(event, data);
};

export const clearTokens = () => {
  try {
    localStorage.removeItem("accessToken2");
    localStorage.removeItem("refreshToken2");
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    localStorage.removeItem("client_details");
    localStorage.removeItem("lawyer_info");
    localStorage.removeItem("showForm");
    localStorage.removeItem("accessToken2");
    localStorage.removeItem("ajs_anonymous_id");
    const analytics = getAnalytics();
    analytics.reset();
  } catch (e) {
    console.log(e);
  }
  // window.analytics.reset() // this is to clear segment anonymous id
};

export const identifyAnalytics = (email, userMetadata, dispatch) => {
  var data = {
    email: email,
    first_name: userMetadata?.individual_info?.first_name,
    last_name: userMetadata?.individual_info?.last_name,
    organization_name: userMetadata?.entity_info?.name || "",
    state_incorporation: userMetadata?.entity_info?.state_incorporation || "",
    state_business: userMetadata?.entity_info?.primary_business_state || "",
    state_residence: userMetadata?.individual_info?.state_of_residence || "",
    account_type: userMetadata?.entity_info ? "business" : "personal",
    payment_frequency: userMetadata?.payment_frequency || "",
    assigned_lawyer_name: userMetadata?.lawyer_info?.name || "",
    assigned_lawyer_email: userMetadata?.lawyer_info?.email || "",
    subscription_type: userMetadata?.subscription_type || "",
  };
  var id = userMetadata?.user_id;
  if (id) {
    const analytics = getAnalytics();
    analytics.identify(id, data);
    dispatch(setUserIdentified(true));
  }
};

export const getDraftIcon = (icon, size) => {
  switch (icon) {
    case "briefcase":
    case "Briefcase":
      return <Briefcase size={size} />;
    case "bank":
    case "Bank":
      return <Bank size={size} />;
    case "building":
    case "Building":
      return <Building size={size} />;
    case "certificate":
    case "Certificate":
      return <Certificate size={size} />;
    case "receipt":
    case "Receipt":
      return <Receipt size={size} />;
    case "folder":
    case "Folder":
      return <FolderLock size={size} />;
    case "Medal":
      return <Medal size={size} />;
    case "FolderLock":
      return <FolderLock size={size} />;
    case "HandDeposit":
      return <HandDeposit size={size} />;
    case "Envelope":
      return <Envelope size={size} />;
    case "Pen":
      return <Pen size={size} />;
    case "GitDiff":
      return <GitDiff size={size} />;
    case "Cpu":
      return <Cpu size={size} />;
    case "Note":
      return <Note size={size} />;
    case "Users":
      return <Users size={size} />;
    case "Signature":
      return <Signature size={size} />;
    case "LockKey":
      return <LockKey size={size} />;
    case "CalendarDots":
      return <CalendarDots size={size} />;
    case "scroll":
    case "Scroll":
      return <Scroll size={size} />;

    case "ChartLineUp":
      return <ChartLineUp size={size} />;
    case "Handshake":
      return <Handshake size={size} />;
    case "Key":
      return <Key size={size} />;
    case "Trademark":
      return <Trademark size={size} />;
    case "Clipboard":
      return <Clipboard size={size} />;
    case "HouseLine":
      return <HouseLine size={size} />;
    case "FileMagnifyingGlass":
      return <FileMagnifyingGlass size={size} />;
    case "Scales":
      return <Scales size={size} />;
    case "Gavel":
      return <Gavel size={size} />;
    case "Copyright":
      return <Copyright size={size} />;
    default:
      return <Lock size={size} />;
  }
};

export const openPricingModal = (dispatch, metadata, email, page, eventName, data, modalName) => {
  // this will be called whenever the user clicks on the upgrade button.
  // this can be from homepage or normal upgrade buttons, or from the chat page to unlock the chat feature.
  trackAnalytics(eventName, { email: email, page: page }, metadata);
  if (!data) {
    data = {};
    console.log("data not found");
    Object.keys(PLANS_V2).forEach((key) => {
      // console.log(key);
      if (key !== "free_tier") {
        data[key] = PLANS_V2[key];
      }
      // console.log(data);
    });
  }
  // console.log(data);
  if (!modalName) {
    dispatch(setPricingModalData({ show: true, plans: data }));
  } else {
    dispatch(modalName({ show: true, plans: data }));
  }
};

export const homePageClickAction = (dispatch, metadata, email) => {
  trackAnalytics("home:talk_to_lawyer_center_bar", { email: email }, metadata);
  // depeneding on the subscription plan, we might open the legal review side-form, or the upgrade plan popup
};

export const legalReviewAction = (dispatch, email, userMetadata, threadData) => {
  var eventName;
  if (window.location.pathname === "/try") {
    eventName = "try_page_legal_review_button_clicked";
  } else {
    eventName = "dashboard_ask_consult_lawyer_clicked";
  }
  trackAnalytics(
    eventName,
    { action: "legal review request", chatId: threadData?.id, user_email: email },
    userMetadata
  );

  if (userMetadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE) {
    trackAnalytics(
      "chatpage:qab:upgrade_plan_button_clicked",
      {
        threadId: threadData.id,
        user_email: email,
        current_plan: userMetadata?.subscription_type || "trial",
        proposed_plan: PLANS.SUBSCRIBER_ENTERPRISE,
        action: "show_upgrade_plan_popup",
      },
      userMetadata
    );
    if (window.location.pathname === "/try") {
      // TODO
      dispatch(
        setShowSignupToUpgradeModal({
          show: true,
          plans: PLANS_V2.subscriber_enterprise,
          intent: "inhouse_counsel",
          slide: "signup",
        })
      );
    } else {
      dispatch(setShowUpgradePlanPopupChatPage({ show: true, plans: PLANS_V2.subscriber_enterprise }));
    }
  } else {
    trackAnalytics(
      "chatpage:qab:legal_review_button_clicked",
      { chatId: threadData?.id, user_email: email, action: "schedule_call" },
      userMetadata
    );
    // console.log("showing scheduled meeting helpers");
    dispatch(setShowScheduleMeeting({ show: true, lawyer_details: userMetadata.lawyer_info }));

    dispatch(legalReviewButtonClickedIntent({ threadId: threadData.id }));
  }
};

export const getCookie = (name) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split("=");
    if (cookie[0] === name) {
      return decodeURIComponent(cookie[1]);
    }
  }
  return null; // Return null if cookie with given name is not found
};

export const setCookie = (name, value, days = 7) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

export const isUUID = (str) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};

export const extractUUID = (str) => {
  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
  const match = str.match(uuidRegex);
  var uuid = match ? match[0] : null;
  return uuid;
};

export const getCleanSegmentId = (id) => {
  if (!isUUID(id)) {
    id = extractUUID(id);
  } else {
  }
  return id;
};

export const getAnalytics = () => {
  const analytics = Analytics({
    // app: "awesome-app",
    plugins: [
      segmentPlugin({
        writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
      }),
    ],
  });
  return analytics;
};

export const getSegmentId = () => {
  const analytics = getAnalytics();
  try {
    var id;
    try {
      id = analytics.user().anonymousId;
    } catch (e) {
      console.log("Unable to get anonymousId from segment", e);
      ErrorLogging({ error: "Unable to get anonymousId from segment", message: JSON.stringify(e) });
    }
    console.log("anonymousId = ", id);
    if (id) {
      return getCleanSegmentId(id);
    }
    analytics.reset();
    id = analytics.generateAnonymousId();
    console.log("generated anonymousId. setting localstorage = ", id);
    localStorage.setItem("ajs_anonymous_id", id);
    return getCleanSegmentId(id);
  } catch (e) {
    ErrorLogging({ error: "Unable to get segmentId", message: JSON.stringify(e) });
  }
};

export const resetAndStartNewChat = (dispatch) => {
  dispatch(resetThreadData());
  localStorage.removeItem("threadData");
  localStorage.removeItem("threadType");
  localStorage.removeItem("userConfig");
  localStorage.removeItem("threadMessages");
  localStorage.removeItem("questions");
  localStorage.removeItem("document");
  localStorage.removeItem("documentId");
  localStorage.removeItem("thread_id");
  localStorage.removeItem("reference_doc");
  setCookie("thread_id", "", 0);
  setCookie("userInput", "", 0);
  setCookie("alreadyReadInput", "", 0);
  dispatch(resetRequestLegalReviewFailureReason());
  dispatch(resetChatPage());
  dispatch(resetData());
  dispatch(resetThreadData());
  dispatch(setSelectedParties({}));
  dispatch(setAwaitingResponse(false));
  dispatch(setMessagingDisabled(false));
  dispatch(setAwaitingDraftQuestionsResponse(false));
  dispatch(resetReferenceDocDownloadUrl());
};

export const clearLocalStorageKeys = () => {
  localStorage.clear();
};

export const upgradeButtonClicked = (dispatch, threadData, email, userMetadata, plansToShow, actionName) => {
  trackAnalytics(
    "chat:upgrade_plan_popup:schedule_meeting_button_clicked",
    {
      threadId: threadData?.id,
      user_email: email,
      current_plan: userMetadata.subscription_type,
      proposed_plan: plansToShow?.paymentPlanName,
    },
    userMetadata
  );
  var data = {
    payment_type: "subscription",
    subscription_info: {
      subscription_type: plansToShow?.paymentPlanName,
      payment_frequency: PLAN_TYPES.MONTHLY,
    },
    legal_review_thread_id: threadData?.id,
    redirect_url: `${process.env.REACT_APP_URL}/?x-vercel-protection-bypass=${process.env.REACT_APP_VERCEL_PROTECTION_BYPASS}`,
  };
  dispatch(setPaymentCancellationScreen({ action: actionName, plans: plansToShow }));
  dispatch(setUserSelectedPlan(plansToShow?.paymentPlanName));
  dispatch(getCheckoutUrl(data));
  dispatch(setSelectedPaymentPlanName(plansToShow?.paymentPlanName));
  dispatch(setOpenUpgradeToProPopup(false));
};

export const readDataFromLocalStorage = (setChatId, loadingText, dispatch, setPage) => {
  var threadId = localStorage.getItem("thread_id");
  setChatId(threadId);
  var threadmessages, questions, document, reference_doc;
  try {
    threadmessages = JSON.parse(localStorage.getItem("threadMessages") || "[]");
  } catch (e) {}
  try {
    questions = JSON.parse(localStorage.getItem("questions") || "{}");
  } catch (e) {}
  try {
    document = JSON.parse(localStorage.getItem("document") || "{}");
  } catch (e) {}
  try {
    reference_doc = JSON.parse(localStorage.getItem("reference_doc") || "{}");
  } catch (e) {}
  if (threadId && !loadingText) {
    dispatch(
      loadThreadTryPage({
        messages: threadmessages,
        id: JSON.parse(threadId),
        questions: questions,
        document: document,
        reference_doc: reference_doc,
      })
    );
    setPage("chat");
  } else {
    setPage("landing");
  }
};

export const formatText = (text) => {
  if (text) {
    text = text.replace(/`/g, "&#96;");
    text = text.replace(/\\n/g, " \n ");
    text = text.replace(/\n#/g, " \n \n #");
    text = text.replace(/\n/g, "\n # \n");
    text = text.replace(/\\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
    text = text.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
  }
  return text;
};
export const getFirstTenkWords = (message) => {
  if (!message) return "";
  const words = message.split(/\s+/).slice(0, 10000).join(" ");
  return words;
};

export const countInputMessageWords = (s) => {
  return s.trim().split(/\s+/).length;
};
