import { Info, Spinner } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePaymentSuccessModalView, getPaymentStatus } from "src/pages/chatpage/chatSlice";
import {
  getLawyerHub,
  getUserMetadata,
  setShowBusinessPlanUnlocked,
  setShowUpgradingPlan,
} from "src/pages/onboarding/onboardingSlice";
import applicationStore from "src/persistence/stores/RootStore";

const UpgradingPlan = () => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = React.useState(180); // 3 minutes in seconds
  const [refreshButtonClicked, setRefreshButtonClicked] = React.useState(false);
  const timeLeftRef = React.useRef(timeLeft);

  const checkoutUrl = useSelector((state) => state.chat.checkoutUrl);
  const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
  const paymentStatus = useSelector((state) => state.chat.paymentStatus);
  const paymentStatusFetchStatus = useSelector((state) => state.chat.paymentStatusFetchStatus);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);

  useEffect(() => {
    if (showUpgradingPlan) {
      if (checkoutUrl?.payment_id) {
        refreshStatus();
        setTimeLeft(180);
      }
    }
  }, [showUpgradingPlan]); // eslint-disable-line

  useEffect(() => {
    const a_paymentStatus = applicationStore.getState().chat.paymentStatus;
    const openPaymentSuccessModal = applicationStore.getState().chat.openPaymentSuccessModal;

    // const a_showBusinessPlanUnlocked = applicationStore.getState().onboarding.showBusinessPlanUnlocked;
    if (refreshButtonClicked && (a_paymentStatus === "successful" || a_paymentStatus === "failed")) {
      setRefreshButtonClicked(false);
    }
    if (a_paymentStatus === "successful" && !openPaymentSuccessModal) {
      dispatch(openPaymentSuccessModal(true));
      dispatch(setShowUpgradingPlan(false));
      dispatch(getLawyerHub());
      dispatch(getUserMetadata());
    }
  }, [paymentStatus]); // eslint-disable-line

  const refreshStatus = (buttonClicked = false) => {
    if (buttonClicked) {
      setRefreshButtonClicked(true);
    }
    dispatch(getPaymentStatus({ payment_id: checkoutUrl.payment_id }));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTime = prevTime > 0 ? prevTime - 1 : 0;
        timeLeftRef.current = newTime;
        if (newTime % 10 === 0 && newTime > 0) {
          refreshStatus();
          // dispatch(getPaymentStatus({ "payment_id": checkoutUrl.payment_id }));
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col items-center justify-center py-8 px-44">
      <p className="text-lg font-normal text-[#686868]">Please wait</p>
      <p className="text-2xl font-semibold text-[#011513] mt-2">We are upgrading your plan</p>
      <Spinner className="text-[#686868] mt-8" size={64} />
      <p className="text-[#686868] text-lg font-medium">{formatTime(timeLeftRef.current)}</p>

      {timeLeftRef.current > 0 ? (
        <p className="text-[#686868] text-lg font-normal text-center mt-8">
          You will be automatically redirected once the <br /> payment is processed
        </p>
      ) : (
        <div className="flex items-center px-3 py-2 mt-8 bg-[#DCA218]/10 rounded-lg text-sm text-[#686868] font-medium mb-6">
          <Info size={24} className="mr-2 text-[#DCA218]" />
          <p>
            Is it taking too long? Try refreshing the entire page. <br /> Don't worry, your payment is secure.{" "}
          </p>
        </div>
      )}
      {timeLeftRef.current < 165 && (
        <button
          className="text-[#18475A] text-lg font-semibold cursor-pointer disabled:cursor-auto"
          disabled={refreshButtonClicked}
          onClick={() => refreshStatus(true)}
        >
          {refreshButtonClicked ? (
            <>
              Refreshing... <div className="w-6 h-6 border-2 border-t-0 border-[#fff] rounded-full animate-spin"></div>{" "}
            </>
          ) : (
            <>Refresh</>
          )}
        </button>
      )}
    </div>
  );
};

export default UpgradingPlan;
