import { ArrowUp, FileText, Info, Paperclip, Stop, Trash, ArrowUpRight } from "@phosphor-icons/react";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAndStartNewChat, trackAnalytics } from "src/pages/helpers.js";
import UpgradePlanPopup from "src/pages/LawyerHub/LegalReviewRequestAside/UpgradePlanPopup.js";
import { useNavigate } from "react-router-dom";
import applicationStore from "src/persistence/stores/RootStore";
import { setActiveSentenceIndex, setSentences, setTypedText } from "../messageSlice";

const ChatBoxComponent = ({
  size,
  allowAttachReference = false,
  // sentences = null,
  inputMessage = "",
  setInputMessage,
  onsubmit,
  handleFileChange,
  percentage,
  referenceDoc,
  setReferenceDoc,
  fileuploadSuccess,
  privacyLink,
  isChatFull = false,
  fileInputRef,
  generatingDraft,
}) => {
  // size can have two values: half or full depending on whether it's a split screen or full screen

  const [typingInterval, setTypingInterval] = useState(null);
  const [typingInterval2, setTypingInterval2] = useState(null);
  const [hideChatBox, setHideChatBox] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // const [typedText, setTypedText] = useState("");

  // const dispatch = useDispatch();
  const messagingDisabled = useSelector((state) => state.messageSlice.threadData.messagingDisabled);
  const chatInputMessage = useSelector((state) => state.messageSlice.chatInputMessage);
  const newThreadMessage = useSelector((state) => state.messageSlice.newThreadMessage);
  const email = useSelector((state) => state.homeslice.email);
  const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const activeSentenceIndex = useSelector((state) => state.messageSlice.activeSentenceIndex);
  const typedText = useSelector((state) => state.messageSlice.typedText);
  const sentences = useSelector((state) => state.messageSlice.sentences);
  const activeIndex = useSelector((state) => state.messageSlice.activeIndex);
  // const activeSentence = useSelector((state) => state.messageSlice.activeSentence);
  const threadData = useSelector((state) => state.messageSlice.threadData);

  useEffect(() => {
    if (!sentences) {
      dispatch(setSentences(["Message Inhouse"]));
    } else if (isChatFull) {
      dispatch(setSentences(["This chat has reached its maximum length"]));
    }
  }, [sentences, isChatFull]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (threadData?.id) {
      const uniqueMessages = threadData.messages.filter(
        (message, index, self) =>
          index === self.findIndex((m) => m.id === message.id && m.message_type === message.message_type)
      );
      const lastMessage = uniqueMessages[uniqueMessages.length - 1];
      if (lastMessage.message_type === "law_gpt_additional_details") {
        setHideChatBox(true);
      } else {
        setHideChatBox(false);
      }
    }
  }, [threadData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chatInputMessage === "") {
      setInputMessage("");
    }
  }, [chatInputMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (newThreadMessage === "") {
      setInputMessage("");
    }
  }, [newThreadMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  // const [activeSentenceIndex, setActiveSentenceIndex] = useState(0);

  const updateActiveIndex = () => {
    if (typingInterval2) {
      clearInterval(typingInterval2);
    }
    const interval = setInterval(() => {
      const activeSentenceIndex = applicationStore.getState().messageSlice.activeSentenceIndex;
      const newIndex = (activeSentenceIndex + 1) % sentences.length;
      // console.log(activeSentenceIndex, newIndex);
      dispatch(setActiveSentenceIndex(newIndex));
    }, 4000);
    setTypingInterval2(interval);
    return () => clearInterval(interval);
  };

  const startNewChat = () => {
    if (window.location.pathname === "/try") {
      resetAndStartNewChat(dispatch);
      window.location.reload();
    } else {
      navigate("/home");
    }
  };

  useEffect(() => {
    setTypingInterval(null);
    setTypingInterval2(null);
    dispatch(setTypedText(sentences[0]));
    updateActiveIndex();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTypingInterval(null);
    setTypingInterval2(null);
    // updateActiveIndex();
    dispatch(setTypedText(sentences[0]));
  }, [sentences]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const activeSentence = sentences[activeSentenceIndex];

    let currentText = "";
    let currentIndex = 0;
    if (typingInterval) {
      clearInterval(typingInterval);
    }

    const interval = setInterval(() => {
      const threadsData = applicationStore.getState().messageSlice.threadData;
      const activeSentenceIndex = applicationStore.getState().messageSlice.activeSentenceIndex;
      const sentencesFromStore = applicationStore.getState().messageSlice.sentences;
      const activeSentence = sentencesFromStore[activeSentenceIndex];
      if (threadsData?.id) {
        const messagingDisabled = threadsData.messagingDisabled;
        var messageType =
          threadsData && threadsData.messages.length > 0
            ? threadsData.messages[threadsData.messages.length - 1].message_type
            : null;
        if (
          messagingDisabled &&
          messageType &&
          messageType !== "draft_qna_message" &&
          messageType !== "regenerate_ai_response"
        ) {
          dispatch(setTypedText("Messaging is disabled"));
        } else {
          if (currentIndex < activeSentence?.length) {
            currentText += activeSentence[currentIndex];
            dispatch(setTypedText(currentText));
            currentIndex++;
          } else {
            clearInterval(typingInterval);
          }
        }
      } else {
        if (currentIndex < activeSentence?.length) {
          currentText += activeSentence[currentIndex];
          dispatch(setTypedText(currentText));
          currentIndex++;
        } else {
          clearInterval(typingInterval);
        }
      }
    }, 70);
    setTypingInterval(interval);
    return () => clearInterval(interval);
  }, [activeSentenceIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const chatSubmitButton = () => {
    return (
      <>
        {isChatFull ? (
          <button
            className="flex justify-center items-center gap-[8px] bg-[#011513] text-white rounded-[8px] font-sans font-normal text-[14px] border h-[40px] w-[123px]"
            onClick={startNewChat}
          >
            Start New{" "}
            <span>
              <ArrowUpRight size={18} weight="bold" />
            </span>
          </button>
        ) : (
          <button
            className={`bg-[#1B2B48] disabled:bg-[#949494]  rounded-full w-[32px] md:w-[40px] ${
              referenceDoc ? "mr-0 md:mr-[16px]" : "mr-[16px]"
            } h-[32px] md:h-[40px]`}
            disabled={
              messagingDisabled ||
              (inputMessage?.trimStart().length ?? 0) < 3 ||
              (referenceDoc && percentage !== 100) ||
              generatingDraft
            }
            onClick={() => onsubmit()}
          >
            {messagingDisabled || awaitingResponse ? (
              <Stop size={18} className="bg-white text-white mx-auto" />
            ) : (
              <ArrowUp size={18} weight="bold" className="text-white mx-auto" />
            )}
          </button>
        )}
      </>
    );
  };

  return (
    <>
      {hideChatBox ? (
        <></>
      ) : (
        <div
          className={`${
            size === "half"
              ? `chatbox-half ${allowAttachReference ? "h-[170px]" : "h-[154px]"}`
              : `chatbox-full px-2 md:px-0 ${
                  allowAttachReference
                    ? `${
                        window.location.pathname === "/ask"
                          ? "min-h-[250px] md:min-h-[150px]"
                          : "min-h-[185px] md:min-h-[180px]"
                      }`
                    : "min-h-[150px] md:min-h-[140px]"
                }`
          } 
                group flex flex-col gap-[] py-[16px] bg-transparent`}
        >
          <UpgradePlanPopup />
          {isChatFull && (
            <div className="flex justify-between relative top-[16px] border-[1px] border-[#A200093D] w-full h-[68px] bg-[#fbf5f5] rounded-t-[12px] pt-[16px] px-[22px] pb-[26px]">
              <p className="text-[#686868] font-sans font-medium text-[14px]">
                This chat is full. Click 'Start New Chat' to continue where you left off—nothing gets lost!
              </p>
              <p className="font-semibold font-sans text-[16px] text-[#1B2B48] cursor-pointer" onClick={startNewChat}>
                Start New Chat
              </p>
            </div>
          )}

          <div
            className={`flex ${
              allowAttachReference ? "flex-col pr-[24px]" : ""
            } z-[0] items-start space-x-2 input-group-area group w-full gap-[12px] py-[16px] pl-[6px] md:pl-[16px] md:pr-[16px]  border focus-within:!border-black bg-white rounded-[10px] md:rounded-[16px] `}
          >
            <div className="w-full flex justify-between items-center">
              <TextArea
                autoSize={{
                  minRows: window.location.pathname === "/home" ? 2 : 1,
                  maxRows: 6,
                }}
                //  rows={5}
                value={inputMessage}
                disabled={messagingDisabled || awaitingResponse || generatingDraft}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    (e.ctrlKey || e.metaKey) &&
                    !e.shiftKey &&
                    (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                  ) {
                    onsubmit();
                  }
                }}
                className={`${
                  window.Location.pathname === "/ask" ? "" : ""
                } focus:!border-0 focus:!shadow-none form-control border-0 disabled:bg-white disabled:!border-0 disabled:!shadow-none placeholder-[#949494] text-[14px] leading-[21px] 
            md:text-[18px] md:leading-[25.6px]`}
                placeholder={typedText}
              />
              {/* <div className="md:hidden">{chatSubmitButton()}</div> */}
              <div className={`md:hidden ${allowAttachReference && !referenceDoc ? "hidden" : ""}`}>
                {chatSubmitButton()}
              </div>
            </div>
            <div
              className={`${
                allowAttachReference ? "mt-[12px] mb-0 flex-row w-full justify-between" : "self-end"
              } button-box align-bottom flex  gap-[12px]`}
            >
              {allowAttachReference && (
                <>
                  {!referenceDoc && (
                    <div>
                      <label htmlFor="file-upload" className="cursor-pointer file-upload-button">
                        <div className="border-[#D1D1D1] border bg-[#FAFAFA] gap-[4px] rounded-[11px] w-[166px] md:h-[40px] flex justify-center items-center py-[8px] md:pl-[8px] pr-[12px]">
                          <Paperclip className="text-[#011513] my-auto" size={18} />
                          <span className="text-[#011513] font-semibold text-[12px] md:text-[14px] my-auto">
                            Attach Document
                          </span>
                        </div>
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept=".pdf,.docx"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        disabled={messagingDisabled}
                      />
                    </div>
                  )}
                  {referenceDoc && percentage !== 100 && (
                    // {true && (
                    <div className="w-full md:w-auto">
                      <div className="border-[#D1D1D1] flex flex-row  bg-[#FAFAFA] rounded-[11px] h-[40px]">
                        <span className="bg-[#FAFAFA] h-[52px] w-[60px] border flex pl-[8px] pr-[12px] rounded-l-[11px] my-auto mx-auto">
                          <FileText className="text-[#011513] my-auto mx-auto" size={18} />
                        </span>
                        <div className="bg-[#fff] w-full flex flex-row justify-between md:w-[333px] h-[52px]  pl-[8px] border pr-[12px] rounded-r-[11px]">
                          <span className="text-[#011513] bg-[#fff] text-[14px] my-auto">uploading reference</span>
                          <div className="flex flex-row gap-[8px]">
                            <div className="relative w-6 h-6 my-auto">
                              <svg className="absolute inset-0" viewBox="0 0 36 36">
                                <path
                                  className="text-gray-300"
                                  d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                />
                                <path
                                  className="text-blue-600"
                                  d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                  strokeDasharray={`${percentage}, 100`}
                                />
                              </svg>
                            </div>
                            <span className="my-auto"> {percentage}%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {referenceDoc && percentage === 100 && (
                    <div className="w-full md:w-auto">
                      <div className="border-[#D1D1D1] flex flex-row border bg-[#FAFAFA] rounded-[11px] h-[40px]">
                        <span className="bg-[#FAFAFA] h-[52px] w-[60px] border flex pl-[8px] pr-[12px] rounded-l-[11px] my-auto mx-auto">
                          <FileText className="text-[#011513] my-auto mx-auto" size={18} />
                        </span>
                        <div className="bg-[#fff] flex flex-row justify-between gap-[24px] h-[52px]  pl-[8px] border pr-[12px] rounded-r-[11px] w-full">
                          <span className="w-[200px] overflow-x-hidden text-[#011513] bg-[#fff] text-[14px] my-auto cursor-default">
                            {referenceDoc.name.length > 25 ? "..." + referenceDoc.name.slice(-25) : referenceDoc.name}
                          </span>
                          <span className="my-auto">
                            <Trash
                              size={24}
                              className="text-[#A20009] my-auto cursor-pointer"
                              onClick={() => {
                                setReferenceDoc(null);
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className={`hidden md:block ${allowAttachReference && !referenceDoc ? "!block" : ""}`}>
                {chatSubmitButton()}
              </div>
            </div>
          </div>
          {window.location.pathname !== "/home" && (
            <>
              {size === "half" && (
                <p className="flex flex-row text-center text-[10px] md:text-[14px] leading-[19.6px] font-[500] text-[#7F8C8D] gap-[10px] justify-center">
                  <Info className="text-[#7F8C8D] my-[2px]" size={16} />
                  <span className="text-[#686868]">
                    AI can make mistakes, always consult a lawyer. Read our{" "}
                    <span
                      onClick={() => {
                        trackAnalytics("dashboard_privacy_policy_button_clicked", { user_email: email }, userMetadata);
                      }}
                    >
                      <a
                        href="https://www.inhouse.app/terms-of-service"
                        target="blank"
                        className="underline text-[#18475A]"
                      >
                        {" "}
                        Terms
                      </a>{" "}
                      and
                      <a
                        href="https://www.inhouse.app/privacy-policy"
                        target="blank"
                        className="underline text-[#18475A]"
                      >
                        {" "}
                        Privacy Policy
                      </a>{" "}
                    </span>
                    for details.
                  </span>
                </p>
              )}
              {size === "full" && (
                <div
                  className={`${
                    privacyLink ? "" : "hidden"
                  } flex justify-center text-[10px] md:text-[14px] leading-[19.6px] font-[500] text-[#7F8C8D] w-full gap-[0px] md:w-full pt-[12px]`}
                >
                  <div className="flex">
                    <p>
                      <Info className="hidden md:block text-[#7F8C8D] w-[30px]" size={16} />
                    </p>
                    <div className="w-full text-[#686868]">
                      AI can make mistakes, always consult a lawyer. Read our{" "}
                      <span
                        onClick={() => {
                          trackAnalytics(
                            "dashboard_privacy_policy_button_clicked",
                            { user_email: email },
                            userMetadata
                          );
                        }}
                      >
                        <a
                          href="https://www.inhouse.app/terms-of-service"
                          target="blank"
                          className="underline text-[#18475A]"
                        >
                          {" "}
                          Terms
                        </a>{" "}
                        and
                        <a
                          href="https://www.inhouse.app/privacy-policy"
                          target="blank"
                          className="underline text-[#18475A]"
                        >
                          {" "}
                          Privacy Policy
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ChatBoxComponent;
