import { Briefcase, Check, Clock } from "@phosphor-icons/react";
import React from "react";
import { useDispatch } from "react-redux";
import { setShowCallScheduledPopup } from "src/pages/onboarding/onboardingSlice";

const ReviewRequestedButton = () => {
  const dispatch = useDispatch();
  const buttonClicked = () => {
    dispatch(setShowCallScheduledPopup(true));
  };
  return (
    <button
      className="text-[#353535] bg-[#fbf6e7] border-[1px]  md:h-[48px] rounded-[8px] gap-[8px]  px-[12px] flex flex-row"
      onClick={() => buttonClicked()}
    >
      <span className="my-auto">
        <Briefcase size={14} className="text-black my-auto" />
      </span>
      <span className="text-black my-auto text-[12px] md:text-[14px] md:leading-[19.6px]"> Review Requested</span>
      <span className="my-auto">
        <Clock size={14} className="text-[#DCA218] my-auto" />
      </span>
    </button>
  );
};

export default ReviewRequestedButton;
