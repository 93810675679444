import React, { useState } from "react";
import { PenNib, CaretDown, CaretUp, Sparkle } from "@phosphor-icons/react";
import FollowupInputPrompt from "./FollowupInputPrompt";
import { setActivePlaceholder } from "src/pages/chatpage/chatSlice";
import NewAvatar from "src/images/NewAvatar.svg";

const PostAnswers = ({ questions, questionsAnswered, skipped, answers, setAnswers, isDocumentGenerated }) => {
  // const [inputMessage, setInputMessage] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleVisibility = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="">
      {/* <div className="flex justify-start pt-2 items-center gap-2">
        <PenNib size={16} /> <p className="text-[#353535] font-medium">Draft an NDA</p>{" "}
      </div> */}
      <div className="w-full py-2 box-border flex-col flex justify-between">
        <div className="w-full mx-auto flex flex-col justify-center items-center ">
          <div className="w-full">
            {/* <img src={NewAvatar} alt="AI" className="w-[32px] h-[32px] mb-[20px]" /> */}
            {/* <p className="text-[#13866F] mb-3 bg-green-100 text-[30px] font-medium justify-center items-center w-9 h-9 rounded-full flex ">
              &#10035;
            </p> */}
            <p className="text-[#949494] mb-4">
              Thank you! You have answered all the necessary questions for me to generate the document!
            </p>

            <div
              className="flex w-full px-4 h-[65px] bg-white rounded-[12px]  justify-between items-center cursor-pointer  shadow-sm "
              onClick={toggleVisibility}
            >
              <p className="text-[#18475A] font-bold italic font-[lora]">View Your Responses</p>
              {isExpanded ? (
                <CaretUp size={16} className="text-[#7F8C8D]" />
              ) : (
                <CaretDown size={16} className="text-[#7F8C8D]" />
              )}
            </div>
            {/* {isExpanded && <div className="w-full h-96 bg-white shadow-sm"></div>} */}
            {isExpanded && (
              <div className="w-full bh-white shadow-sm bg-white rounded-md">
                {questions.map((question, index) => (
                  <>
                    <div className="w-full p-3 md:p-4 bg-white rounded-b-md border-t">
                      <div className="flex justify-start items-start gap-2 mb-2">
                        <span className="w-5 h-5">
                          <p className="w-5 h-5 mt-[2px] text-xs border rounded-full flex justify-center font-medium items-center bg-gray-100">
                            {index + 1}
                          </p>
                        </span>{" "}
                        <p className="text-[14px] md:text-[16px] font-medium">{question?.text || question?.question}</p>
                      </div>
                      {skipped.indexOf(index) !== -1 ||
                      Object.keys(questionsAnswered).indexOf(index.toString()) === -1 ? (
                        <div className="flex flex-col gap-[8px] bg-[#FAFAFA] p-[8px] ml-[30px] rounded-[8px]">
                          <p className="flex flex-row text-[#13866F] gap-[8px]">
                            <Sparkle size={14.5} className="my-auto text-[#686868]" weight="bold" />
                            <span className="text-[14px] my-auto leading-[22.5px] font-[600] text-[#686868]">
                              AI will pick the best option based on your needs
                            </span>
                          </p>
                        </div>
                      ) : (
                        <>
                          <FollowupInputPrompt
                            index={index}
                            answers={answers}
                            questionsAnswered={questionsAnswered}
                            disabled={true}
                            question={question}
                            setAnswers={setAnswers}
                            isDocumentGenerated={isDocumentGenerated}
                          />
                        </>
                      )}
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostAnswers;
