import { User, PencilSimpleLine, Asterisk, FastForward } from "@phosphor-icons/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import ChoosePartyScreenComponent from "src/pages/chatpage/Modals/DraftDocumentQuestionaireModal/ChoosePartyScreen";
import { createNewParty, getSavedParties } from "src/pages/Homepage/HomeSlice";
import AddPartyScreenComponent from "src/pages/chatpage/Modals/DraftDocumentQuestionaireModal/AddPartyScreen";
import { useWebSocketContext } from "src/WebsocketProvider";

const ChangeParty = (props) => {
  const { chatInfo } = props;
  const dispatch = useDispatch();
  const partiesList = useSelector((state) => state.homeslice.partiesList);
  const [showModal, setShowModal] = useState(false);
  const [editPartyId, setEditPartyId] = useState(null);
  const [choosePartyScreenOpen, setchoosePartyScreenOpen] = useState(false);
  const [addPartyScreenOpen, setAddPartyScreenOpen] = useState(false);
  const [parties, setParties] = useState([]);
  const [allowUpdate, setAllowUpdate] = useState(true);

  const [displayParty, setDisplayParty] = useState({});
  const { sendMessage, createMessage } = useWebSocketContext();

  const [displayName, setDisplayName] = useState("");

  const createNewPartyStatus = useSelector((state) => state.homeslice.createNewPartyStatus);
  const updatingParty = useSelector((state) => state.homeslice.updatingParty);
  const selectedParties = useSelector((state) => state.homeslice.selectedParties);
  const threadData = useSelector((state) => state.messageSlice.threadData);

  const defaultNewParty = {
    is_primary: false,
    is_entity: true,
    has_entity: "no",
    entity_type: null,
    full_name: "",
    state_incorporation: "California",
    primary_business_state: "California",
    address: "",
    description: "",
  };

  const [newParty, setNewParty] = useState({
    is_primary: false,
    is_entity: false,
    has_entity: "no",
    entity_type: null,
    full_name: "",
    state_incorporation: "California",
    primary_business_state: "California",
    address: "",
    description: "",
  });

  useEffect(() => {
    setchoosePartyScreenOpen(true);
    setAddPartyScreenOpen(false);
  }, [showModal]);

  useEffect(() => {
    if (selectedParties.id) {
      setDisplayParty(selectedParties);
      if (selectedParties?.entity_info) {
        setDisplayName(selectedParties?.entity_info?.name);
      } else if (selectedParties?.individual_info?.name) {
        setDisplayName(selectedParties?.individual_info?.name);
      } else {
        setDisplayName(selectedParties?.full_name);
      }
    } else {
      const primaryParty = partiesList.find((party) => party.is_primary === true);
      if (threadData.cached_party_details) {
        setDisplayParty(threadData.cached_party_details);
        if (threadData.cached_party_details?.entity_info) {
          setDisplayName(threadData.cached_party_details?.entity_info?.name);
        } else if (threadData.cached_party_details?.individual_info?.name) {
          setDisplayName(threadData.cached_party_details?.individual_info?.name);
        } else {
          setDisplayName(threadData.cached_party_details?.full_name);
        }
      } else {
        setDisplayParty(primaryParty);
        if (primaryParty?.entity_info) {
          setDisplayName(primaryParty?.entity_info?.name);
        } else if (primaryParty?.individual_info) {
          setDisplayName(primaryParty?.individual_info?.name);
        } else {
          setDisplayName(primaryParty?.full_name);
        }
      }
      // else if (partiesList && partiesList.length > 0) {
      //     const primaryParty = partiesList.find(party => party.is_primary === true);
      //     if (threadData.party_id) {
      //         const party = partiesList.find(party => party.id === threadData.party_id);
      //         if (party) {
      //             setDisplayParty(party)
      //         }
      //         else {
      //             setDisplayParty(threadData.cached_party_details)
      //         }
      //         if (primaryParty && threadData.party_id === primaryParty.id) {
      //             setAllowUpdate(true);
      //         }
      //         else {
      //             setAllowUpdate(false);
      //         }
      //     }
      //     else if (partiesList && partiesList.length > 0) {
      //         const primaryParty = partiesList.find(party => party.is_primary === true);
      //         if (primaryParty) {
      //             setDisplayParty(primaryParty);
      //         }
      //         setAllowUpdate(true);
      //     }
      // }
    }
  }, [partiesList, selectedParties, threadData]);

  useEffect(() => {
    if (createNewPartyStatus === "success") {
      setAddPartyScreenOpen(false);
      setchoosePartyScreenOpen(true);
      if (updatingParty) {
        dispatch(getSavedParties());
      }
    }
  }, [createNewPartyStatus, updatingParty]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var uniqueMessages = threadData.messages.filter(
      (message, index, self) =>
        index ===
        self.findIndex((t) => t.text === message.text && t.message_type === message.message_type && t.id === message.id)
    );
    if (chatInfo !== uniqueMessages?.[uniqueMessages.length - 1]) {
      setAllowUpdate(false);
    } else {
      setAllowUpdate(true);
    }
  }, [threadData, chatInfo]);

  const updateParty = (key, value) => {
    setNewParty({ ...newParty, [key]: value });
  };
  const resetParty = () => {
    setNewParty(defaultNewParty);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const addOrUpdateParty = () => {
    var entityInfo = null;
    var individualInfo = null;
    var primaryBusinessState = null;
    if (newParty.is_entity) {
      if (newParty.primary_business_state !== "") {
        primaryBusinessState = newParty.primary_business_state;
      }
      entityInfo = {
        type: newParty.entity_type,
        state_incorporation: newParty.state_incorporation,
        primary_business_state: primaryBusinessState,
        address: newParty.address,
        business_purpose: newParty.description,
        name: newParty.full_name,
      };
    } else {
      individualInfo = {
        state_residence: newParty.state_incorporation,
        description: newParty.description,
        address: newParty.address,
        name: newParty.full_name,
      };
    }
    const data = {
      id: editPartyId,
      entity_info: entityInfo,
      individual_info: individualInfo,
    };
    dispatch(createNewParty(data)).then((res) => {
      if (res.payload.isEdit) {
        setEditPartyId(null);
      }
    });
  };

  const updatePartyType = (yes) => {
    if (yes === "false") {
      setNewParty({ ...newParty, is_entity: false, entity_type: "Individual", has_entity: "no" });
    } else {
      setNewParty({ ...newParty, is_entity: true, has_entity: "yes" });
    }
  };
  const addNewPartyTileClicked = () => {
    setchoosePartyScreenOpen(false);
    resetParty();
    setAddPartyScreenOpen(true);
  };
  const confirmPartySelectionClicked = (localselectedParties) => {
    setchoosePartyScreenOpen(false);
    setShowModal(false);
    var message = createMessage("draft", "update-party", {
      thread_id: threadData?.id,
      new_party_id: localselectedParties.id,
    });
    sendMessage(message, true);
  };

  const backToSelectPartyScreen = () => {
    setAddPartyScreenOpen(false);
    setchoosePartyScreenOpen(true);
  };

  return (
    <div className="mt-[16px] inline-block">
      <div className="gap-[20px] flex flex-col py-[16px] px-[20px] border rounded-[12px] border-[#E4E4E4] bg-white">
        <span>The AI will represent this party in the {threadData?.document?.doc_title_shorthand || "document"}. </span>
        <div className="rounded-[12px] flex-col gap-[4px] py-[12px] px-[16px] flex bg-[#F2F2F280]">
          <div className="flex flex-row gap-[8px]">
            <User size={16} className="text-[#686868] my-auto" />
            <p className="font-sans font-semibold text-[14px] text-[#686868] my-auto">Your Party</p>
          </div>
          <div className="flex flex-row justify-between gap-[30px]">
            <p className="font-sans font-semibold text-[18px] text-[#18475A]">{displayName} </p>
            {allowUpdate && (
              <button
                className="text-[#264653] rounded-[12px] font-semibold text-[12px] text-sans flex gap-[8px] items-center px-[16px] cursor-pointer my-auto"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                CHANGE{" "}
                <span>
                  <PencilSimpleLine size={18} />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        centered
        open={showModal}
        cancelButtonProps={{ style: { display: "none" } }}
        className="image-upload-pop-up h-full rounded-[10px] bg-[#fff] overflow-hidden"
      >
        <div className="flex flex-col w-full md:w-[860px]">
          {choosePartyScreenOpen && (
            <ChoosePartyScreenComponent
              addNewPartyTileClicked={addNewPartyTileClicked}
              confirmPartySelectionClicked={confirmPartySelectionClicked}
              parties={parties}
              // searchParty={searchParty}
              // setSearchParty={setSearchParty}
              closeModal={closeModal}
              // filterParties={filterParties}
              setEditPartyId={setEditPartyId}
              newParty={newParty}
              setNewParty={setNewParty}
            />
          )}
          {addPartyScreenOpen && (
            <AddPartyScreenComponent
              backToSelectPartyScreen={backToSelectPartyScreen}
              // addPartyButtonClicked={addPartyButtonClicked}
              newParty={newParty}
              closeModal={closeModal}
              updateParty={updateParty}
              updatePartyType={updatePartyType}
              editPartyId={editPartyId}
              addOrUpdateParty={addOrUpdateParty}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ChangeParty;
