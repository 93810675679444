import { ArrowLeft, Briefcase, Pencil, PencilSimple, Plus, User, X } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import WhiteRightArrowImage from "../../../../images/icons/arrow-right-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetCreateNewPartyStatus, setSelectedParties } from "../../../Homepage/HomeSlice";

const ChoosePartyScreenComponent = (props) => {
  const dispatch = useDispatch();
  const { confirmPartySelectionClicked, addNewPartyTileClicked, closeModal, setEditPartyId, newParty, setNewParty } =
    props;
  const [parties, setParties] = useState([]);
  const partiesList = useSelector((state) => state.homeslice.partiesList);
  const [localselectedParties, setlocalselectedParties] = useState({});
  const selectedParties = useSelector((state) => state.homeslice.selectedParties);

  useEffect(() => {
    if (partiesList && partiesList.length > 0) {
      try {
        var result = partiesList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setParties(result);
        let primaryParty = result.find((party) => party.is_primary === true);
        if (primaryParty) {
          setlocalselectedParties(primaryParty);
        }
      } catch (e) {
        setParties(partiesList);
        let primaryParty = partiesList.find((party) => party.is_primary === true);
        if (primaryParty) {
          setlocalselectedParties(primaryParty);
        }
      }
    }
  }, [partiesList]);

  useEffect(() => {
    if (selectedParties?.id) {
      setlocalselectedParties(selectedParties);
    }
  }, [selectedParties]);

  const partiesConfirmed = () => {
    dispatch(setSelectedParties(localselectedParties));
    confirmPartySelectionClicked(localselectedParties);
  };

  const getState = (party) => {
    if (!party.individual_info) {
      return party?.entity_info?.state_incorporation;
    } else {
      return party?.individual_info?.state_residence;
    }
  };

  const getAddress = (party) => {
    if (!party.individual_info) {
      return party?.entity_info?.address || "-";
    } else {
      return party?.individual_info?.address || "-";
    }
  };

  const getDescription = (party) => {
    if (!party.individual_info) {
      return party?.entity_info?.business_purpose;
    } else {
      return party?.individual_info?.description;
    }
  };

  const addParty = () => {
    setEditPartyId();
    dispatch(resetCreateNewPartyStatus());
    addNewPartyTileClicked();
  };

  const handelEdit = (party) => {
    const address = party.entity_info === null ? party.individual_info?.address : party.entity_info?.address;
    setEditPartyId(party.id);
    addNewPartyTileClicked();
    dispatch(resetCreateNewPartyStatus());
    setNewParty({
      full_name: party?.entity_info ? party.entity_info?.name : party.individual_info?.name,
      address: address,
      state_incorporation: party.entity_info
        ? party.entity_info?.state_incorporation
        : party.individual_info?.state_residence,
      primary_business_state: party.entity_info ? party.entity_info?.primary_business_state : "",
      is_entity: party.entity_info ? true : false,
      business_purpose: party.entity_info ? party.entity_info?.business_purpose : "",
      id: party.id,
    });
  };
  return (
    <>
      <div className="flex w-full md:w-[860px] flex-row  justify-between border-b gap-[12px] py-[20px] px-[20px] md:px-[41px]">
        <div className="flex gap-[12px]">
          <div className="flex flex-col gap-[4px]">
            <p className="text-[20px] md:text-[24px] font-[600] leading-[33.6px] font-[Lora] italic">Change Party</p>
            <p className="text-[#686868] text-[12px] md:text-[16px] leading-[19.6px] font-[400]">
              {/* Party details will be used by our AI while drafting the details */}
              The document will represent the party you choose / add. You can change it anytime in the draft later.
            </p>
          </div>
        </div>
        <X
          size={30}
          className="hover:cursor-pointer my-auto"
          color="black"
          onClick={() => closeModal()}
          id="cancel-draft-document-button"
        />
      </div>

      <div className="flex flex-col w-full md:w-[860px] mx-auto justify-between gap-[4px] px-[24px] md:px-[56px] py-[24px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[12px] items-stretch">
          <div
            className="bg-[#FAFAFA] rounded-[12px] border cursor-pointer border-[#E8E8E8] flex flex-col justify-center gap-[8px] pt-[56px] pb-[48px] px-[16px]"
            onClick={() => {
              addParty();
            }}
          >
            <Plus size={32} className="text-[#7C7B79] mx-auto" />
            <p className="text-[18px] leading-[23.44px] font-[700] text-center">Add New</p>
          </div>
          {parties && parties?.length > 0 && (
            <>
              {parties.map((party, index) => (
                <div
                  className={`flex flex-col gap-[16px] border p-[18px] rounded-[8px] cursor-pointer ${
                    localselectedParties?.id === party?.id ? "bg-[#ECF5F34D]  !border-[#248370]" : ""
                  } `}
                  onClick={() => {
                    setlocalselectedParties(party);
                  }}
                >
                  <div className="flex justify-between w-full mx-auto">
                    <div className="flex flex-col gap-[8px]">
                      <p className="text-[16px] font-[600] text-[#141414]">
                        {party?.entity_info ? party?.entity_info?.name : party?.individual_info?.name}
                      </p>
                    </div>
                    <div className={`flex flex-row gap-[8px] h-[24px] p-[4px] `}>
                      {!party.individual_info && <Briefcase size={16} className="text-[#686868] my-auto" />}
                      {!party.entity_info && <User size={16} className="text-[#686868] my-auto" />}
                      <p className="text-[12px] font-[500] text-[#686868] my-auto capitalize">
                        {party.individual_info ? "Individual" : "Company"}
                      </p>
                    </div>
                  </div>
                  <div className="border-t h-[1px] w-full"></div>
                  <div className="overflow-y-scroll">
                    <table className="gap-y-[8px] flex flex-col">
                      <tbody className="flex flex-col gap-y-[8px]">
                        <tr className="gap-[12px]">
                          <td className="text-[12px] text-[#000] font-[600] w-[92px]">State</td>
                          <td className="text-[#77787B]">{getState(party)}</td>
                        </tr>
                        <tr className="">
                          <td className="text-[12px] text-[#000] font-[600]  w-[92px]">Address</td>
                          <td className="text-[#77787B]">{getAddress(party)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="flex justify-end gap-1 items-center w-full" onClick={() => handelEdit(party)}>
                    <p className="text-xs text-[#18475A] uppercase font-bold">Edit Party</p>
                    <PencilSimple size={12} weight="bold" className="text-[#18475A] font-bold" />{" "}
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
        {parties?.length > 0 && (
          <div className="flex flex-col justify-between w-[100%] mx-auto mt-[20px]">
            {localselectedParties == {} && (
              <p className="mt-[20px] text-center w-full text-red-500">Please select a party to continue</p>
            )}
            <div className="flex flex-row justify-between w-[100%] mx-auto mt-[20px]">
              <span></span>
              <button
                className={`bg-[#1B2B48] disabled:bg-[#949494] text-white gap-[12px] rounded-[8px] flex flex-row px-[24px] py-[12px]`}
                id="document-draft-continue-2"
                onClick={() => {
                  partiesConfirmed();
                }}
                disabled={localselectedParties == {}}
              >
                Confirm and Change <img src={WhiteRightArrowImage} className="pt-[4px]" alt="->" />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChoosePartyScreenComponent;
