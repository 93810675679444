import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import { X } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { closePaymentModal, resetPaymentStatus, setCheckoutUrl } from "../../chatSlice";
import { setShowUpgradePlanPopupChatPage, setShowUpgradingPlan } from "src/pages/onboarding/onboardingSlice";
import { openPopup } from "src/components/DocumentViewer/helpers";
import { isAction } from "@reduxjs/toolkit";

const PaymentModal = (props) => {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_URL;
  const openPaymentModal = useSelector((state) => state.chat.openPaymentModal);
  const checkoutUrl = useSelector((state) => state.chat.checkoutUrl);
  const paymentCancellationScreen = useSelector((state) => state.chat.paymentCancellationScreen);
  const email = useSelector((state) => state.homeslice.email);
  const metadata = useSelector((state) => state.onboarding.userMetadata);
  const [iframeUrl, setIframeUrl] = useState(checkoutUrl?.url);

  const closeModal = () => {
    dispatch(closePaymentModal());
  };

  const iframeRef = useRef(null);

  useEffect(() => {
    if (checkoutUrl) {
      dispatch(setShowUpgradePlanPopupChatPage({ plans: {}, show: false }));
      setIframeUrl(checkoutUrl.url);
      dispatch(resetPaymentStatus());
    }
  }, [checkoutUrl]);

  useEffect(() => {
    const checkIframeUrl = () => {
      try {
        const currentUrl = iframeRef?.current?.contentWindow?.location?.href;
        if (currentUrl !== iframeUrl && currentUrl.search(`${BASE_URL}`) !== -1) {
          openPaymentCheckModal();
          closeModal();
        }
      } catch (e) {}
    };

    const interval = setInterval(checkIframeUrl, 5000);
    return () => clearInterval(interval);
  }, [iframeUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const openPaymentCheckModal = () => {
    dispatch(setShowUpgradingPlan(true));
  };
  const closePayemtPopup = () => {
    dispatch(setCheckoutUrl(null));
    dispatch(resetPaymentStatus());
    // variable name : paymentSelectionSource
    // paymentSelectionSource.action = "multiple_plans_selection"  > openPopup(dispatch, , metadata, email, "payment", "payment_cancelled:opening_plan_selection");
    if (paymentCancellationScreen.action === "multiple_plans_selection") {
      openPopup(
        dispatch,
        paymentCancellationScreen.action,
        metadata,
        email,
        "payment",
        "payment_cancelled:opening_plan_selection",
        paymentCancellationScreen.plans
      );
    } else if (paymentCancellationScreen.action === "dedicated_plan_popup") {
      openPopup(
        dispatch,
        paymentCancellationScreen.action,
        metadata,
        email,
        "payment",
        "payment_cancelled:dedicated_plan_popup",
        paymentCancellationScreen.plans,
        setShowUpgradePlanPopupChatPage
      );
    } else {
      openPopup(
        dispatch,
        paymentCancellationScreen.action,
        metadata,
        email,
        "payment",
        "payment_cancelled:single_plan_selection",
        paymentCancellationScreen.plans,
        setShowUpgradePlanPopupChatPage
      );
    }
    closeModal();
  };
  return (
    <Modal
      centered
      open={openPaymentModal}
      // open={true}
      className="checkout-modal h-full bg-[#fff] overflow-hidden "
      footer={null}
    >
      <div className="w-screen h-screen flex flex-col ">
        <div className="flex w-full flex-col border-b gap-4 py-[20px] px-[41px]">
          <div className="flex gap-[8px] w-full justify-between">
            <h1 className="text-[20px] font-[600] leading-[28px] font-[Lora] italic">Complete Payment</h1>
            <X
              size={30}
              className="hover:cursor-pointer"
              color="black"
              onClick={() => {
                closePayemtPopup();
              }}
              id="cancel-draft-document-button"
            />
          </div>
        </div>
        <div className="w-full p-[20px]">
          {iframeUrl && (
            <iframe
              src={iframeUrl}
              className="border"
              width="100%"
              height={window.innerHeight - 100}
              ref={iframeRef}
              title="Complete Payment"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
