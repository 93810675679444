import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useUser, useSignUp, useSignIn } from "@clerk/clerk-react";

import { useNavigate } from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout.js";

import FourLawyersImage from "src/images/4-lawyer.svg";
// import { toast } from 'react-toastify';

import TopNav from "../chatpage/Navs/TopNav.js";

import { setDraftModalDraftType, setShowExploreMoreModal } from "../../modalSlice.js";
import {
  ArrowRight,
  Briefcase,
  ChatsCircle,
  City,
  FileMagnifyingGlass,
  Handshake,
  PenNib,
  Receipt,
  Wallet,
} from "@phosphor-icons/react";
import { getEducationMetadata } from "./HomeSlice.js";
import ExploreMoreModalComponent from "../../components/chat/Modals/ExploreMoreModal/index.js";
import { setShowPersonalizePopup, setShowUpgradePlanNudge } from "../onboarding/onboardingSlice.js";
import { openPopup } from "src/components/DocumentViewer/helpers.js";
import { countInputMessageWords, getCookie, getFirstTenkWords, setCookie, trackAnalytics } from "../helpers.js";
import { PLANS } from "src/components/Constants.js";
import {
  getPresignedUrl,
  resetFileUploadProgress,
  resetIsChatFull,
  resetRedirectTo,
  resetThreadData,
  setAskToDraftIntentWSResponse,
  setAwaitingCounter,
  setAwaitingResponse,
  setMessagingDisabled,
  setNewThreadMessage,
  setSentences,
  setWordCountPopup,
  updateUploadedFileName,
  uploadFile,
} from "../chatpage/messageSlice.js";
import LegalReviewRequestAside from "../LawyerHub/LegalReviewRequestAside/index.js";
import ChatBoxComponent from "../chatpage/components/ChatBoxComponent.js";
import { useWebSocketContext } from "src/WebsocketProvider.js";
import { toast } from "react-toastify";
import SignupPopup from "../chatpage/SignupPopup.js";
import { suggestionBtnsLandingPage } from "src/components/Constants.js";
import applicationStore from "src/persistence/stores/RootStore.js";
import WordLimitPopup from "src/components/WordLimitPopup.js";

const Homepage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const open_draft = queryParams.get("open_draft");
  const open_membership = queryParams.get("open_membership");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [smallScreen, setSmallScreen] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [referenceDoc, setReferenceDoc] = useState(null);

  const { isLoaded, user } = useUser();
  const { sendMessage, createMessage, socketRef } = useWebSocketContext();
  const { isUploading, fileuploadError } = useSelector((state) => state.messageSlice);
  const [referenceDocId, setReferenceDocId] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
  const email = useSelector((state) => state.homeslice.email);
  const sidenavCollapsed = useSelector((state) => state.homeslice.sidenavCollapsed);
  const allSuggestions = useSelector((state) => state.homeslice.allSuggestions);
  const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const educationMetadataFetchStatus = useSelector((state) => state.homeslice.educationMetadataFetchStatus);
  const redirectTo = useSelector((state) => state.messageSlice.redirectTo);
  const presignedUrl = useSelector((state) => state.messageSlice.presignedUrl);
  const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);

  const fileInputRef = useRef(null);
  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (user && user.createdAt) {
      var diff = new Date() - new Date(user.createdAt);
      var days = diff / (1000 * 60 * 60 * 24);
      if (userMetadataFetchStatus === "success" || userMetadataFetchStatus === "failed") {
        if (
          days < 1 &&
          !(userMetadata?.entity_info?.primary_business_state || userMetadata?.individual_info?.state_of_residence) &&
          !localStorage.getItem("onboardingPopupShown")
        ) {
          setTimeout(() => {
            dispatch(setShowPersonalizePopup(true));
            localStorage.setItem("onboardingPopupShown", true);
          }, 1500);
        }
      }
    }
  }, [user, userMetadata]);

  useEffect(() => {
    dispatch(resetThreadData());
    dispatch(setMessagingDisabled(false));
    dispatch(setSentences(["Ask me any legal questions here", "Draft a contract that...", "Review a lease for..."]));
    setTimeout(() => {
      var cookieFromHomepage = getCookie("userInput");
      var recentlyReadUserInput = getCookie("alreadyReadInput");
      if (cookieFromHomepage && cookieFromHomepage !== recentlyReadUserInput) {
        setCookie("alreadyReadInput", cookieFromHomepage, 7);
        setInputMessage(cookieFromHomepage);
        setTimeout(() => {
          trackAnalytics(
            "homepage:new_thread:from_cookie",
            { user_email: email, message: cookieFromHomepage },
            userMetadata
          );
          onsubmit(cookieFromHomepage);
        }, 5000);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSmallScreen(true);
    }
    dispatch(setMessagingDisabled(false));
    dispatch(setAwaitingResponse(false));
    dispatch(resetIsChatFull());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (allSuggestions && allSuggestions.categories?.length > 0) {
      var cats = allSuggestions.categories;
      setCategories(cats);
      setSelectedCategory(cats[0]?.id);
    }
  }, [allSuggestions]); // eslint-disable-line

  useEffect(() => {
    if (open_membership) {
      dispatch(setShowUpgradePlanNudge(true));
    }
  }, [open_membership]);

  useEffect(() => {
    getSelectedCategorySuggestions();
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    getSelectedCategorySuggestions();
    trackAnalytics("dashboard_home_viewed", { user_email: email }, userMetadata);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (open_draft) {
      openDraftDocumentModal("homepage");
      navigate("/home");
    }
  }, [open_draft]); // eslint-disable-line

  useEffect(() => {
    if (educationMetadataFetchStatus === "failed") {
      dispatch(getEducationMetadata());
    }
  }, [educationMetadataFetchStatus]); // eslint-disable-line

  useEffect(() => {
    if (fileuploadError) {
      toast.error(`File Upload failed. Please try again.`);
      setUploadPercentage(0);
      setReferenceDocId(null);
      setReferenceDoc(null);
    }
  }, [fileuploadError]); // eslint-disable-line
  useEffect(() => {
    if (uploadPercentage === 100) {
      setReferenceDocId(presignedUrl?.storage_id);
    }
  }, [uploadPercentage]); // eslint-disable-line

  useEffect(() => {
    if (redirectTo) {
      var redirectUrl = redirectTo;
      dispatch(resetRedirectTo(null));
      navigate(redirectUrl);
    }
  }, [redirectTo]); // eslint-disable-line

  useEffect(() => {
    if (referenceDoc && presignedUrl) {
      var filename = referenceDoc.name;
      dispatch(updateUploadedFileName({ filename: filename, storage_id: presignedUrl?.storage_id }));
      trackAnalytics("ask thread: reference doc uploaded", { user_email: email, filename: filename }, userMetadata);
      // window.analytics.track("ask thread: reference doc uploaded", { "user_email": email, "filename": filename });
      dispatch(
        uploadFile({
          file: referenceDoc,
          data: presignedUrl.upload_fields,
          url: presignedUrl.url,
          onUploadProgress: (progress) => {
            setTimeout(() => {
              setUploadPercentage(progress);
            }, 3000);
          },
        })
      );
    }
  }, [presignedUrl]); // eslint-disable-line

  const openDraftDocumentModal = (source, draft) => {
    trackAnalytics(`Draft document from ${source}`, { user_email: email }, userMetadata);
    // window.analytics.track(`Draft document from ${source}`, { "user_email": email });
    dispatch(setDraftModalDraftType(draft));
  };

  const sendWsMessage = (message) => {
    console.log("sending ws message");
    const awaitingCounter = applicationStore.getState().messageSlice.awaitingCounter;
    if (awaitingCounter > 20) {
      setCookie("alreadyReadInput", "", 1);
      window.location.reload();
    }
    dispatch(setMessagingDisabled(true));
    dispatch(setAwaitingResponse(true));
    if (socketRef?.current?.readyState !== 1) {
      console.log("sending ws message awaitingCounter= ", awaitingCounter);
      dispatch(setAwaitingCounter(awaitingCounter + 1));
      setTimeout(() => {
        sendWsMessage(message);
      }, 1000);
    } else {
      var response = sendMessage(message, true);
      if (!response) {
        alert("Error sending message. Please try again.");
      }
      setInputMessage("");
    }
  };

  const getSelectedCategorySuggestions = () => {
    var allExamples = [];
    var category = selectedCategory;
    if (!category && allSuggestions.categories && allSuggestions.categories.length > 0) {
      category = allSuggestions.categories?.find((category) => category.name === "IP")?.id;
      setSelectedCategory(category);
    }
    if (category && allSuggestions.categories && allSuggestions.categories.length > 0) {
      ["ask", "draft"].forEach((type) => {
        var st = allSuggestions[type + "_examples"].map((value) => ({ ...value, sort: Math.random(), type: type }));
        // var examples = allSuggestions[type + "_examples"].filter(suggestion => suggestion.type === type);
        allExamples = allExamples.concat(st.filter((suggestion) => suggestion.category_id === category));
      });

      var newExamples = allExamples.sort((a, b) => a.sort - b.sort).map((value) => value);
      setSuggestions(newExamples.slice(0, 5));
    }
  };

  const exploreAllClicked = () => {
    trackAnalytics("Explore all clicked (homepage)", { user_email: email }, userMetadata);
    // window.analytics.track("Explore all clicked (homepage)", { "user_email": email });
    dispatch(setShowExploreMoreModal(true));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      // toast.error("No file selected.");
      return;
    }
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (["docx", "pdf"].includes(fileExtension)) {
      // console.log("Valid file selected:", file);
      dispatch(getPresignedUrl());
      dispatch(resetFileUploadProgress());
      setUploadPercentage(0);
      setReferenceDoc(file);
    } else {
      toast.error("Invalid file format. Only docx and pdf are allowed.");
      setReferenceDoc(null);
    }
  };

  const getCircle = (type) => {
    switch (type) {
      case "ask":
        return (
          <div>
            <ChatsCircle size={16} className="text-[#C61DCA]" />
          </div>
        );

      case "draft":
        return (
          <div>
            <PenNib size={16} className="text-[#13866F]" />
          </div>
        );
      case "review":
        return (
          <div>
            <FileMagnifyingGlass size={16} className="text-[#949494]" />
          </div>
        );
      default:
        return "#C61DCA";
    }
  };
  const getIcon = (type, bold = false) => {
    var realType = `${type}${bold ? "-bold" : ""}`;
    switch (realType) {
      case "ask":
        return <ChatsCircle size={16} className="text-[#C61DCA]" />;

      case "draft":
        return <PenNib size={16} className="text-[#13866F]" />;
      case "review":
        return <FileMagnifyingGlass size={16} className="text-[#E79571]" />;
      case "briefcase":
        return <Briefcase size={20} className="text-[#686868]" />;

      case "briefcase-bold":
        return <Briefcase size={20} className="text-[#686868]" weight="bold" />;

      case "city":
        return <City size={20} className="text-[#686868]" />;
      case "city-bold":
        return <City size={20} className="text-[#686868]" weight="bold" />;
      case "wallet":
        return <Wallet size={20} className="text-[#686868]" />;
      case "wallet-bold":
        return <Wallet size={20} className="text-[#686868]" weight="bold" />;
      case "handshake":
        return <Handshake size={20} className="text-[#686868]" />;
      case "handshake-bold":
        return <Handshake size={20} className="text-[#686868]" weight="bold" />;
      case "receipt":
        return <Receipt size={20} className="text-[#686868]" />;
      case "receipt-bold":
        return <Receipt size={20} className="text-[#686868]" weight="bold" />;
      default:
        return "#C61DCA";
    }
  };

  const suggestionBtnClicked = (btnText, buttonFunc) => {
    setInputMessage(btnText);
    if (buttonFunc === "Review") {
      handleFileButtonClick();
    }
  };
  const onSubmitWithHandleWordCount = () => {
    const wordCount = countInputMessageWords(inputMessage);
    if (wordCount > 10000) {
      const msg = getFirstTenkWords(inputMessage);
      setInputMessage(msg);
      dispatch(setWordCountPopup(true));
    } else {
      onsubmit(inputMessage);
    }
  };

  const onsubmit = (msg) => {
    var message = msg || inputMessage;
    trackAnalytics(
      "new thread from homepage. sending first message",
      { user_email: email, message: message },
      userMetadata
    );
    trackAnalytics("dashboard_home_send_chat_button_clicked", { user_email: email, message: message }, userMetadata);
    // window.analytics.track("new ask thread. sending first message", { "user_email": email, "message": inputMessage })
    // if (isUploading) {
    //   toast.error(`File Upload is in progress.`);
    // }
    dispatch(setNewThreadMessage(message));
    var docId = referenceDocId;
    if (uploadPercentage === 100 && referenceDoc) {
      docId = referenceDocId;
    } else {
      docId = null;
    }
    var wsmessage = createMessage("ask", "create-thread", { message: message, reference_doc_storage_id: docId });
    dispatch(setAwaitingCounter(0));
    var response = sendWsMessage(wsmessage, true);
    if (!response) {
      dispatch(setMessagingDisabled(true));
    }
  };

  const openAskPage = (message) => {
    dispatch(resetThreadData());
    navigate("/ask", { state: { message: message } });
  };
  const goToSomePlace = (suggestion) => {
    // if (userMetadata?.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE) {
    //   if (userMetadata?.threads_remaining === 0) {
    //     if (userMetadata?.subscription_type === PLANS.PERSONAL_FREE) {
    //       openPopup(dispatch, "personal_free_limits_exhausted");
    //     } else {
    //       openPopup(dispatch, "upgrade_plan");
    //     }
    //     return;
    //   }
    // }
    dispatch(setAskToDraftIntentWSResponse({}));
    switch (suggestion.type) {
      case "ask":
        openAskPage(suggestion?.actual_prompt);
        trackAnalytics("dashboard_home_get_advice", { user_email: email }, userMetadata);
        break;
      case "draft":
        trackAnalytics("dashboard_home_draft_new", { user_email: email, small_screen: smallScreen }, userMetadata);
        // if (smallScreen) {
        //     openSmallScreenModal(dispatch)
        //     return
        // }
        var draft = draftTypeSuggestions?.find((draft) => draft.id === suggestion?.draft_metadata_id);
        if (draft) {
          openDraftDocumentModal("home page tiles", draft);
        } else {
          openDraftDocumentModal("home page tiles", suggestion?.display_prompt);
        }
        break;
      case "review":
        openDraftDocumentModal();
        break;
      default:
        break;
    }
  };

  const retryEducationMetaFetch = () => {
    trackAnalytics("Retry education meta fetch", { user_email: email }, userMetadata);
    // window.analytics.track("Retry education meta fetch", { "user_email": email });
    dispatch(getEducationMetadata());
  };

  return (
    <>
      <WordLimitPopup onProceed={onsubmit} />
      <LegalReviewRequestAside />
      {isLoaded ? (
        <BaseLayout>
          <div className="flex flex-col w-full h-screen overflow-y-scroll pt-[50px] md:pt-0">
            <div
              className={`flex flex-col w-full h-screen justify-between  mx-auto pb-[32px] ${
                sidenavCollapsed ? " md:max-w-[1376px] " : "md:max-w-[1256px]"
              }`}
            >
              <TopNav page="home"></TopNav>
              <div
                className={`w-full mx-auto gap-[24px] flex flex-col px-3 ${
                  sidenavCollapsed ? "md:px-[60px]" : "md:px-[20px]"
                }`}
              >
                <div
                  className={`${
                    sidenavCollapsed ? "md:w-[1248px]" : " md:w-[1134px]"
                  } w-full mx-auto gap-[8px] flex flex-col mt-5`}
                >
                  <h1 className="text-[#686868] text-center text-[14px] md:text-[20px] leading-[28px] font-[500]">
                    Welcome{user?.fullName ? ` ${user.firstName},` : ","}
                  </h1>
                  <p className="text-xl text-center md:text-[32px] md:leading-[44.8px] font-[Lora] italic font-[700] text-[#1B2B48]">
                    How can we help you?
                  </p>
                </div>
                <div
                  className={`flex md:flex-col flex-col  justify-between mx-auto w-full md:mb-[100px] ${
                    sidenavCollapsed ? "md:w-[1248px]" : " md:w-[1134px]"
                  }`}
                >
                  <ChatBoxComponent
                    size="full"
                    allowAttachReference={true}
                    inputMessage={inputMessage}
                    setInputMessage={setInputMessage}
                    onsubmit={onSubmitWithHandleWordCount}
                    referenceDoc={referenceDoc}
                    handleFileChange={handleFileChange}
                    // sentences={}
                    percentage={uploadPercentage}
                    setReferenceDoc={setReferenceDoc}
                    fileuploadSuccess={uploadPercentage}
                    fileInputRef={fileInputRef}
                  />
                  <div className=" flex flex-wrap gap-[16px] justify-center items-center">
                    {suggestionBtnsLandingPage.map((button) => {
                      return (
                        <p
                          className={`cursor-pointer flex justify-center items-center gap-[8px] font-sans font-medium text-[14px] text-[#222222] px-[16px] py-[8px] border-[1px] bg-white border-[#E4E4E4] ${button.hover} rounded-[98px] `}
                          onClick={() => suggestionBtnClicked(button.text, button.func)}
                        >
                          <span>{button.icon}</span>
                          {button.text}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <ExploreMoreModalComponent />
        </BaseLayout>
      ) : (
        <>
          {isLoaded && getUserDetailsStatus === "failed" ? (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]"></div>
          )}
        </>
      )}
    </>
  );
};

export default Homepage;
