import { X } from "@phosphor-icons/react/dist/ssr";
import useSelection from "antd/es/table/hooks/useSelection";
import React, { useEffect } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useDispatch, useSelector } from "react-redux";
import { PLANS } from "src/components/Constants";
import { closePaymentModal } from "src/pages/chatpage/chatSlice";
import {
  markLegalReviewCallScheduled,
  resetRequestLegalReviewFailureReason,
} from "src/pages/onboarding/onboardingSlice";
import { fetchMeetingDetails, setShowNextSteps, setShowScheduleMeeting } from "src/pages/onboarding/onboardingSlice";

const ScheduleMeeting = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.homeslice.email);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const lawyerCalendlyLink = useSelector((state) => state.onboarding.lawyerCalendlyLink);

  const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);

  const [meetingScheduled, setMeetingScheduled] = React.useState(false);
  const [meetingDetails, setMeetingDetails] = React.useState(null);
  const [calendlyLink, setCalendlyLink] = React.useState(process.env.REACT_APP_CALENDLY_MEETING_ID);

  const [prefillData, setPrefillData] = React.useState({
    email: email,
    firstName: userMetadata?.individual_info?.first_name,
    lastName: userMetadata?.last_name,
    name: `${userMetadata?.individual_info?.first_name} ${userMetadata?.individual_info?.last_name}`,
    customAnswers: {
      a1: threadData?.id
        ? `I want a review on https://platform.inhouse.app/${threadData?.thread_type}/shared/${threadData?.id}`
        : "",
    },
  });

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      setMeetingScheduled(true);
      setMeetingDetails(e.data.payload);
    },
    onPageHeightResize: (e) => console.log(e.data.payload.height),
  });

  const closeModal = () => {
    console.log("close modal");
    dispatch(setShowScheduleMeeting({ show: false, lawyer_details: {} }));
  };

  const submitLawyerMatchingRequest = () => {
    var meetingId = meetingDetails.event.uri.split("/").pop();
    if (meetingId) {
      if (threadData?.id) {
        dispatch(markLegalReviewCallScheduled({ threadId: threadData?.id }));
      }
      dispatch(setShowScheduleMeeting({ show: false, lawyer_details: {} }));
      closeModal();
    }
  };

  useEffect(() => {
    if (lawyerCalendlyLink) {
      setCalendlyLink(lawyerCalendlyLink);
    } else if (userMetadata?.lawyer_info) {
      setCalendlyLink(userMetadata?.lawyer_info?.calendly_link);
    } else {
      setCalendlyLink(process.env.REACT_APP_CALENDLY_MEETING_ID);
    }
  }, [lawyerCalendlyLink, showScheduleMeeting]); // eslint-disable-link

  useEffect(() => {
    setPrefillData({
      email: email,
      firstName: userMetadata?.individual_info?.first_name,
      lastName: userMetadata?.individual_info?.last_name,
      name: `${userMetadata?.individual_info?.first_name} ${userMetadata?.individual_info?.last_name}`,
      customAnswers: {
        a1: threadData?.id
          ? `I want a review on https://platform.inhouse.app/${threadData?.thread_type}/shared/${threadData?.id}`
          : "",
        // a2: threadData?.id ? `https://platform.inhouse.app/${threadData?.thread_type}/shared/${threadData?.id}` : "",
        // a3: threadData?.id ? `https://platform.inhouse.app/${threadData?.thread_type}/shared/${threadData?.id}` : "",
      },
    });
  }, [email, userMetadata, threadData]); // eslint-disable-link

  useEffect(() => {
    setPrefillData({
      email: email,
      firstName: userMetadata?.individual_info?.first_name,
      lastName: userMetadata?.individual_info?.last_name,
      name: `${userMetadata?.individual_info?.first_name} ${userMetadata?.individual_info?.last_name}`,
      customAnswers: {
        a1: threadData?.id
          ? `I want a review on https://platform.inhouse.app/${threadData?.thread_type}/shared/${threadData?.id}`
          : "",
      },
    });
  }, [email, userMetadata, threadData]); // eslint-disable-link

  return (
    <>
      <div className="flex flex-col h-full p-0 overflow-scroll">
        <X
          className="absolute top-4 right-4 cursor-pointer"
          size={20}
          onClick={() => {
            dispatch(setShowScheduleMeeting({ show: false, lawyer_details: {} }));
            dispatch(resetRequestLegalReviewFailureReason());
          }}
        />

        {calendlyLink ? (
          <>
            {window.innerWidth < 830 ? (
              <InlineWidget
                url={calendlyLink}
                styles={{ height: "680px", overflow: "scroll", overFlowX: "scroll" }}
                prefill={prefillData}
              />
            ) : (
              <InlineWidget
                url={calendlyLink}
                styles={{ height: "680px", minWidth: "600px", overflow: "scroll", overflowX: "scroll" }}
                prefill={prefillData}
              />
            )}
          </>
        ) : (
          ""
        )}
        {/* <div
          class="calendly-inline-widget"
          data-url={calendlyLink}
          style={{ minWidth: "320px", height: "700px" }}
        ></div> */}

        {meetingDetails && (
          <>
            <div className="flex justify-end px-[24px] ">
              <button
                className="bg-[#1B2B48] text-white px-4 py-2 rounded-lg my-4"
                onClick={() => {
                  submitLawyerMatchingRequest();
                }}
              >
                Ok, got it
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ScheduleMeeting;
