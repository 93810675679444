import React, { useEffect } from "react";
import ToggleSwitch from "../components/ToggleSwitch";
import Button from "../Button";
import USFlag from "src/images/us-flag.png";
import { ArrowUpRight, ShieldCheck } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PLANS } from "src/components/Constants";
import { isDraft } from "@reduxjs/toolkit";
const RequestReviewFormAside = (props) => {
  const {
    email,
    trackAnalytics,
    setShowLearnMoreModal,
    dispatch,
    legalReiviewImg,
    text,
    setText,
    loading,
    phoneNumber,
    setPhoneNumber,
    notice,
  } = props;

  const location = useLocation();
  const [isFieldInFocus, setIsFieldInFocus] = React.useState(false);

  const [title, setTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [bannerImage, setBannerImage] = React.useState();
  const [banner, setBanner] = React.useState();
  const [formLabelText, setFormLabelText] = React.useState("");
  const [formPlaceholderText, setFormPlaceholderText] = React.useState("");
  const [learnMoreText, setLearnMoreText] = React.useState("");
  const [phonenumberplaceholder, setPhonenumberplaceholder] = React.useState("");
  const [phoneNumberLabel, setPhoneNumberLabel] = React.useState("");

  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);
  const userConfig = useSelector((state) => state.onboarding.userConfig);
  useEffect(() => {
    setBanner(userConfig?.legalReview?.banner);
    setText(userConfig?.legalReview?.text);
    setFormLabelText(userConfig?.legalReview?.formLabel);
    setFormPlaceholderText(userConfig?.legalReview?.formPlanceholder);
    setPhoneNumberLabel(userConfig?.legalReview?.phoneNumberLabel);
    setPhonenumberplaceholder(userConfig?.legalReview?.phonenumberplaceholder);
  }, [userConfig]);

  return (
    <section className="w-full">
      <div id="Before-request">
        {location.pathname !== "/home" && userMetadata?.lawyer_info ? (
          <></>
        ) : (
          <div className="">
            <div className="relative flex flex-col py-12 border-[#18475A29] bg-[#F5FAF9]">
              <div>{banner}</div>
            </div>

            <div className="px-8 flex flex-col gap-[24px]">
              <div className="flex flex-col">
                <p className="text-[16px] font-[700] font-sans text-[#353535] mt-4">{formLabelText}</p>

                <textarea
                  placeholder={formPlaceholderText}
                  className="border px-3 py-3 h-[130px] border-[#D1D1D1] w-full mt-2 rounded-lg"
                  disabled={loading}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                {notice.description && <p className="text-red-500 my-2">{notice.description}</p>}
              </div>
              <div className="w-full relative">
                <div className="flex flex-col gap-[12px] mb-[40px]">
                  <div className="flex flex-col">
                    <p className="font-sans font-[600] text-[16px] leading-[25.6px] text-[#353535]">
                      {phoneNumberLabel} <sup class="text-red-500 font-[600]">*</sup>
                    </p>
                    <p className="text-[12px] leading-[18px] font-[400] text-[#686868]">
                      This helps us reach out to you to connect you with a lawyer.
                    </p>
                  </div>
                  <div className="gap-[5px] flex flex-col">
                    <div className="w-full h-[52px] border flex items-center rounded-[8px] bg-white">
                      <div
                        className="px-[12px] text-[14px] font-normal w-[58px] text-sans text-[#686868] rounded-[8px] flex flex-row items-center gap-[4px]"
                        title="Currently available only in the USA"
                      >
                        <img src={USFlag} className="w-[15px]" alt="🇺🇸" />
                        <span>+1</span>
                      </div>
                      <input
                        type="number"
                        name="telephone"
                        placeholder={phonenumberplaceholder}
                        className="border-l px-[12px] h-full w-full text-[14px] font-normal font-sans rounded-r-[8px]"
                        value={phoneNumber}
                        onBlur={() => {
                          setIsFieldInFocus(false);
                        }}
                        onFocus={() => {
                          setIsFieldInFocus(true);
                        }}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    {notice.phoneNumber && <p className="text-red-500">{notice.phoneNumber}</p>}
                  </div>
                </div>
                {/* {notice.error && <p className="text-red-500 my-2">{notice.error}</p>} */}
                {requestLegalReviewStatus === "failed" && (
                  <p className="text-red-500 mt-[-20px] mb-[20px]">Failed to request legal review</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default RequestReviewFormAside;
