import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import mAndmLogo from "src/images/m&m.svg";

const MMLegal = (props) => {
  const [text, setText] = useState("");

  const referralCategory = props.referralCategory;
  const referralCategoryText = useSelector((state) => state.onboarding.referralCategoryText);

  useEffect(() => {
    switch (referralCategory) {
      case "accidents_and_injuries":
        setText("injury");
        break;
      case "criminal":
        setText("criminal defense");
        break;
      case "employment":
        setText("employment");
        break;
      case "business_litigation":
        setText("business litigation");
        break;
      default:
        setText(referralCategory?.replace(/_/g, " "));
    }
  }, [referralCategory]);

  return (
    <div className="w-full md:w-[572px] pt-[40px] pb-[20px] bg-[#ECF5F3] px-[20px] md:px-[32px] flex flex-col gap-[8px]">
      <p className="font-[lora] font-bold italic text-[18px] md:text-[24px] text-[#011513]">
        {referralCategoryText} for free
      </p>

      <div className="flex gap-[8px]">
        <img src={mAndmLogo} alt="M&M" />
        <p className="md:mr-10 font-sans text-[12px] font-[400] text-[#666666]">
          Partnering with America’s largest {text} law firm{" "}
          <span className="font-[500] text-[#222222]">
            — Morgan & Morgan. <br />
            $25B+ recovered{" "}
          </span>{" "}
          in 35 years.
        </p>
      </div>
    </div>
  );
};

export default MMLegal;
