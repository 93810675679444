import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { Modal } from "antd";
import Fuse from "fuse.js";

import { useDispatch, useSelector } from "react-redux";
// import { X, FileArrowUp, ClockCounterClockwise, FileText, ArrowLeft } from "@phosphor-icons/react";

import { setDraftDocumentModalOpen, setonlyDraftType } from "../../../../modalSlice.js";
import {
  createNewParty,
  getSavedParties,
  resetCreateNewPartyStatus,
  setSelectedDocumentType,
  setSelectedParties,
} from "../../../Homepage/HomeSlice.js";
import ConcernedPartyScreenComponent from "./ConcernedPartyScreen.js";
import ChoosePartyScreenComponent from "./ChoosePartyScreen.js";
import AddPartyScreenComponent from "./AddPartyScreen.js";
import DocumentDetailsScreenComponent from "./DocumentDetailsScreen.js";
import DraftDocumentModalHeader from "./DraftDocumentModalHeader.js";
import FinalTouchesScreen from "./FinalTouchesScreen.js";
import { useWebSocketContext } from "../../../../WebsocketProvider";
import BrowseAll from "./BrowseAll.js";
import { setAskToDraftIntentWSResponse, setMessagingDisabled } from "../../messageSlice.js";
import { trackAnalytics } from "src/pages/helpers.js";
import applicationStore from "src/persistence/stores/RootStore.js";

const DraftDocumentQuestionaireModal = (props, ref) => {
  // console.log(props)
  const { email } = props;

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { isConnected, messages, sendMessage, createMessage } = useWebSocketContext();

  const [isDocumentDetailsScreen, setIsDocumentDetailsScreen] = useState(true);
  const [concernedPartiesScreen, setConcernedPartiesScreen] = useState(false);
  const [documentCreationMode, setDocumentCreationMode] = useState(true);
  const [choosePartyScreenOpen, setchoosePartyScreenOpen] = useState(false);
  const [addPartyScreenOpen, setAddPartyScreenOpen] = useState(false);
  const [browseAllScreen, setBrowseAllScreen] = useState(false);
  const [parties, setParties] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [finishingTouchesScreen, setFinishingTouchesScreen] = useState(false);
  const [selectedDocumentTypeTile, setSelectedDocumentTypeTile] = useState("");
  const [whichPartyIam, setWhichPartyIam] = useState("Primary Party");
  const [fileSelected, setFileSelected] = useState(null);
  const [finalNotes, setFinalNotes] = useState("");
  const [newParty, setNewParty] = useState({
    is_primary: false,
    is_entity: false,
    has_entity: "no",
    entity_type: null,
    full_name: "",
    state_incorporation: "California",
    primary_business_state: "California",
    address: "",
    description: "",
  });
  const [searchParty, setSearchParty] = useState("");
  const [editOldParty, setEditOldParty] = useState(false);
  const [editPartyId, setEditPartyId] = useState(null);
  const [referenceThreadId, setReferenceThreadId] = useState(null);

  const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen);
  const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);
  const draftTypes = useSelector((state) => state.homeslice.draftTypes);
  const partiesList = useSelector((state) => state.homeslice.partiesList);
  const selectedParties = useSelector((state) => state.homeslice.selectedParties);
  const documentType = useSelector((state) => state.modalSlice.draftModalDraftType);
  const selectedDocumentType = useSelector((state) => state.homeslice.selectedDocumentType);
  const createNewPartyStatus = useSelector((state) => state.homeslice.createNewPartyStatus);
  const askToDraftIntentWSResponse = useSelector((state) => state.messageSlice.askToDraftIntentWSResponse);
  const updatingParty = useSelector((state) => state.homeslice.updatingParty);
  const askToDraftIntent = useSelector((state) => state.messageSlice.askToDraftIntent);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  useEffect(() => {
    setIsDocumentDetailsScreen(false);
    setConcernedPartiesScreen(false);
    setDocumentCreationMode(true);
    setchoosePartyScreenOpen(false);
    setAddPartyScreenOpen(false);
    setParties([]);
    setBrowseAllScreen(false);
    setFilteredSuggestions([]);
    setIsDropdownVisible(false);
    setFinishingTouchesScreen(false);
    setSelectedDocumentTypeTile("");
    setWhichPartyIam("Primary Party");
    setFileSelected(null);
    setSearchParty("");
    resetParty();
    dispatch(resetCreateNewPartyStatus());
    dispatch(setSelectedParties([]));
    // askToDraftMove()
    if (documentType) {
      var doc = documentType;
      if (typeof documentType === "string") {
        doc = {
          id: null,
          name: documentType,
          category: null,
          draft_type: null,
          short_description: documentType,
          long_description: documentType,
          additional_search_terms: [],
          icon: null,
          common_use_cases: [],
        };
        setDocumentType(doc);
        // dispatch(setSelectedDocumentType(doc))
        setFinalNotes(documentType);
        setConcernedPartiesScreen(false);
        setIsDocumentDetailsScreen(false);
        createDraftContinueButtonClicked();
      } else {
        dispatch(setSelectedDocumentType(documentType));
        setIsDocumentDetailsScreen(false);
        setConcernedPartiesScreen(true);
        // setConcernedPartiesScreen(false);
        setDocumentCreationMode(true);
      }
    } else {
      setIsDocumentDetailsScreen(true);
    }
  }, [draftDocumentModalOpen]);

  useEffect(() => {
    setParties(partiesList);
  }, [partiesList]);

  useEffect(() => {
    filterParties(searchParty);
  }, [searchParty]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createNewPartyStatus === "success") {
      setAddPartyScreenOpen(false);
      setchoosePartyScreenOpen(true);
      if (updatingParty) {
        dispatch(getSavedParties());
      }
    }
  }, [createNewPartyStatus, updatingParty]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(askToDraftIntentWSResponse).length > 0) {
      const threadData = applicationStore.getState().messageSlice.threadData;
      if (threadData.id) {
        setReferenceThreadId(threadData.id);
      }
      askToDraftMove();
    }
  }, [askToDraftIntentWSResponse]);

  const askToDraftMove = () => {
    var updatedDoc;
    var docFound;
    if (askToDraftIntentWSResponse?.instruction_summary) {
      setFinalNotes(askToDraftIntentWSResponse.instruction_summary);
      updatedDoc = {
        id: askToDraftIntentWSResponse?.draft_type_id,
        name: askToDraftIntent?.draft_prompt,
        category: null,
        draft_type: "contract",
        short_description: askToDraftIntent?.instruction_summary,
        long_description: askToDraftIntent?.instruction_summary,
        additional_search_terms: [],
        icon: null,
        common_use_cases: [],
      };
      docFound = false;
    } else {
      updatedDoc = draftTypeSuggestions?.find((draft) => draft.id === askToDraftIntentWSResponse?.draft_type_id);
      docFound = true;
    }
    dispatch(setSelectedDocumentType(updatedDoc));
    setDocumentType(updatedDoc);
    if (docFound) {
      continueToConcernedParties();
    } else {
      createDraftContinueButtonClicked();
    }
  };

  const setDocumentType = (userInput) => {
    dispatch(setonlyDraftType(userInput));
  };

  const handleChange = (e) => {
    const userInput = e.target.value;
    setDocumentType({
      id: null,
      name: userInput,
      category: "",
      draft_type: "custom",
      description: "",
      min_parties: 1,
      max_parties: 2,
      party_tags: ["Party 1", "Party 2"],
    });

    if (userInput === "") {
      setFilteredSuggestions([]);
      setIsDropdownVisible(false);
      return;
    }
    const fuseOptions = {
      // keys: ['category', 'description', 'name'],
      // Removed description after Ryan Reported suggestions are irrelevant in (Ryan's usability testing file)
      keys: ["name"],
      threshold: 0.3, // Adjust the threshold to control the fuzzy matching sensitivity
    };

    const fuse = new Fuse(draftTypeSuggestions, fuseOptions);
    const searchText = userInput;
    const results = fuse.search(searchText);
    const filtered = results.map((result) => result.item);

    setFilteredSuggestions(filtered);
    setIsDropdownVisible(userInput.length > 0);
    setSelectedDocumentTypeTile("");
    dispatch(setSelectedDocumentType(documentType));
  };

  const handleSuggestionClick = (suggestion) => {
    setDocumentType(suggestion);
    setIsDropdownVisible(false);
    setSelectedDocumentTypeTile("");
    setDocumentType(suggestion);
    dispatch(setSelectedDocumentType(suggestion));
    continueToConcernedParties();
  };

  const onDropDownBlur = () => {
    setTimeout(() => {
      setIsDropdownVisible(false);
    }, 300);
  };
  const defaultNewParty = {
    is_primary: false,
    is_entity: true,
    has_entity: "no",
    entity_type: null,
    full_name: "",
    state_incorporation: "California",
    primary_business_state: "California",
    address: "",
    description: "",
  };
  const resetParty = () => {
    setNewParty(defaultNewParty);
  };

  const documentSuggestionClicked = (data) => {
    trackAnalytics(
      "Document Type Suggestions Clicked",
      {
        user_email: email,
        title: data.suggestion.display_prompt,
      },
      userMetadata
    );
    // window.analytics.track("Document Type Suggestions Clicked", {
    //     "user_email": email,
    //     "title": data.suggestion.display_prompt
    // })
    setDocumentType(data.suggestion);
    setSelectedDocumentTypeTile(data);
    dispatch(setSelectedDocumentType(data.suggestion));
    continueToConcernedParties();
  };

  const continueToConcernedParties = () => {
    setIsDocumentDetailsScreen(false);
    setConcernedPartiesScreen(true);
    // dispatch(setSelectedDocumentType(documentType));
    trackAnalytics("dashboard_home_draft_new_popup_step1continue_clicked", { user_email: email }, userMetadata);
    trackAnalytics(
      "Document Type Selected",
      {
        user_email: email,
        next: "Concerned parties screen",
      },
      userMetadata
    );
    // window.analytics.track("Document Type Selected", {
    //     user_email: email, "next": "Concerned parties screen"
    // })
  };

  const addNewPartyButtonClicked = () => {
    trackAnalytics(
      "Add Details button --> New party Screen",
      {
        user_email: email,
      },
      userMetadata
    );
    // window.analytics.track("Add Details button --> New party Screen", {
    //     user_email: email
    // });
    setConcernedPartiesScreen(false);
    setDocumentCreationMode(false);
    setIsDocumentDetailsScreen(false);
    setSearchParty("");
    setchoosePartyScreenOpen(true);
  };

  const backtoScreen1 = () => {
    trackAnalytics("dashboard_home_draft_new_popup_goback_clicked", { user_email: email }, userMetadata);
    trackAnalytics("Back to Document Type Selection screen", { user_email: email }, userMetadata);
    // window.analytics.track("Concerned Parties Screen -> Document Type Selection screen", { user_email: email })
    setConcernedPartiesScreen(false);
    setDocumentCreationMode(true);
    setIsDocumentDetailsScreen(true);
    setFinishingTouchesScreen(false);
  };

  const backToConcernedPartyScreen = () => {
    trackAnalytics("Back to Concerned Parties screen", { user_email: email }, userMetadata);
    // window.analytics.track("Select Party Screen -> Concerned Parties Screen", { user_email: email })
    setchoosePartyScreenOpen(false);
    setDocumentCreationMode(true);
    setConcernedPartiesScreen(true);
  };

  const addNewPartyTileClicked = () => {
    trackAnalytics("dashboard_home_draft_new_popup_addparty_clicked", { user_email: email }, userMetadata);
    trackAnalytics("Add New Party Tile Clicked", { user_email: email }, userMetadata);
    // window.analytics.track("Add New Party Tile Clicked", { user_email: email })
    setchoosePartyScreenOpen(false);
    setSearchParty("");
    resetParty();
    setAddPartyScreenOpen(true);
  };

  const backToSelectPartyScreen = () => {
    setAddPartyScreenOpen(false);
    setchoosePartyScreenOpen(true);
    trackAnalytics("moving back to choose party screen", { user_email: email }, userMetadata);
    // window.analytics.track("moving back to choose party screen", { user_email: email })
  };

  const addPartyButtonClicked = () => {
    trackAnalytics(
      "dashboard_home_draft_new_popup_addparty_confirmselection_clicked",
      { user_email: email },
      userMetadata
    );
    addParty();
    trackAnalytics("Add Party Button Clicked", { user_email: email, party: newParty }, userMetadata);
    // window.analytics.track("Add Party Button Clicked", { user_email: email, "party": newParty })
  };

  const createDraftContinueButtonClicked = () => {
    // if (selectedParties.length >= documentType.min_parties && selectedParties.length <= documentType.max_parties) {
    trackAnalytics("dashboard_home_draft_new_popup_step2continue_clicked", { user_email: email }, userMetadata);
    trackAnalytics("concerned parties finalised", { user_email: email, next: "/draft/id page" }, userMetadata);
    // window.analytics.track("concerned parties finalised", { user_email: email, "next": "/draft/id page" })
    setConcernedPartiesScreen(false);
    setIsDocumentDetailsScreen(false);
    setDocumentCreationMode(false);
    setFinishingTouchesScreen(true);
    dispatch(setSelectedDocumentType({}));
    // }
  };

  const closeModal = () => {
    trackAnalytics("Draft Document Modal Closed", { user_email: email }, userMetadata);
    // window.analytics.track("Draft Document Modal Closed", { user_email: email })
    dispatch(setDraftDocumentModalOpen(false));
    dispatch(setAskToDraftIntentWSResponse({}));
  };

  const backToSelectPartyScreen2 = () => {
    setFinishingTouchesScreen(false);
    setDocumentCreationMode(true);
    setConcernedPartiesScreen(true);
    trackAnalytics("moving back to choose party screen", { user_email: email }, userMetadata);
    // window.analytics.track("moving back to choose party screen", { user_email: email })
  };

  const browseAllClicked = () => {
    trackAnalytics("dashboard_home_draft_new_popup_browseall_clicked", { user_email: email }, userMetadata);
    setBrowseAllScreen(true);
    setDocumentCreationMode(false);
    setIsDocumentDetailsScreen(false);
  };

  const closeBrowseAll = () => {
    setBrowseAllScreen(false);
    setDocumentCreationMode(true);
    setIsDocumentDetailsScreen(true);
  };

  const draftTypeSelectedFromBrowseAll = (data) => {
    setBrowseAllScreen(false);
    setDocumentCreationMode(true);
    setIsDocumentDetailsScreen(false);
    setConcernedPartiesScreen(true);

    setSelectedDocumentTypeTile(data);
    setDocumentType(data.suggestion);
    setSelectedDocumentTypeTile(data);
    dispatch(setSelectedDocumentType(data.suggestion));
  };

  const filterParties = (searchParty) => {
    setParties(partiesList?.filter((party) => party.name?.toLowerCase().includes(searchParty?.toLowerCase())));
  };

  const updatePartyType = (yes) => {
    if (yes === "false") {
      setNewParty({ ...newParty, is_entity: false, entity_type: "Individual", has_entity: "no" });
    } else {
      setNewParty({ ...newParty, is_entity: true, has_entity: "yes" });
    }
  };

  const updateParty = (key, value) => {
    setNewParty({ ...newParty, [key]: value });
  };

  const addParty = () => {
    var entityInfo = null;
    var individualInfo = null;
    var primaryBusinessState = null;
    if (newParty.primary_business_state !== "") {
      primaryBusinessState = newParty.primary_business_state;
    }
    if (newParty.is_entity) {
      entityInfo = {
        type: newParty.entity_type,
        state_incorporation: newParty.state_incorporation,
        primary_business_state: primaryBusinessState,
        address: newParty.address,
        business_purpose: newParty.description,
      };
    } else {
      individualInfo = {
        state_residence: newParty.state_incorporation,
        description: newParty.description,
        address: newParty.address,
      };
    }

    const data = {
      is_primary: false,
      full_name: newParty.full_name,
      entity_info: entityInfo,
      individual_info: individualInfo,
    };

    dispatch(createNewParty(data));
    trackAnalytics("New Party added", { user_email: email, party: data }, userMetadata);
    // window.analytics.track("New Party added", { user_email: email, "party": data })
  };
  const editParty = () => {
    var entityInfo = null;
    var individualInfo = null;
    var primaryBusinessState = null;
    if (newParty.is_entity) {
      if (newParty.primary_business_state !== "") {
        primaryBusinessState = newParty.primary_business_state;
      }
      entityInfo = {
        type: newParty.entity_type,
        state_incorporation: newParty.state_incorporation,
        primary_business_state: primaryBusinessState,
        address: newParty.address,
        business_purpose: newParty.description,
      };
    } else {
      individualInfo = {
        state_residence: newParty.state_incorporation,
        description: newParty.description,
        address: newParty.address,
      };
    }
    const data = {
      id: editPartyId,
      is_primary: false,
      full_name: newParty.full_name,
      entity_info: entityInfo,
      individual_info: individualInfo,
    };
    // console.log("EditPartyId : " + data.id)
    // console.log(data)
    dispatch(createNewParty(data)).then((res) => {
      if (res.payload.isEdit) {
        setEditPartyId(null);
      }
    });
  };

  const confirmPartySelectionClicked = () => {
    trackAnalytics("Confirm Party Selections", { user_email: email }, userMetadata);
    // window.analytics.track("Confirm Party Selections", { user_email: email })
    setchoosePartyScreenOpen(false);
    setDocumentCreationMode(true);
    setConcernedPartiesScreen(true);
  };

  const createDraft = () => {
    trackAnalytics("dashboard_home_draft_new_popup_step3continue_clicked", { user_email: email }, userMetadata);
    trackAnalytics("Create Draft Button Clicked", { user_email: email }, userMetadata);
    // window.analytics.track("Create Draft Button Clicked", { user_email: email })
    var primaryParty = selectedParties?.filter((party) => party.is_primary === true);
    var otherParties = selectedParties?.filter((party) => party.is_primary !== true);
    var parties = {};
    var nonUserPartyTag = selectedDocumentType?.party_tags?.filter((tag) => tag !== whichPartyIam)[0];
    if (nonUserPartyTag) {
      otherParties.forEach((party, index) => {
        if (party.id !== undefined && party.id !== null) {
          parties[party.id] = nonUserPartyTag;
        }
      });
    }
    if (primaryParty[0]) {
      parties[primaryParty[0].id] = whichPartyIam;
    }
    // var otherParties = selectedParties.filter(party => party.is_primary !== true).map(party => party.id);
    var draftId = null;
    var documentName = null;
    var notes = finalNotes;
    // debugger;
    if (selectedDocumentType?.id) {
      draftId = selectedDocumentType?.id;
    } else {
      documentName = `${selectedDocumentType.name}`; // TODO
    }
    if (finalNotes && documentName) {
      documentName = `${documentName}`;
    }
    if (draftId) {
      notes = null;
    }

    var message = createMessage("draft", "create-thread", {
      message: notes,
      reference_doc_storage_id: null,
      draft_type_id: draftId,
    });
    // console.log(message)
    sendMessage(message, true);
    dispatch(setMessagingDisabled(true));
  };

  useImperativeHandle(ref, () => ({
    isDocumentDetailsScreen,
    concernedPartiesScreen,
    documentType,
    documentCreationMode,
    choosePartyScreenOpen,
    addPartyScreenOpen,
    addParty,
    parties,
    newParty,
    searchParty,
    draftDocumentModalOpen,
    partiesList,
    selectedParties,
    confirmPartySelectionClicked,
  }));

  return (
    <Modal
      open={draftDocumentModalOpen}
      className="draft-document-popup rounded overflow-hidden pb-[24px]"
      cancelButtonProps={{
        style: { display: "none" },
      }}
      footer={null}
    >
      <div className=" h-full flex flex-col pb-[36px]">
        {(documentCreationMode || finishingTouchesScreen) && (
          <>
            <DraftDocumentModalHeader
              isDocumentDetailsScreen={isDocumentDetailsScreen}
              concernedPartiesScreen={concernedPartiesScreen}
              finishingTouchesScreen={finishingTouchesScreen}
              closeModal={closeModal}
            />
          </>
        )}
        {/* <p>choosePartyScreenOpen ...+ {JSON.stringify(choosePartyScreenOpen)}</p> */}
        {/* <div className="md:hidden w-full py-20 flex justify-center items-center"> <p className="font-sans font-medium text-[16px]">This feature is available on desktop only.</p></div> */}
        <div className="">
          {isDocumentDetailsScreen && (
            <DocumentDetailsScreenComponent
              documentType={documentType}
              setDocumentType={setDocumentType}
              handleChange={handleChange}
              concernedPartiesScreen={concernedPartiesScreen}
              isDropdownVisible={isDropdownVisible}
              filteredSuggestions={filteredSuggestions}
              handleSuggestionClick={handleSuggestionClick}
              draftTypeSuggestions={draftTypeSuggestions}
              selectedDocumentTypeTile={selectedDocumentTypeTile}
              setSelectedDocumentTypeTile={setSelectedDocumentTypeTile}
              continueToConcernedParties={continueToConcernedParties}
              documentSuggestionClicked={documentSuggestionClicked}
              browseAllClicked={browseAllClicked}
              onDropDownBlur={onDropDownBlur}
              isDocumentDetailsScreen={isDocumentDetailsScreen}
              createDraftContinueButtonClicked={createDraftContinueButtonClicked}
            />
          )}

          {concernedPartiesScreen && (
            <ConcernedPartyScreenComponent
              backtoScreen1={backtoScreen1}
              createDraft={createDraft}
              createDraftContinueButtonClicked={createDraftContinueButtonClicked}
              addNewPartyButtonClicked={addNewPartyButtonClicked}
              whichPartyIam={whichPartyIam}
              closeModal={closeModal}
              setWhichPartyIam={setWhichPartyIam}
            />
          )}
          {finishingTouchesScreen && (
            <>
              <FinalTouchesScreen
                setFinishingTouchesScreen={setFinishingTouchesScreen}
                backToSelectPartyScreen2={backToSelectPartyScreen2}
                fileSelected={fileSelected}
                backtoScreen1={backtoScreen1}
                setFileSelected={setFileSelected}
                createDraft={createDraft}
                finalNotes={finalNotes}
                setFinalNotes={setFinalNotes}
                parties={parties}
                dispatch={dispatch}
                setSelectedParties={setSelectedParties}
                selectedParties={selectedParties}
              />
            </>
          )}
        </div>

        {choosePartyScreenOpen && (
          <ChoosePartyScreenComponent
            backToConcernedPartyScreen={backToConcernedPartyScreen}
            addNewPartyTileClicked={addNewPartyTileClicked}
            confirmPartySelectionClicked={confirmPartySelectionClicked}
            parties={parties}
            searchParty={searchParty}
            setSearchParty={setSearchParty}
            closeModal={closeModal}
            filterParties={filterParties}
            setEditPartyId={setEditPartyId}
            newParty={newParty}
            setNewParty={setNewParty}
          />
        )}
        {browseAllScreen && (
          <BrowseAll
            documentType={documentType}
            setDocumentType={setDocumentType}
            handleChange={handleChange}
            draftTypeSuggestions={draftTypeSuggestions}
            setBrowseAllScreen={setBrowseAllScreen}
            closeBrowseAll={closeBrowseAll}
            draftTypeSelectedFromBrowseAll={draftTypeSelectedFromBrowseAll}
          />
        )}
        {addPartyScreenOpen && (
          <AddPartyScreenComponent
            backToSelectPartyScreen={backToSelectPartyScreen}
            addPartyButtonClicked={addPartyButtonClicked}
            newParty={newParty}
            closeModal={closeModal}
            updateParty={updateParty}
            updatePartyType={updatePartyType}
            editPartyId={editPartyId}
            editParty={editParty}
          />
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(DraftDocumentQuestionaireModal);
