import { ChatsCircle, Lightning, PenNib, List, X, DotsThreeVertical, Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowUpgradePlanNudge } from "src/pages/onboarding/onboardingSlice";
import { resetPaymentStatus, setCheckoutUrl } from "../chatSlice";
import { trackAnalytics } from "src/pages/helpers";
import { generateSharedId, setShowChatActionModal, setThreadSelectedToShare } from "../threadsSlice";
import ShareThreadModal from "src/components/chat/Modals/ShareThreadModal";
import { ReviewStatus, PLANS, PLANS_V2 } from "src/components/Constants";
import ShareButton from "./ShareButton";
import TalkToDedicatedLawyerButton from "./TalkToDedicatedLawyerButton";
import inhouseImg from "../../../images/inhouse-rounded-logo.svg";
import { setMobileSideNav, setOpenLandingPageMenu } from "src/pages/Homepage/HomeSlice";
import IHLogoLarge from "../../../images/inhouse-logo-v2.png";
import { setShowSignupToUpgradeModal } from "src/pages/onboarding/onboardingSlice";
import ReviewRequestedButton from "./ReviewRequestedButton";
const TopNav = ({ page, showSteps, resetChat }) => {
  const dispatch = useDispatch();
  const [text, setText] = React.useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [duration, setDuration] = React.useState("");
  const [actionOnThread, setActionOnThread] = React.useState(""); // actions that can be done on a thread i.e Rename, Delete, Share
  const [updateActionLoading, setUpdateActionLoading] = React.useState(false);
  const [icon, setIcon] = React.useState(
    <span>
      <ChatsCircle size={16} className="text-[#B6B6B6]" />
    </span>
  );

  const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const options = { weekday: "long", day: "numeric", month: "short", year: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  const threads = useSelector((state) => state.threads.threads);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const email = useSelector((state) => state.onboarding.email);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const threadTitleTop = useSelector((state) => state.messageSlice.threadTitleTop);
  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);
  const viewChatHistory = useSelector((state) => state.homeslice.viewChatHistory);
  var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
  const userConfig = useSelector((state) => state.onboarding.userConfig);
  const openLandingPageMenu = useSelector((state) => state.homeslice.openLandingPageMenu);

  useEffect(() => {
    if (page === "chat" && Object.keys(threads).length > 0) {
      var keyName = "";
      for (var key in threads) {
        var index = threads[key].find((thread) => thread.id === threadData.id);
        if (index) {
          keyName = key;
          break;
        }
      }
      if (keyName) {
        setDuration(key);
      }
    }
  }, [page, threads]); // eslint-disable-line

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        dispatch(setOpenLandingPageMenu(true));
      } else {
        dispatch(setOpenLandingPageMenu(false));
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    if (window.location.pathname.search("draft") > -1) {
      setIcon(
        <span>
          <PenNib size={16} className="text-[#13866F]" />
        </span>
      );
    } else {
      setIcon(
        <span>
          <ChatsCircle size={16} className="text-[#B6B6B6]" />
        </span>
      );
    }
    if (page !== "ask_home") {
      if (threadData?.title) {
        setText(threadData.title);
      } else if (threadData?.isSharedThread || threadData.is_shared) {
        setText(threadData?.title);
      } else {
        if (threadTitleTop && threadTitleTop?.title) {
          setText(threadTitleTop.title);
        } else {
          if (threadData?.metadata?.created_at) {
            setText(new Date(threadData?.metadata?.created_at).toLocaleDateString("en-US", options));
          } else {
            setText(formattedDate);
          }
        }
      }
    } else {
      setText(formattedDate);
    }
  }, [threadData]); // eslint-disable-line

  const handleMobileSideNav = () => {
    dispatch(setMobileSideNav(true));
  };

  const openUpgradeModal = () => {
    trackAnalytics("subscription popup triggered", { position: "topnav", user_email: email }, userMetadata);
    trackAnalytics("dashboard_home_upgrade", { user_email: email }, userMetadata);
    dispatch(setCheckoutUrl(""));
    dispatch(resetPaymentStatus());
    dispatch(setShowUpgradePlanNudge(true));
  };

  const shareThread = () => {
    setActionOnThread("Share");
    trackAnalytics("thread shared: topnav", { thread_id: threadData.id, user_email: email }, userMetadata);
    dispatch(setThreadSelectedToShare(threadData.id));
    dispatch(generateSharedId({ thread: threadData, duration: duration }));
  };

  if (page === "home") {
    return (
      <div className="fixed top-0 w-full md:static flex flex-col z-[1]">
        <div className="flex justify-between items-center h-[54px] md:h-[74px] bg-[#FFFFFF] md:bg-transparent md:justify-end w-full py-[16px] mx-auto px-[12px] md:px-[48px] gap-[16px]">
          <div className="w-[80px] flex justify-start items-center gap-[12px]">
            <List onClick={handleMobileSideNav} size={26} className="my-4 md:hidden" />
            <img src={inhouseImg} alt="Inhouse logo" className="w-[24px] h-[24px] md:hidden" />
          </div>
          {userConfig?.homepage?.topnav ? userConfig?.homepage?.topnav({ dispatch, userMetadata, email, page }) : ""}
        </div>
        {/* {!showRequestLegalReviewAside &&
          !viewChatHistory &&
          userMetadata.subscription_type !== PLANS.PERSONAL_FREE &&
          userMetadata.subscription_type !== PLANS.SUBSCRIBER_ENTERPRISE && (
            <div className="md:hidden w-full h-[41px] bg-[#fbf6e7] flex justify-center items-center border-[1px] border-[#E4E4E4] p-[12px]">
              {showPlansMobileView()}
            </div>
          )} */}
      </div>
    );
  } else if (page === "landing-page" || page === "try") {
    const menuOptions = [
      { name: "Home", link: "https://www.inhouse.app/" },
      { name: "About", link: "https://www.inhouse.app/about" },
      { name: "Pricing", link: "https://www.inhouse.app/pricing" },
    ];
    return (
      <div
        className={`fixed top-0 w-full ${
          openLandingPageMenu ? "h-screen" : "h-[80px]"
        } shadow-sm md:!shadow-none bg-[#FFFFFF] md:bg-[#FAF9F7] md:h-[80px] flex flex-col md:flex-row justify-between md:items-center md:px-[32px] md:py-[16px] z-[2]`}
      >
        <div className="flex items-center gap-4 px-3">
          {page === "landing-page" && (
            <div className={`md:hidden`}>
              {openLandingPageMenu ? (
                <span className="cursor-pointer" onClick={() => dispatch(setOpenLandingPageMenu(false))}>
                  <X size={32} />
                </span>
              ) : (
                <span className=" cursor-pointer" onClick={() => dispatch(setOpenLandingPageMenu(true))}>
                  <List size={32} />
                </span>
              )}
            </div>
          )}
          <a href="https://inhouse.app/" target="_blank">
            <img
              src={IHLogoLarge}
              className="w-[100px] h-[42px] mx-[32px] md:px-[0] my-[16px] md:my-0 object-contain cursor-pointer"
              alt="Inhouse"
            />
          </a>
          {page === "try" && (
            <div
              className="bg-[#748A8C14] rounded-[50%] border-2 flex h-[40px] w-[40px] cursor-pointer md:hidden"
              onClick={resetChat}
            >
              <Plus size={20} className="mx-auto my-auto" />
            </div>
          )}
          {!openLandingPageMenu && (
            <button
              className="absolute right-3 rounded-[100px] px-[24px] py-[12px] font-sans font-medium text-[14px] bg-[#1B2B48] text-white"
              onClick={() => dispatch(setShowSignupToUpgradeModal({ show: true, slide: "signup" }))}
            >
              Start for free
            </button>
          )}
        </div>

        {openLandingPageMenu && (
          <div className="flex-1 md:h-auto bg-[#ffff] md:bg-[#FAF9F7] w-full flex flex-col py-6 md:py-0 md:flex-row justify-end items-center">
            {page === "landing-page" && (
              <div className="flex flex-col md:flex-row gap-[10px] md:gap-[40px] flex-1 w-full md:justify-center px-3">
                {menuOptions.map((menu) => {
                  return (
                    <a
                      href={menu?.link}
                      target="blank"
                      className="font-bold md:font-normal hover:font-semibold text-[18px] md:text-[14px] font-sans text-[#686868] hover:text-[#1B2B48]"
                    >
                      {menu?.name}
                    </a>
                  );
                })}
              </div>
            )}
            <div className="flex w-full md:w-auto px-3 md:px-0 flex-col-reverse md:flex-row gap-[16px] md:gap-[8px]">
              <button
                className="rounded-[100px] text-[14px] text-[#686868] px-[24px] py-[12px] font-[400]"
                onClick={() => {
                  trackAnalytics("trypage-login-button-topnav");
                  dispatch(setShowSignupToUpgradeModal({ show: true, slide: "login" }));
                }}
              >
                Log In
              </button>
              <button
                className="border-[1px] rounded-[100px] px-[24px] py-[12px] font-sans font-medium text-[14px] bg-[#1B2B48] text-white"
                onClick={() => {
                  trackAnalytics("trypage-signup-button-topnav");
                  dispatch(setShowSignupToUpgradeModal({ show: true, slide: "signup" }));
                }}
              >
                {page === "landing-page" ? "Sign up" : "Sign up"}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  } else if (page === "onboarding") {
    return (
      <div className="fixed top-0 w-full md:static flex flex-col ">
        <div className="flex justify-between items-center h-[54px] md:h-[74px] bg-[#FFFFFF] md:bg-transparent md:justify-end w-full py-[16px] mx-auto px-[12px] md:px-[48px] gap-[16px]">
          <div className="flex justify-center items-center gap-[12px]">
            <List onClick={handleMobileSideNav} size={26} className="my-4 mx-2 md:hidden" />
            <img src={inhouseImg} alt="Inhouse logo" className="w-[24px] h-[24px] md:hidden" />
          </div>
          {showSteps()}
        </div>
      </div>
    );
  } else if (page === "ask_home" || page === "chat") {
    var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);

    var meetingScheduled = false;
    if (userMetadata?.subscription_type === PLANS.PERSONAL_FREE && legalReview?.lr_lawyer_matching_meeting_time) {
      meetingScheduled = true;
    } else if (userMetadata?.lawyer_matching_meeting_at) {
      meetingScheduled = true;
    }
    return (
      <div className="fixed top-0 w-full md:static flex flex-row h-[54px] md:h-[74px]  bg-[#FAF9F7] z-[1]  ">
        <div className="flex justify-between w-full py-[12px]  px-[24px] ">
          <List onClick={handleMobileSideNav} size={26} className="md:hidden" />
          <h1 className="hidden md:flex flex-row gap-[8px] my-auto py-auto">
            {icon}
            <span className="text-[14px] leading-[21px] font-[500] my-auto">{text}</span>
          </h1>
          {page === "chat" && (
            <div className="flex justify-between gap-[16px]">
              {!legalReview ? (
                <>{userMetadata?.lawyer_info?.name && <TalkToDedicatedLawyerButton userMetadata={userMetadata} />}</>
              ) : (
                <>
                  <ReviewRequestedButton />
                </>
              )}

              <div className="hidden md:block">
                <ShareButton shareThread={shareThread} />
              </div>
              <div
                className="md:hidden flex justify-center items-center border w-[30px] h-[30px] rounded-[6px]"
                onClick={() => dispatch(setShowChatActionModal(true))}
              >
                <DotsThreeVertical size={32} />
              </div>
            </div>
          )}
          <ShareThreadModal
            open={actionOnThread === "Share"}
            setActionOnThread={setActionOnThread}
            actionOnThread="share"
            threadInfo={{ thread: threadData }}
            loading={updateActionLoading}
            setLoading={setUpdateActionLoading}
          />
        </div>
      </div>
    );
  } else {
    return <div className="flex flex-row w-full h-full py-auto"></div>;
  }
};

export default TopNav;
