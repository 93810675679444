import Modal from "./Modal";
import stars from "../images/3-stars.svg";
import { useAuth, useUser } from "@clerk/clerk-react";
import OnboardingV2 from "src/pages/onboardingV2";
import toast from "react-hot-toast";
import { getUserMetadata, setShowPersonalizePopup } from "src/pages/onboarding/onboardingSlice";
import { useDispatch, useSelector } from "react-redux";

const PersonalizePopup = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { getToken } = useAuth();

  const showPersonalizePopup = useSelector((state) => state.onboarding.showPersonalizePopup);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  return (
    <Modal
      contentAreaStyles={{ paddingLeft: "0px", paddingRight: "0px", borderRadius: "16px" }}
      isOpen={showPersonalizePopup}
      bodyStyle={{ paddingBottom: "0px", borderRadius: "16px", paddingTop: "0px" }}
      className="w-[700px] h-full"
      onClose={() => {
        dispatch(setShowPersonalizePopup(false));
      }}
    >
      <div className="flex flex-col w-full md:w-[632px]">
        <div className="w-full px-[18px] pt-[24px] flex gap-[10px]">
          <p className="font-[lora] font-bold italic text-[#141414] text-[24px]">Personalize Inhouse AI</p>
          <img src={stars} alt="Starts" className="w-[22px] h-[36px]" />
        </div>
        <div className="w-full px-[23px] py-[15px]">
          <OnboardingV2
            user={user}
            getToken={getToken}
            sidenav={true}
            moveNext={() => {
              toast.success("Profile Updated");
              dispatch(getUserMetadata());
              dispatch(setShowPersonalizePopup(false));
            }}
            isInPopup={true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PersonalizePopup;
