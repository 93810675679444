import Modal from "src/components/Modal";
import { Check, ArrowRight } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userDataTryPage } from "src/components/Constants";
import { setUpgradeModalData } from "../onboarding/onboardingSlice";

const UpgradePopupTryPage = () => {
  const dispatch = useDispatch();
  const onClose = () => {
    return dispatch(setUpgradeModalData({ data: {}, show: false }));
  };
  const upgradeModalData = useSelector((state) => state.onboarding.upgradeModalData);
  const showUpgradeModalTryPage = useSelector((state) => state.onboarding.showUpgradeModalTryPage);

  // useEffect(() => {
  //   dispatch(setUpgradeModalData({ data: userDataTryPage?.upgrade_to_counsel, show: true }));
  // }, []);

  return (
    <Modal
      isOpen={showUpgradeModalTryPage}
      bodyClassName={"py-0 !rounded-[16px]"}
      onClose={() => onClose()}
      contentAreaStyles={{ paddingLeft: "0px", paddingRight: "0px", borderRadius: "16px" }}
      bodyStyle={{ paddingBottom: "0px", paddingTop: "0px", borderRadius: "16px" }}
    >
      <div className="w-full md:w-[889px] md:h-[617px] flex flex-row md:flex-col rounded-[16px]">
        <div className="left md:w-[62%] md:max-w-[555px] h-full p-12 flex flex-col justify-between">
          <div className="flex flex-col gap-[40px]">
            <div className="flex flex-col gap-[10px]">
              <p>
                <span className="bg-gradient-to-r from-[#4F45F4] via-[#7E1586] to-[#DA6390] bg-clip-text text-transparent font-medium font-sans text-[12px]">
                  {upgradeModalData?.textLine1}
                </span>
              </p>
              {upgradeModalData?.textLine2}
            </div>
            <div className="flex gap-[16px] flex-col">
              <p className="font-medium text-[14px] font-sans text-[#353535]">Also get these perksa:</p>
              <ul className="gap-[12px] flex flex-col">
                {upgradeModalData?.features?.map((feature, index) => (
                  <li className="flex gap-[10px]">
                    <Check size={14} weight="bold" className="text-[#248370]" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex justify-between items-center">
            {upgradeModalData?.learnMore}
            <button className="flex justify-center items-center gap-[12px] bg-[#1B2B48] p-[12px] rounded-[8px] text-white font-medium text-[14px] font-sans">
              {upgradeModalData?.btnName}{" "}
              <span>
                <ArrowRight size={18} />
              </span>{" "}
            </button>
          </div>
        </div>
        {upgradeModalData?.rightSection}
      </div>
    </Modal>
  );
};

export default UpgradePopupTryPage;
