import React from "react";
import { X, VideoCamera, Question } from "@phosphor-icons/react";
import Modal from "src/components/Modal";
import { useDispatch } from "react-redux";
import { setShowScheduleMeeting } from "src/pages/onboarding/onboardingSlice";

const SupportPopup = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();

  const scheduleCallClicked = () => {
    dispatch(
      setShowScheduleMeeting({
        show: true,
        lawyer_details: { calendly_link: process.env.REACT_APP_CALENDLY_MEETING_ID },
      })
    );
    onClose();
  };

  if (!isVisible) return null;

  return (
    <Modal
      isOpen={isVisible}
      bodyClassName={"py-0 !rounded-[16px]"}
      onClose={onClose}
      contentAreaStyles={{ paddingLeft: "0px", paddingRight: "0px", borderRadius: "16px" }}
    >
      <div className="w-full md:w-[800px] px-[10px] md:px-[40px] py-[32px] bg-white flex flex-col gap-[20px] md:gap-[40px] justify-center items-center rounded-lg shadow-lg">
        <span className="text-[#343330]">
          {" "}
          <Question size={24} />
        </span>
        <div className="flex flex-col gap-[4px]">
          <h2 className="text-[24px] font-semibold text-[#353535] text-center leading-[33.6px]">Looking for help?</h2>
          <p className="text-[14px] font-medium text-[#011513] text-center leading-[21px]">
            Drop an email to{" "}
            <a
              href="mailto:support@inhouse.app"
              className="font-medium text-[#18475A]"
              target="_blank"
              rel="noreferrer"
            >
              support@inhouse.app
            </a>{" "}
            or schedule a call with our support team
          </p>
        </div>
        <span className="flex justify-end">
          <button
            className="bg-[#1B2B48] px-[24px] py-[12px] rounded-[8px] gap-[12px] flex text-[14px] font-medium  justify-center items-center text-white"
            onClick={() => {
              scheduleCallClicked();
            }}
          >
            Schedule a Call
            <span>
              <VideoCamera size={18} />
            </span>
          </button>
        </span>
      </div>
    </Modal>
  );
};

export default SupportPopup;
