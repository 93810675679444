import React, { useEffect, useState } from "react";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import ChangeParty from "../Prompts/ChangeParty";
import { useSelector } from "react-redux";
import { formatText } from "src/pages/helpers";

const MessageRender = ({
  message,
  userType,
  avatar,
  isFirstAiMessage,
  sharedpage,
  chatInfo,
  userMetadata,
  threadMessages,
  index,
}) => {
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const [expanded, setExpanded] = useState(false);
  const [shortMessage, setShortMessage] = useState(null);
  const [showCollapse, setShowCollapse] = useState(false);
  const [see, setsee] = useState("See");

  const wordLimit = 250;
  useEffect(() => {
    if (chatInfo?.payload?.quick_action_buttons?.length > 0 && index === threadMessages.length - 1) {
      setShortMessage(message);
      setShowCollapse(false);
    } else {
      const words = message ? message.split(" ") : [];
      setShortMessage(words.slice(0, wordLimit).join(" ") + (words.length > wordLimit ? "..." : ""));
      setShowCollapse(words.length > wordLimit);
    }
  }, [message, threadData?.messages]);

  const getUserInitials = () => {
    const firstInitial = userMetadata?.first_name?.[0]?.toUpperCase() || "";
    const lastInitial = userMetadata?.last_name?.[0]?.toUpperCase() || "";
    return firstInitial + lastInitial;
  };
  return (
    <div className="message-render">
      {userType === "user" && (
        <div
          className={`gap-[10px] flex px-[14px] leading-[160%] bg-[#FFFFFF] rounded-[12px] userMessageBoxChatPage py-[16px] text-[#1B2B48] font-sans font-[500]  mt-[20px] ${
            message !== undefined && message?.split(" ").length < 35 ? "text-[14px]" : ""
          }
                    ${
                      message !== undefined && message?.split(" ").length < 50 && message?.length >= 35 && "text-[14px]"
                    } ${message !== undefined && message?.length >= 250 && "text-[14px]"}</>
                    }}`}
        >
          <div>
            <div className="w-[32px] h-[32px] box-border flex justify-center items-center bg-[#1B2B48] rounded-full px-[5px] py-[4px]">
              <p className="text-[#FFFFFF] font-[700] text-[14px] font-sans">{getUserInitials()}</p>
            </div>
          </div>
          <div className="chat">
            {isFirstAiMessage && <img src={avatar} alt="AI" className="w-[32px] h-[32px] mt-[32px] mb-[20px]" />}
            {message && (
              <div>
                <Markdown remarkPlugins={[remarkGfm]}>
                  {expanded ? formatText(message) : formatText(shortMessage)}
                </Markdown>
                {showCollapse && (
                  <span
                    className="text-blue-500 text-[14px] cursor-pointer underline"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? "See less" : "See more"}
                  </span>
                )}
              </div>
            )}
            {window.location.pathname !== "/try" && (
              <>
                {!sharedpage && (chatInfo?.show_party_update_option || chatInfo?.payload?.allow_update_party) && (
                  <ChangeParty chatInfo={chatInfo} />
                )}
              </>
            )}
          </div>
        </div>
      )}

      {userType === "ai" && (
        <div
          className={`flex rounded-b-[5px] text-[14px] md:text-[16px] leding-[22px] md:leading-[25.6px] text-[#333333]}`}
        >
          <div className="chat my-3 max-w-full w-screen md:w-full">
            {/* {isFirstAiMessage && <img src={avatar} alt="AI" className="w-[32px] h-[32px] mt-[16px] mb-[10px]" />} */}
            {message && (
              <div>
                <Markdown remarkPlugins={[remarkGfm]}>
                  {`${expanded ? formatText(message) : formatText(shortMessage)}`}
                </Markdown>
                {showCollapse && (
                  <span
                    className="text-[#3F65A9] font-[600] mx-4 text-[14px] cursor-pointer underline"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? "See less" : "See more"}
                  </span>
                )}
              </div>
            )}
            {window.location.pathname !== "/try" && (
              <>
                {!sharedpage && (chatInfo?.show_party_update_option || chatInfo?.payload?.allow_update_party) && (
                  <ChangeParty chatInfo={chatInfo} />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageRender;
