import React from "react";
import { setShowUpgradePlanNudge } from "../onboarding/onboardingSlice";
import { useDispatch } from "react-redux";
import { useAuth, useUser } from "@clerk/clerk-react";
import OnboardingV2 from ".";

const OnboardingBase = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { getToken } = useAuth();

  const moveNext = (next) => {
    const queryParams = new URLSearchParams(window.location.search);
    var thread_id = queryParams.get("thread_id");
    switch (next) {
      case "ask":
        window.location.href = `/ask`;
        break;
      case "draft":
        window.location.href = `/?open_draft=legal review request`;
        break;
      case "review":
        window.location.href = `/`;
        break;
      case "membership":
        dispatch(setShowUpgradePlanNudge(true));
        window.location.href = "/?open_membership=true";
        break;
      case "pro":
        if (thread_id) {
          window.location.href = `/chat/${thread_id}?open_pro=true`;
        } else {
          window.location.href = `/home?open_pro=true`;
        }
        break;
      case "counsel":
        if (thread_id) {
          window.location.href = `/chat/${thread_id}?open_counsel=true`;
        }
        window.location.href = `/home?open_counsel=true`;
        break;
      default:
        window.location.href = `/`;
    }
  };
  return <OnboardingV2 user={user} getToken={getToken} sidenav={false} moveNext={moveNext} />;
};

export default OnboardingBase;
