import { ArrowRight, Check, SealCheck, VideoCamera } from "@phosphor-icons/react";
import Modal from "src/components/Modal";
import { setShowUpgradePlanPopupChatPage } from "src/pages/onboarding/onboardingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { upgradeButtonClicked } from "src/pages/helpers";

import { toast } from "react-toastify";

const UpgradePlanPopup = () => {
  const dispatch = useDispatch();
  const showUpgradePlanPopupChatPage = useSelector((state) => state.onboarding.showUpgradePlanPopupChatPage);
  const documentNameShortHand = useSelector((state) => state.onboarding.documentNameShortHand);
  const plansToShow = useSelector((state) => state.onboarding.plansToShow);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const email = useSelector((state) => state.homeslice.email);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const checkoutUrlStatus = useSelector((state) => state.chat.checkoutUrlStatus);

  useEffect(() => {
    if (["idle", "loading", "success", undefined].indexOf(checkoutUrlStatus) === -1) {
      toast.error(checkoutUrlStatus);
    }
  }, [checkoutUrlStatus]);

  return (
    <Modal
      isOpen={showUpgradePlanPopupChatPage}
      bodyClassName={"py-0 !rounded-[16px]"}
      onClose={() => dispatch(setShowUpgradePlanPopupChatPage({ plans: {}, show: false }))}
      contentAreaStyles={{ paddingLeft: "0px", paddingRight: "0px", borderRadius: "16px" }}
      bodyStyle={{ paddingBottom: "0px", paddingTop: "0px", borderRadius: "16px" }}
    >
      <div className="w-full md:w-[889px] md:h-[617px] flex flex-col md:flex-row rounded-[16px]">
        <div className="left w-full md:w-[62%] md:max-w-[555px] h-full p-12 flex flex-col justify-between">
          <div className="flex flex-col gap-[40px]">
            <div className="flex flex-col gap-[10px]">
              <p>
                <span className="bg-gradient-to-r from-[#4F45F4] via-[#7E1586] to-[#DA6390] bg-clip-text text-transparent font-medium font-sans text-[12px]">
                  {plansToShow?.textLine1}
                </span>
              </p>
              <p className="font-[lora] font-bold italic text-[30px] text-[#353535]">
                {documentNameShortHand ? documentNameShortHand : "Talk to an expert lawyer"} at just{" "}
                <span className="text-[#248370]"> $149.</span>
              </p>
            </div>
            <div className="flex gap-[16px] flex-col">
              <ul className="gap-[12px] flex flex-col">
                {plansToShow?.featuresDedicatedPage?.map((feature, index) => (
                  <li className="flex gap-[10px]">
                    <div className="w-[30px] ">
                      <SealCheck size={19} weight="fill" className="text-[#248370]" />
                    </div>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex md:flex-row flex-col-reverse mt-4 md:mt-0 gap-[8px] md:gap-0">
            <div className="flex my-auto">{plansToShow?.learnMore}</div>
            <div className="flex my-auto">
              <button
                className="flex gap-[12px] bg-[#1B2B48] p-[12px] rounded-[8px] text-white font-medium text-[14px] my-auto font-sans w-full md:w-[150px] text-center"
                onClick={() => {
                  upgradeButtonClicked(dispatch, threadData, email, userMetadata, plansToShow, "single_plan_selection");
                }}
                disabled={checkoutUrlStatus === "loading"}
              >
                <div className="mx-auto">
                  {checkoutUrlStatus === "loading" && (
                    <div className="flex w-full">
                      <div className="w-4 h-4 border-2 border-t-0 border-white rounded-full animate-spin mx-auto"></div>
                    </div>
                  )}
                  {checkoutUrlStatus !== "loading" && (
                    <div className="flex gap-[12px]">
                      {plansToShow?.btnName}
                      {plansToShow?.btnName ? <ArrowRight size={16} className="my-auto" /> : ""}
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
        {plansToShow?.rightSection}
      </div>
    </Modal>
  );
};

export default UpgradePlanPopup;
