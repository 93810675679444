import React, { useEffect, useState, useRef } from "react";
import TopNav from "./Navs/TopNav";
import ChatBoxComponent from "./components/ChatBoxComponent";
import { PenNib, FileText, ChatsCircle, Gavel, ArrowDown, OpenAiLogo, Briefcase, Info } from "@phosphor-icons/react";
import { UsersThree } from "@phosphor-icons/react/dist/ssr";
import ratingStar from "../../images/rating-star.svg";
import { suggestionBtnsLandingPage } from "src/components/Constants";
import { useDispatch } from "react-redux";
import { setSentences } from "./messageSlice";
import { trackAnalytics } from "../helpers";

const LandingPage = (props) => {
  const { inputMessage, setInputMessage, onsubmit, referenceDoc, handleFileChange, uploadPercentage, setReferenceDoc } =
    props;

  const fileInputRef = useRef(null);
  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };
  const dispatch = useDispatch();
  dispatch(setSentences(["Ask me any legal questions here", "Draft a contract that...", "Review a lease for..."]));

  const listItems = [
    {
      icon: <OpenAiLogo weight="bold" size={20} className="text-[#666666] my-auto" />,
      text: "#1 Legal AI on ChatGPT store",
    },
    { icon: <img src={ratingStar} alt="Stars" />, text: "Trusted by 4,000+ clients" },
    {
      icon: <Briefcase weight="bold" size={20} className="text-[#666666] my-auto" />,
      text: "Supported by 2,000+ lawyers",
    },
  ];

  const suggestionBtnClicked = (btnText, buttonFun) => {
    setInputMessage(btnText);
    trackAnalytics("try-page-suggestion-button-clicked", { "button-text": btnText });
    if (buttonFun === "Review") {
      handleFileButtonClick();
    }
  };

  return (
    <div className="flex flex-col justify-between items-center w-full h-screen">
      <TopNav page="landing-page" />
      <div className="w-full h-screen md:mx-auto py-[120px]">
        <div className="flex justify-center items-center flex-col gap-[4px] md:gap-[8px] py-4 md:!pt-16">
          <p className="font-[500] text-[16px] font-sans text-[#686868]">
            Meet your{" "}
            <span className="bg-gradient-to-r from-[#4F45F4] via-[#7E1586] to-[#DA6390] bg-clip-text text-transparent">
              AI-powered inhouse counsel
            </span>
          </p>
          <p className="font-[lora] font-[700] italic text-[32px] text-[#1B2B48]">How can we help you?</p>
          <div className="w-full">
            <ChatBoxComponent
              size="full"
              allowAttachReference={true}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              onsubmit={onsubmit}
              privacyLink={false}
              referenceDoc={referenceDoc}
              handleFileChange={handleFileChange}
              percentage={uploadPercentage}
              setReferenceDoc={setReferenceDoc}
              fileuploadSuccess={uploadPercentage}
              fileInputRef={fileInputRef}
            />
          </div>

          <div className=" flex flex-wrap gap-[16px] justify-center items-center">
            {suggestionBtnsLandingPage.map((button) => {
              return (
                <p
                  className={`cursor-pointer flex justify-center items-center gap-[8px] font-sans font-medium text-[14px] text-[#222222] px-[16px] py-[8px] border-[1px] bg-white border-[#E4E4E4] ${button.hover} rounded-[98px] `}
                  onClick={() => suggestionBtnClicked(button.text, button.fun)}
                >
                  <span>{button.icon}</span>
                  {button.text}
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex items-center pb-4 gap-1">
        <Info className="text-[#7F8C8D] my-[2px]" size={16} />
        <p className="flex flex-row text-center text-[12px] leading-[150%] font-[400] text-[#686868] gap-[10px] justify-center">
          <span>
            AI can make mistakes, always consult a lawyer. Read our{" "}
            <a href="https://www.inhouse.app/terms-of-service" target="blank" className="underline">
              Terms
            </a>{" "}
            and{" "}
            <a href="https://www.inhouse.app/privacy-policy" target="blank" className="underline">
              {" "}
              Privacy Policy
            </a>
            .
          </span>
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
