import React from "react";
import { ReviewStatus } from "src/components/Constants";
import RequestReviewFormAside from "../RequestReviewFormAside";
import LegalReviewRequestedSuccessfully from "../LegalReviewRequestedSuccessfully";
import LegalReviewCompleteAsidePersonal from "../Personal/LegalReviewCompleteAsidePersonal";
import emailLogo from "src/images/emailLogo.svg";
import ReviewRequestedSuccessMessage from "../ReviewRequestedSuccessMessage";
import LawyerassignmentPending from "../LawyerassignmentPending";

const LegalReviewRequestBusiness = (props) => {
  const {
    email,
    trackAnalytics,
    dispatch,
    metadata,
    legalReiviewImg,
    requestLegalReviewStatus,
    text,
    setText,
    loading,
    setShowLearnMoreModal,
    phoneNumber,
    setPhoneNumber,
    notice,
    legalReview,
  } = props;
  return (
    <>
      {window.location.pathname === "/home" ? (
        <>
          {requestLegalReviewStatus === "success" ? (
            <LegalReviewRequestedSuccessfully metadata={metadata} dispatch={dispatch} email={email} />
          ) : (
            <RequestReviewFormAside
              email={email}
              trackAnalytics={trackAnalytics}
              dispatch={dispatch}
              inputMandatory={false}
              text={text}
              setText={setText}
              loading={loading}
              setShowLearnMoreModal={setShowLearnMoreModal}
              legalReiviewImg={legalReiviewImg}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              notice={notice}
            />
          )}
        </>
      ) : (
        <>
          {metadata?.lawyer_info && (
            <>
              {/* dedicated lawyer  */}
              {requestLegalReviewStatus === "success" ? (
                <LegalReviewRequestedSuccessfully metadata={metadata} dispatch={dispatch} email={email} />
              ) : (
                <>
                  {" "}
                  {legalReview ? (
                    <LegalReviewRequestedSuccessfully email={email} dispatch={dispatch} metadata={metadata} />
                  ) : (
                    // legal review is not yet requested
                    <>
                      <div className="bg-white px-[53px] h-full w-full flex gap-[24px] flex-col justify-center items-center py-[50px]">
                        <img src={emailLogo} alt="Email logo" className="w-[87px] h-[107px] md:w-auto md:h-auto" />
                        <p className="font-semibold text-[18px] md:text-[24px] font-sans text-center">
                          Share this thread with your lawyer — <span>{metadata?.lawyer_info?.name}</span>
                        </p>
                        <p className="font-normal text-[12px] md:text-[16px] font-sans text-[#353535] text-center">
                          Your current thread will be shared with them <br />
                          for review on their email.
                        </p>
                        <p className="font-medium text-[12px] md:text-[16px] font-sans text-[#141414] text-center">
                          An email thread will be created for you to <br />
                          continue communication and receive updates.
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {/*  no dedicated lawyer */}
          {!metadata?.lawyer_info && (
            <>
              {/* review is not yet requested */}
              {!legalReview && (
                <>
                  <RequestReviewFormAside
                    email={email}
                    trackAnalytics={trackAnalytics}
                    dispatch={dispatch}
                    inputMandatory={false}
                    text={text}
                    setText={setText}
                    loading={loading}
                    setShowLearnMoreModal={setShowLearnMoreModal}
                    legalReiviewImg={legalReiviewImg}
                    phoneNumber={phoneNumber}
                    notice={notice}
                    setPhoneNumber={setPhoneNumber}
                  />
                </>
              )}
              {legalReview && (
                <>
                  {/* case: fresh request */}
                  {/* if meeting is already scheduled, we will not show the schedule call button */}
                  {/* {(requestLegalReviewStatus === "success") ? <>
                                    <ReviewRequestedSuccessMessage
                                        metadata={metadata}
                                        dispatch={dispatch}
                                        email={email}
                                    />
                                </>
                                : */}
                  <>
                    {/* // review already requested */}
                    {legalReview?.status === ReviewStatus.pending_lawyer_matching ? (
                      <LawyerassignmentPending />
                    ) : (
                      <LegalReviewRequestedSuccessfully />
                    )}
                  </>
                  {/* } */}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LegalReviewRequestBusiness;
