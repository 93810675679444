import { ArrowRight, Check, Info } from "@phosphor-icons/react";
import React from "react";
import { useDispatch } from "react-redux";
import { PLANS } from "src/components/Constants";
import { setShowRequestLegalReviewAside } from "src/pages/onboarding/onboardingSlice";

const ReferralRequested = ({ email }) => {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(setShowRequestLegalReviewAside(false));
  };
  return (
    <div className="w-full flex flex-col h-screen justify-between">
      <div className="w-full flex"></div>
      <div className="px-[90px] flex flex-col justify-center items-center gap-[24px]">
        <div className="w-[53px] h-[53px] mb-2j">
          <Check
            size={24}
            weight="bold"
            className="border-2 rounded-full w-[56px] h-[56px] p-2 font-bold bg-[#137513] text-white"
          />
        </div>
        <p className="text-[24px] font-[600]">Thank you!</p>
        <p className="font-normal font-sans text-[16px] text-center text-[#353535]">
          {email
            ? `We will reach out to you on ${email} or your SMS once we find an expert suited for your case.`
            : "We will reach out to you on your SMS once we find an expert suited for your case."}
        </p>
      </div>
      <section className="w-full">
        <div className="flex flex-row w-full justify-between gap-[12px] border-t border-t-[#E4E4E4] mt-4 py-3 px-6">
          <div></div>
          <button
            className={`bg-[#1B2B48] text-white font-medium rounded-lg flex items-center
                                    h-[48px] gap-[12px] py-[12px] px-[12px] disabled:!bg-[#949494]`}
            onClick={close}
          >
            <span className="my-auto w-[105px]">OK, got it!</span>
            <ArrowRight size={18} className="my-auto text-white" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default ReferralRequested;
