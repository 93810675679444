import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CaretDoubleRight, X } from "@phosphor-icons/react";
import {
  requestLegalReview,
  resetRequestLegalReviewFailureReason,
  resetStoreReferralStatus,
  storeReferral,
} from "src/pages/onboarding/onboardingSlice";
import ReliabilityGuaranteedPopup from "../ReliabilityGuaranteedPopup";
import { trackAnalytics } from "src/pages/helpers";
import { setShowRequestLegalReviewAside } from "src/pages/onboarding/onboardingSlice";
import { useLocation } from "react-router-dom";
import LegalReviewAsideFooter from "./Footer";
import ReferralAside from "./ReferralAside";
import ReferralRequested from "./ReferralRequested";
const LegalReviewRequestAside = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const metadata = useSelector((state) => state.onboarding.userMetadata);

  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);
  // const showReviewRequestedModal = useSelector((state) => state.onboarding.showReviewRequestedModal);
  const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const requestLegalReviewFailureReason = useSelector((state) => state.onboarding.requestLegalReviewFailureReason);
  const showLearnMoreModal = useSelector((state) => state.onboarding.showLearnMoreModal);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const legalReview = lawyerHubData?.find((item) => item.thread_id === threadData?.id);
  const referralCategory = useSelector((state) => state.onboarding.referralCategory);
  const storeReferralStatus = useSelector((state) => state.onboarding.storeReferralStatus);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const storeReferalEmail = useSelector(state => state.onboarding.storeReferalEmail)

  const email = useSelector((state) => state.homeslice.email);
  const [ShowScheduleCall, setShowScheduleCall] = useState(false);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [notice, setNotice] = useState({});
  const [name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [stateOfResidence, setStateOfResidence] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [footer, setFooter] = useState();
  const [absoluteRelativeRequired, setAbsoluteRelativeRequired] = useState(false);

  useEffect(() => {
    if (phoneNumber.length === 10 && !isNaN(phoneNumber) && ["0", "-", "+"].indexOf(phoneNumber[0]) === -1) {
      setPhoneNumberValid(true);
    } else {
      setPhoneNumberValid(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    setLoading(false);
    setNotice({});
    setText("");
    setName(
      userMetadata?.individual_info
        ? `${userMetadata?.individual_info?.first_name || ""} ${userMetadata?.individual_info?.last_name || ""}`
        : ""
    );
    setEmail(email);
    setStateOfResidence(userMetadata ? userMetadata?.individual_info?.state_of_residence : "");
    dispatch(resetRequestLegalReviewFailureReason());
    dispatch(resetStoreReferralStatus());
  }, [showRequestLegalReviewAside]); // eslint-disable-line

  const submitRequest = () => {
    var err = {};
    if (!referralCategory) {
      if (window.location.pathname === "/home" && !phoneNumberValid) {
        err["phoneNumber"] = "Please enter a valid phone number";
      }
      if (window.location.pathname !== "/home" && !phoneNumberValid && !metadata?.lawyer_info) {
        err["phoneNumber"] = "Please enter a valid phone number";
      }
      if (window.location.pathname === "/home" && text.length === 0) {
        err["description"] = "Please enter a description";
      }
      if (Object.keys(err).length > 0) {
        setNotice(err);
        return;
      }
    } else {
      if (!phoneNumberValid) {
        err["phoneNumber"] = "Please enter a valid phone number";
      }
      if (!stateOfResidence) {
        err["stateOfResidence"] = "Please select a state";
      }
      if (Email && !Email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        err["Email"] = "Please enter a valid email";
      }
      if (name.length === 0) {
        err["name"] = "Please enter your name";
      }
      if (Object.keys(err).length > 0) {
        setNotice(err);
        return;
      }
    }

    setLoading(true);
    setNotice({});
    if (referralCategory) {
      dispatch(
        storeReferral({
          name: name,
          email: Email,
          phone: phoneNumber,
          thread_id: threadData?.id,
          description: text,
          state: stateOfResidence,
          category: referralCategory,
        })
      );
    } else {
      dispatch(
        requestLegalReview({
          threadId: threadData?.id,
          description: text,
          phone_number: phoneNumber,
          communication_preference: "phone",
        })
      );
      if (metadata.subscription_type === "subscriber_business") {
        if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
          trackAnalytics("request legal review clicked", {
            user_email: email,
            subscription_type: metadata.subscription_type,
            legal_review_credits_total: metadata.legal_review_credits_total,
            legal_review_credits_used: metadata.legal_review_credits_used,
          });
        } else if (metadata.legal_review_credits_total > metadata.legal_review_credits_used) {
          // set text that one legal credit will be used
          trackAnalytics("request legal review clicked", {
            user_email: email,
            subscription_type: metadata.subscription_type,
          });
        }
      } else if (metadata.lawyer_info === null) {
        // show find lawyer popup
        trackAnalytics("request legal review clicked", {
          user_email: email,
          subscription_type: metadata.subscription_type,
          lawyer_assigned: "not yet",
        });
      } else if (metadata.lawyer_info) {
        if (metadata.legal_review_credits_used >= metadata.legal_review_credits_total) {
          // show popup to upgrade plan. user is out of credits
          // also text should change
          trackAnalytics("request legal review clicked", {
            user_email: email,
            subscription_type: metadata.subscription_type,
            legal_review_credits_total: metadata.legal_review_credits_total,
            legal_review_credits_used: metadata.legal_review_credits_used,
          });
        } else if (metadata.legal_review_credits_total > metadata.legal_review_credits_used) {
          // set text that one legal credit will be used
          trackAnalytics("request legal review clicked", {
            user_email: email,
            subscription_type: metadata.subscription_type,
            lawyer_assigned: "yes",
          });
        }
      }
    }
  };

  useEffect(() => {
    setNotice({});
  }, []);

  useEffect(() => {
    if (storeReferralStatus === "success") {
      setLoading(false);
      setNotice({ success: "Your request has been submitted successfully. We will get back to you shortly." });
    } else if (storeReferralStatus === "failed") {
      setLoading(false);
      setNotice({ error: "Failed to save the request. Please try again" });
    } else if (storeReferralStatus === "loading") {
      setLoading(true);
      setNotice({ error: "" });
    }
  }, [storeReferralStatus]);

  useEffect(() => {
    if (requestLegalReviewStatus === "failed") {
      setLoading(false);
      if (!requestLegalReviewFailureReason) {
        setNotice({ error: "Failed to request review. Please try again later." });
      } else {
        setNotice({ error: requestLegalReviewFailureReason.detail });
      }
    }
    if (requestLegalReviewStatus === "success") {
      setLoading(false);
    }
  }, [requestLegalReviewStatus, requestLegalReviewFailureReason]);

  useEffect(() => {
    if ((!legalReview && requestLegalReviewStatus !== "success") || requestLegalReviewFailureReason) {
      setAbsoluteRelativeRequired(true);
    } else if (requestLegalReviewStatus === "success") {
      setAbsoluteRelativeRequired(false);
    } else if (window.location.pathname === "/home") {
      setAbsoluteRelativeRequired(true);
    }

    // setFooter(generateFooter())
  }, [requestLegalReviewStatus, metadata, lawyerHubData, threadData]);

  return (
    <>
      {showRequestLegalReviewAside && (
        <div className="fixed inset-0 z-[1100] bg-[rgba(0,0,0,0.5)] ">
          <aside className="h-[90%] md:h-screen top-[10%] md:top-0 w-full md:w-[572px] flex flex-col overflow-y-scroll  bg-white border-l border-l-[#E4E4E4] md:border-0 shadow-xl absolute z-[1200] right-0 md:rounded-t-[0px] rounded-t-[12px]">
            <div
              className={`flex flex-col justify-between flex-1 items-center h-screen ${
                absoluteRelativeRequired ? "relative" : ""
              }`}
            >
              <div
                className={`flex justify-end w-full top-0 right-0 z-10 px-6 py-3 ${
                  absoluteRelativeRequired ? "absolute" : ""
                }`}
              >
                <button
                  className="flex items-center my-auto text-sm cursor-pointer font-semibold text-[#353535]"
                  onClick={() => {
                    dispatch(setShowRequestLegalReviewAside(false));
                    setShowScheduleCall(false);
                  }}
                >
                  <span className="hidden md:flex justify-center items-center">
                    {location.pathname === "/home" ? (
                      <>
                        Close <X className="ml-2" size={16} />
                      </>
                    ) : (
                      <>
                        Hide <CaretDoubleRight className="ml-2" size={16} />{" "}
                      </>
                    )}
                  </span>
                  <span className="md:hidden">
                    <X className="ml-2" size={16} />
                  </span>
                </button>
              </div>
              {!notice.success ? (
                <>
                  <ReferralAside
                    referralCategory={referralCategory}
                    name={name}
                    setName={setName}
                    Email={Email}
                    setEmail={setEmail}
                    stateOfResidence={stateOfResidence}
                    setStateOfResidence={setStateOfResidence}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumberValid={phoneNumberValid}
                    setPhoneNumberValid={setPhoneNumberValid}
                    text={text}
                    notice={notice}
                    setText={setText}
                  />
                  {/* show userData?.legalReviewAsideOld depending upon the user's subscription plan */}
                  <div className="max-h-[120px] w-full flex justify-center items-center">
                    <LegalReviewAsideFooter
                      metadata={metadata}
                      legalReview={legalReview}
                      requestLegalReviewStatus={requestLegalReviewStatus}
                      loading={loading}
                      submitRequest={submitRequest}
                      dispatch={dispatch}
                      setShowRequestLegalReviewAside={setShowRequestLegalReviewAside}
                      phoneNumberValid={phoneNumberValid}
                      text={text}
                      notice={notice}
                      storeReferralStatus={storeReferralStatus}
                    />
                  </div>
                </>
              ) : (
                <ReferralRequested email={storeReferalEmail} />
              )}
            </div>
          </aside>
        </div>
      )}
      {showLearnMoreModal && <ReliabilityGuaranteedPopup />}
    </>
  );
};

export default LegalReviewRequestAside;
