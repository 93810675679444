import stars from "../../../images/3-stars.svg";
import upRightArrow from "../../../images/up-right-arrow.svg";
import { setShowPersonalizePopup } from "src/pages/onboarding/onboardingSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const OnboardingNudge = ({ dispatch, userMetadata }) => {
  const [isOnboardingComplete, setOnboardingComplete] = useState(true);

  useEffect(() => {
    if (userMetadata?.entity_info || userMetadata?.individual_info) {
      const entityInfoComplete =
        userMetadata?.entity_info?.name &&
        userMetadata?.entity_info?.primary_business_state &&
        userMetadata?.entity_info?.business_purpose;

      const individualInfoComplete =
        userMetadata?.individual_info?.first_name &&
        userMetadata?.individual_info?.last_name &&
        userMetadata?.individual_info?.state_of_residence;

      if (entityInfoComplete || individualInfoComplete) {
        setOnboardingComplete(true);
      } else {
        setOnboardingComplete(false);
      }
    }
  }, [userMetadata]);

  return (
    <>
      {!isOnboardingComplete && (
        <div
          className="w-[156px] h-[203px] rounded-[16px] p-[16px] flex flex-col gap-[8px] bg-tell-us-nudge"
          onClick={() => dispatch(setShowPersonalizePopup(true))}
        >
          <img src={stars} alt="Starts" className="w-[18px] h-[18px]" />
          <p className="font-sans font-medium text-[14px] text-[#141414]">Tell us about yourself</p>
          <p className="font-sans font-normal text-[12px] text-[#353535]">
            Help our AI learn your needs faster with just a few quick answers.
          </p>
          <button className="flex justify-start py-2 items-center gap-[8px] font-sans font-semibold text-[14px]">
            <span className="text-tell-us-nudge">Get Started</span>{" "}
            <img src={upRightArrow} alt="UpRightArrow" className="w-[18px] h-[18px]" />
          </button>
        </div>
      )}
    </>
  );
};

export default OnboardingNudge;
