import React, { forwardRef, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import BaseLayout from "../layouts/BaseLayout";
import useValidatePageAccess from "../../hooks/useValidatePageAccess";
import TopNav from "./Navs/TopNav";
import ReviewShimmer from "../../components/Shimmers/ReviewShimmer";
import DraftShimmer from "../../components/Shimmers/DraftShimmer";
import AskShimmer from "../../components/Shimmers/AskShimmer";
import { useDispatch, useSelector } from "react-redux";
import { renderMessage } from "../../components/DocumentViewer/helpers";
import SavedDataModalComponent from "../../components/Prompts/SavedDataModalComponent";
import DocImage from "src/images/DocImage.png";

import { getSharedMessages, resetThreadData, setThreadTitle } from "./messageSlice";
import { resetChatPage } from "./chatSlice";
import { resetData } from "./threadsSlice";
import AskToDraftModal from "../../components/chat/Modals/AskToDraftModal";
import DraftDocumentQuestionaireModal from "./Modals/DraftDocumentQuestionaireModal";
import LegalReviewRequestAside from "../LawyerHub/LegalReviewRequestAside/index.js";
import UpSellFlowPopup from "../LawyerHub/UpsellFlowPopup";
import { ArrowRight } from "@phosphor-icons/react";
import { trackAnalytics } from "../helpers";

const Shared = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { threadType, chatId } = useParams();

  const chatpageRef = useRef(null);
  const messageListRef = useRef(null);

  const getThreadStatus = useSelector((state) => state.messageSlice.getThreadStatus);
  const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);
  const threads = useSelector((state) => state.threads.threads);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const email = useSelector((state) => state.homeslice.email);
  const showAskToDraftModal = useSelector((state) => state.messageSlice.showAskToDraftModal);
  const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen);
  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);

  const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
  const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
  const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
  const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
  const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
  const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  // const scroll = useSelector((state) => state.messageSlice.scroll);

  useEffect(() => {
    dispatch(resetChatPage());
    dispatch(resetData());
    dispatch(resetThreadData());
    dispatch(getSharedMessages(chatId));
    // dispatch(getThread(chatId));
  }, [chatId]); // eslint-disable-line

  useEffect(() => {
    if (getThreadStatus === "success") {
      var results = [];
      Object.keys(threads).forEach((key) => {
        const filtered = threads[key].filter((item) => {
          return item.id === chatId;
        });
        if (filtered.length > 0) {
          results = results.concat(filtered);
        }
      });
      if (results.length > 0 && threadData?.isSharedThread !== true) {
        dispatch(setThreadTitle(results[0].title));
      }
    }
  }, [getThreadStatus, threads]); // eslint-disable-line

  const scrollContainerToBottom = (messageListRef) => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };
  const viewDocument = () => {
    trackAnalytics(`${threadData.thread_type} thread: view document on thread`);
    var url = threadData?.document.url
      ? threadData?.document?.url
      : `https://docs.google.com/document/d/${threadData?.document?.google_doc_id}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    // scrollContainerToBottom(messageListRef);
  }, [awaitingResponse, threadData.messages]); // eslint-disable-line

  return (
    <>
      <BaseLayout>
        <div className="flex flex-col w-[100%]">
          <div className="flex justify-between flex-col h-screen">
            <TopNav page="chat"></TopNav>
            {/* <p>{JSON.stringify(threadData.messages)}</p> */}
            {getThreadStatus === "success" && (
              <>
                <div
                  className="py-[24px] mx-auto overflow-y-auto gap-[12px] justify-end flex-1  w-full"
                  id="scrolling_div"
                  ref={messageListRef}
                >
                  {threadData.messages.map((message, index) => (
                    <div className="px-3 md:px-0 md:w-[736px] mx-auto">
                      {renderMessage(message, index, threadData, "full", email, threadType, true)}
                    </div>
                  ))}
                  {threadData?.document?.id && !threadData?.document?.isLocked && !awaitingResponse && (
                    <>
                      <div className="flex flex-col md:w-[736px] px-3 md:px-0 md:mx-auto">
                        <div className="flex flex-col w-full  mx-auto gap-0 mt-4 cursor-pointer" onClick={viewDocument}>
                          <div className="rounded-t-[14px] flex flex-row w-full border border-[#E4E4E4] p-0">
                            <div className="left px-[24px] py-[12px] h-[56px]">
                              <img src={DocImage} alt="doc" className="w-[32px] h-[32px] object-contain" />
                            </div>
                            <div className="bg-white  right font-[600] text-[16px] px-[24px] py-[14px] w-full rounded-b-0 rounded-r-[14px] my-auto h-[56px]">
                              {threadData?.document?.doc_title_shorthand}
                            </div>
                          </div>
                          <div className="relative w-full h-[500px] border border-[#E4E4E4]">
                            <iframe
                              src={`https://docs.google.com/document/d/${threadData?.document?.id}/preview`}
                              className="absolute top-0 left-0 w-full h-full"
                              title={threadData?.document?.doc_title_shorthand}
                            ></iframe>
                            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-white opacity-100">
                              <div className="absolute bottom-0 left-0 w-full flex justify-center p-4">
                                <button
                                  target="_blank"
                                  rel="noreferrer"
                                  className="bg-[#1B2B48] text-white font-bold items-center justify-center rounded  flex flex-row w-[262px] h-[40px] gap-[12px]"
                                >
                                  <span className="my-auto text-[14px]">View Document</span>{" "}
                                  <span className="my-auto">
                                    <ArrowRight size={16} className="text-white" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {awaitingResponse && (
                    <div className="mt-[12px]">
                      <>{threadType === "review" && <ReviewShimmer />}</>
                      <>{threadType === "ask" && <AskShimmer />}</>
                      <>{threadType === "draft" && <DraftShimmer />}</>
                    </div>
                  )}
                </div>
              </>
            )}
            {getThreadStatus === "loading" && (
              <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div>
                <p className="text-black">Fetching thread data...</p>
              </div>
            )}
            {getThreadStatus === "failed" && (
              <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                {/* <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div> */}
                <p className="text-black text-center">
                  Failed to fetch thread data. <br /> Please refresh the page and try again
                </p>
              </div>
            )}
          </div>
        </div>
        {showAskToDraftModal && <AskToDraftModal />}

        {draftDocumentModalOpen && <DraftDocumentQuestionaireModal email={email} ref={chatpageRef} />}
        {showRequestLegalReviewAside && <LegalReviewRequestAside />}
        {(showSubscriptionPopup ||
          showUpgradingPlan ||
          showBusinessPlanUnlocked ||
          showFindBestLawyer ||
          showMeetingConfirmation ||
          showNextSteps ||
          showScheduleMeeting) && <UpSellFlowPopup />}
      </BaseLayout>

      <SavedDataModalComponent />
    </>
  );
};

export default Shared;
