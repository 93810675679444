// import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { ArrowClockwise } from "@phosphor-icons/react";

const TryAgain = () => {
  // const serverError = useSelector((state) => state.onboarding.serverError);

  const handleTryAgain = () => {
    window.location.reload();
  };

  return (
    <div className="w-full h-screen bg-transparent flex flex-col justify-center items-center border-2 border-redgap-[24px] bg-red-600 z-4  gap-[24px]">
      <p className="font-sans text-[16px] font-medium">Something went wrong. Please Try again</p>
      <button
        onClick={handleTryAgain}
        className="font-sans px-[24px] text-[14px] py-[12px] flex items-center rounded-lg gap-[8px] text-white bg-[#1B2B48]"
      >
        Try Again <ArrowClockwise size={16} />
      </button>
    </div>
  );
};

export default TryAgain;
