import { Dropdown, Space } from "antd";

// import IHLogo from "../../../images/ih-logo-small-v2.png";
import IHLogo from "../../../images/Inhouse-logos/Inhouse logos_PNG/favicon-blue.png";
import IHLogoLarge from "../../../images/inhouse-logo-v2.png";

import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";

import { Provider, useDispatch, useSelector } from "react-redux";
import { getPortalSession, resetPortalSessionUrl } from "../../UserAuthentication/AuthenticationSlice.js";
import {
  getSavedParties,
  setViewChatHistory,
  getAllDraftTypes,
  getEducationMetadata,
  updateSidenavCollapse,
  resetCreateNewPartyStatus,
} from "../../../pages/Homepage/HomeSlice.js";

import { SignOutButton, useAuth, useUser } from "@clerk/clerk-react";
import ChatHistory from "./ChatHistory.js";
import { useEffect, useRef, useState } from "react";
import { getAllThreads } from "../threadsSlice.js";
import {
  ArrowLineLeft,
  ArrowLineRight,
  ChatsCircle,
  ClockCounterClockwise,
  FileMagnifyingGlass,
  House,
  IdentificationCard,
  PenNib,
  Plus,
  Question,
  Lightning,
  CaretDown,
  Sparkle,
} from "@phosphor-icons/react";
import { useClerk } from "@clerk/clerk-react";
import applicationStore from "../../../persistence/stores/RootStore.js";
import {
  createUserAPI,
  getLawyerHub,
  getUserMetadata,
  setShowRequestLegalReviewAside,
  setUserConfig,
} from "../../onboarding/onboardingSlice.js";
import ToastHandler from "./ToastHandler.js";

import PlanSelectionPopup from "src/pages/LawyerHub/PlanSelectionPopup";
import useValidatePageAccess from "src/hooks/useValidatePageAccess";
import PaymentModal from "../Modals/PaymentModal";
import LawyerCard from "src/pages/LawyerHub/LawyerCard";
import { setDraftDocumentModalOpen, setDraftModalDraftType } from "src/modalSlice";
import DraftDocumentQuestionaireModal from "../Modals/DraftDocumentQuestionaireModal";
import { resetRedirectTo, setAskToDraftIntentWSResponse } from "../messageSlice";
import UpSellFlowPopup from "src/pages/LawyerHub/UpsellFlowPopup";
import { clearLocalStorageKeys, clearTokens, getAnalytics, trackAnalytics } from "src/pages/helpers";
import OnboardingV2 from "src/pages/onboardingV2";
import { toast } from "react-toastify";
import { PLANS, PLANS_V2, userData } from "src/components/Constants";
import PersonalAccountThreadsExhausted from "src/pages/LawyerHub/UpsellFlowPopup/PersonalAccountThreadsExhausted";
import { setShowLawyerCard } from "src/pages/onboarding/onboardingSlice";
import SupportPopup from "./SupportPopup";
import { resetThreadData } from "../messageSlice";
import SmallScreenModalComponent from "src/components/chat/Modals/SmallScreenModal";
import { setMobileSideNav } from "../../../pages/Homepage/HomeSlice.js";
import TasksLeftText from "./TasksLeftText";
import ReliabilityGuaranteedPopup from "src/pages/LawyerHub/ReliabilityGuaranteedPopup";
import PaymentSuccessModal from "../Modals/PaymentSuccessModal/PaymentSuccessModal";

import CallScheduledPopup from "src/pages/LawyerHub/LegalReviewRequestAside/CallScheduledPopup";
import OnboardingNudge from "./TellUsAboutSomethingNudge";
import PersonalizePopup from "src/components/PersonalizePopup.js";

const SideNav = ({ subscriptionType }) => {
  useValidatePageAccess();

  const [smallScreen, setSmallScreen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startNewChatDropdownVisible, setStartNewChatDropdownVisible] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const sidenavCollapsed = useSelector((state) => state.homeslice.sidenavCollapsed);
  const viewChatHistory = useSelector((state) => state.homeslice.viewChatHistory);
  const email = useSelector((state) => state.onboarding.email);
  const redirectTo = useSelector((state) => state.messageSlice.redirectTo);
  const showLearnMoreModal = useSelector((state) => state.onboarding.showLearnMoreModal);

  const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const checkoutUrl = useSelector((state) => state.chat.checkoutUrl);
  const showUpgradePlanNudge = useSelector((state) => state.onboarding.showUpgradePlanNudge);
  const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
  const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
  const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
  const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
  const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
  const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
  const markLegalReviewCallScheduledStatus = useSelector(
    (state) => state.onboarding.markLegalReviewCallScheduledStatus
  );
  const portalSessionUrl = useSelector((state) => state.auth.portalSessionUrl);
  const educationMetadataFetchStatus = useSelector((state) => state.homeslice.educationMetadataFetchStatus);
  const draftTypesStatus = useSelector((state) => state.homeslice.draftTypesStatus);
  const showPersonalPlanThreadsExhausted = useSelector((state) => state.onboarding.showPersonalPlanThreadsExhausted);
  const savedPartiesFetchStatus = useSelector((state) => state.homeslice.savedPartiesFetchStatus);
  const showLawyerCard = useSelector((state) => state.onboarding.showLawyerCard);
  const smallScreenModalOpen = useSelector((state) => state.homeslice.smallScreenModalOpen);
  const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);
  const mobileSideNav = useSelector((state) => state.homeslice.mobileSideNav);
  const userConfig = useSelector((state) => state.onboarding.userConfig);

  const { user } = useUser();
  const { getToken } = useAuth();

  const draftDocumentModalRef = useRef(null);
  const { openUserProfile } = useClerk();

  const getPortalSessionUrl = () => {
    dispatch(getPortalSession());
  };

  const changeSidenavCollapse = () => {
    dispatch(updateSidenavCollapse(!sidenavCollapsed));
  };

  const visitLawyerHub = () => {
    trackAnalytics(
      "Lawyer Hub clicked",
      { current_url: window.location.path, user_email: user?.primaryEmailAddress?.emailAddress },
      userMetadata
    );
    // window.analytics.track("Lawyer Hub clicked", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
    navigate("/lawyer-hub");
  };

  const goToSomePlace = (suggestion) => {
    if (window.innerWidth < 768) {
      dispatch(setMobileSideNav(false));
    }
    dispatch(setAskToDraftIntentWSResponse({}));
    switch (suggestion.type) {
      case "ask":
        trackAnalytics(
          "Ask Icon clicked from sidebar",
          { current_url: window.location.path, user_email: user?.primaryEmailAddress?.emailAddress },
          userMetadata
        );
        dispatch(resetThreadData());
        navigate("/");
        break;
      case "draft":
        trackAnalytics(
          "draft Icon clicked from sidebar",
          { current_url: window.location.path, user_email: user?.primaryEmailAddress?.emailAddress },
          userMetadata
        );
        openDraftDocumentModal("side nav");
        break;
      default:
        break;
    }
  };

  const items = [
    {
      label: "My Account",
      key: "0",
    },
    {
      label: "Logout",
      key: "2",
    },
  ];

  const handleChatHistoryClicked = () => {
    trackAnalytics(
      "dashboard_home_history_tab_clicked",
      { user_email: user?.primaryEmailAddress?.emailAddress },
      userMetadata
    );
    dispatch(setViewChatHistory(true));
    if (window.innerWidth <= 768) {
      dispatch(setMobileSideNav(false));
    }
  };
  const openDraftDocumentModal = (source, draft) => {
    trackAnalytics(
      `Draft document from ${source}`,
      { user_email: user?.primaryEmailAddress?.emailAddress },
      userMetadata
    );
    // window.analytics.track(`Draft document from ${source}`, { "user_email": user?.primaryEmailAddress?.emailAddress });
    dispatch(setDraftModalDraftType(draft));
    // navigate(`/?open_draft=${message}`)
    // dispatch(setDraftModalDraftType(message))
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(getUserMetadata());
    }, 1000);
    if (educationMetadataFetchStatus === "idle" || educationMetadataFetchStatus === "failed") {
      dispatch(getEducationMetadata());
    }
    dispatch(getLawyerHub());
    dispatch(getAllThreads());
    if (window.innerWidth < 768) {
      dispatch(updateSidenavCollapse(true));
      setSmallScreen(true);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (markLegalReviewCallScheduledStatus === "success") {
      dispatch(getLawyerHub());
    }
  }, [markLegalReviewCallScheduledStatus]); // eslint-disable-line

  useEffect(() => {
    if (userMetadataFetchStatus === "profile-not-completed") {
      navigate("/onboarding?utm_source=signup");
    } else if (userMetadataFetchStatus === "profile-not-created") {
      dispatch(createUserAPI());
      navigate("/onboarding?utm_source=signup");
    } else if (userMetadataFetchStatus === "success") {
      if (draftTypesStatus === "idle" || draftTypesStatus === "failed") {
        dispatch(getAllDraftTypes());
      }
      if (savedPartiesFetchStatus === "idle" || savedPartiesFetchStatus === "failed") {
        dispatch(getSavedParties());
      }
    }
  }, [userMetadataFetchStatus]); // eslint-disable-line

  useEffect(() => {
    if (redirectTo) {
      var redirectUrl = redirectTo;
      dispatch(resetRedirectTo(null));
      dispatch(setDraftDocumentModalOpen(false));
      navigate(redirectUrl);
    }
  }, [redirectTo]); // eslint-disable-line

  useEffect(() => {
    if (portalSessionUrl) {
      const newTab = window.open(portalSessionUrl, "_blank");

      function checkIfTabClosed() {
        if (newTab?.closed) {
          setTimeout(() => {
            dispatch(getUserMetadata());
          }, 2000);
          clearInterval(checkInterval);
        }
      }

      dispatch(resetPortalSessionUrl());
      const checkInterval = setInterval(checkIfTabClosed, 1000);
    }
  }, [portalSessionUrl]); // eslint-disable-line

  useEffect(() => {
    if (userMetadata.show_new_lawyer_assigned_notification) {
      dispatch(setShowLawyerCard(true));
    }
  }, [userMetadata]); // eslint-disable-line

  useEffect(() => {
    dispatch(setShowRequestLegalReviewAside(false));
  }, [window.location.href]); // eslint-disable-line
  useEffect(() => {
    if (requestLegalReviewStatus === "success") {
      dispatch(getLawyerHub());
    }
  }, [requestLegalReviewStatus]); // eslint-disable-line

  useEffect(() => {
    if (window.innerWidth < 768) {
      dispatch(updateSidenavCollapse(false));
    }
  }, [window.innerWidth]);

  const renderTasksLeftInMoblieNav = (userMetadata) => {
    switch (userMetadata?.subscription_type) {
      case "":
      case null:
      case undefined:
        return <></>;
      case PLANS.PERSONAL_FREE:
      case PLANS.FREE_TIER:
      case PLANS.SUBSCRIBER_BUSINESS:
      case PLANS.BUSINESS_TRIAL:
      case PLANS.BUSINESS_TRIAL_ENDED:
        return (
          <div className="md:hidden block">
            <TasksLeftText userMetadata={userMetadata} />
          </div>
        );
      case PLANS.SUBSCRIBER_ENTERPRISE:
        return (
          <div className="md:flex flex-row gap-[8px] my-auto hidden">
            <span className="my-auto text-[12px] leading-[18px]">You have unlimited tasks</span>
            <span className="my-auto">
              <Lightning size={16} className="my-auto text-[#18475A]" weight="fill" />
            </span>
          </div>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (userMetadata?.user_id) {
      var baseData = userData;
      // console.log(baseData);
      // console.log(userData);
      var subscriptionType = userMetadata?.subscription_type;
      if (subscriptionType === "personal_free" || subscriptionType === "free_trial") {
        subscriptionType = "free_tier";
      }
      var legalReview = userMetadata?.lawyer_info
        ? baseData.legalReview.dedicatedLawyer
        : baseData.legalReview.nonDedicatedLawyer;
      var data = {
        homepage: {
          topnav: baseData.homepage.topnav[subscriptionType],
          talktolawyerbanner: baseData.homepage.talktolawyerbanner[subscriptionType],
        },
        sidenav: {
          iconOnUserImage: baseData.sidenav.iconOnUserImage[subscriptionType],
          displayPlanName: baseData.sidenav.displayPlanName[subscriptionType],
          planName: baseData.sidenav.planName[subscriptionType],
        },
        legalReview: {
          banner: legalReview?.banner,
          text: legalReview?.text,
          buttonCta: legalReview?.buttonCta,
          buttonClasses: legalReview?.buttonClasses,
          formLabel: legalReview?.formLabel,
          formPlaceholder: legalReview?.formPlaceholder,
          phoneNumberLabel: legalReview?.phoneNumberLabel,
          phonenumberplaceholder: legalReview?.phonenumberplaceholder,
          footer: legalReview?.footer,
          requestSuccessMessage: legalReview?.requestSuccessMessage,
          cta: legalReview.cta,
        },
      };
      // now this data requires customization depending if there are any names to be inserted
      // most probably this will happen only in case of inhouse counsel
      dispatch(setUserConfig(data));
    }
  }, [userMetadata]); // eslint-disable-line

  return (
    <>
      <div className="bg-white z-[51]">
        <div
          className={`side-nav ${
            sidenavCollapsed === true ? "small " : "large !w-[297px] md:!w-[180px]"
          } h-screen overflow-y-scroll top-0
                flex py-[24px] px-[0px] flex-col justify-between left-0 ${
                  mobileSideNav ? "block" : "hidden"
                } md:flex absolute md:relative`}
        >
          <div className="relative flex flex-col gap-6 side-nav-threads align-end">
            <div className="sidebar-first-section flex flex-col gap-y-[40px] ">
              <ul className="flex flex-col p-[8px] gap-[16px]">
                <li className="h-[32px] flex flex-col">
                  {sidenavCollapsed ? (
                    <img src={IHLogo} className="w-[100px] h-[42px] object-contain cursor-default" alt="Inhouse" />
                  ) : (
                    <div className="flex w-full pr-[16px] justify-between items-center">
                      <img
                        src={IHLogoLarge}
                        className="w-[100px] h-[42px] object-contain cursor-default"
                        alt="Inhouse"
                      />
                      <div className="md:hidden shadow-sm border w-[32px] h-[32px] rounded-[4px] p-[6px]">
                        <ArrowLineLeft
                          size={20}
                          className="md:hidden"
                          onClick={() => dispatch(setMobileSideNav(false))}
                        />{" "}
                      </div>
                    </div>
                  )}
                </li>
              </ul>
              <ul className="flex flex-col p-[8px] gap-[24px]">
                <div className="mx-[16px] md:hidden">{renderTasksLeftInMoblieNav(userMetadata)}</div>
                <Dropdown
                  trigger={["click"]}
                  open={startNewChatDropdownVisible}
                  onOpenChange={(open) => setStartNewChatDropdownVisible(open)}
                  dropdownRender={() => (
                    <div className="absolute left-0 py-2 bg-transparent group-hover:block top-full min-w-fit ">
                      <div className="bg-white border shadow-md rounded-xl  w-[235px]">
                        <div className="hidden md:block text-[#141414] text-base font-medium whitespace-nowrap px-4 py-3 w-[235px]">
                          Start New Chat
                        </div>
                        <ul className="flex flex-col py-2 select-none">
                          <li
                            className="flex flex-row w-full cursor-pointer text-[#686868] py-2 items-center text-sm font-normal px-4 justify-start"
                            onClick={() => {
                              goToSomePlace({ type: "ask" });
                              setStartNewChatDropdownVisible(false);
                            }}
                          >
                            <span>
                              <ChatsCircle className="mr-2" />
                            </span>{" "}
                            Ask Inhouse AI{" "}
                          </li>
                          <li
                            className="flex flex-row w-full cursor-pointer text-[#686868] py-2 items-center text-sm font-normal px-4 justify-start"
                            onClick={() => {
                              goToSomePlace({ type: "draft" });
                              setStartNewChatDropdownVisible(false);
                            }}
                          >
                            <PenNib className="mr-2" /> Draft Legal Document{" "}
                          </li>
                          <li
                            className="hidden flex-row w-full cursor-pointer text-[#686868] py-2 items-center text-sm font-normal px-4 justify-start"
                            onClick={() => {
                              // goToSomePlace();
                              setStartNewChatDropdownVisible(false);
                            }}
                          >
                            <FileMagnifyingGlass className="mr-2" /> Scan for Red Flags{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                >
                  <li
                    className={`relative group flex items-center rounded-[8px] md:rounded-full ${
                      sidenavCollapsed ? "" : "-left-2 bg-[#748A8C14] px-[12px] py-[8px] md:px-1 md:py-1"
                    }`}
                    onClick={() => {
                      trackAnalytics(
                        "Start new chat clicked",
                        { current_url: window.location.path, user_email: user?.primaryEmailAddress?.emailAddress },
                        userMetadata
                      );
                      // window.analytics.track("Start new chat clicked", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
                      // navigate("/")
                    }}
                  >
                    <div
                      className={`w-[20px] h-[20px] md:w-6 md:h-6 flex justify-center items-center rounded-full ${
                        sidenavCollapsed
                          ? `${startNewChatDropdownVisible ? "bg-[#353535]" : "bg-[#748A8C14]"}`
                          : `${startNewChatDropdownVisible ? "bg-[#353535]" : "bg-white"}`
                      }`}
                    >
                      <Plus
                        size={16}
                        weight={`bold`}
                        className={` ${startNewChatDropdownVisible ? "text-white" : "text-[#353535]"}`}
                      />
                    </div>
                    {!sidenavCollapsed && (
                      <div
                        className={`flex-1 inner ${window.location.pathname === "/home" ? "active" : ""} text-center`}
                      >
                        <p className="text-[12px]  font-medium">Start New Chat</p>
                      </div>
                    )}
                    {!sidenavCollapsed && <CaretDown size={14} className="md:hidden" />}
                  </li>
                </Dropdown>

                <li
                  className=""
                  onClick={() => {
                    trackAnalytics(
                      "Home clicked",
                      { current_url: window.location.path, user_email: user?.primaryEmailAddress?.emailAddress },
                      userMetadata
                    );
                    // window.analytics.track("Home clicked", { "current_url": window.location.path, "user_email": user?.primaryEmailAddress?.emailAddress });
                    navigate("/");
                    window.innerWidth <= 768 && dispatch(setMobileSideNav(false));
                  }}
                >
                  <House
                    size={16}
                    weight={`${window.location.pathname === "/home" ? "fill" : "fill"}`}
                    className={`${window.location.pathname === "/home" ? "text-[#18475A]" : "text-[#18475A]"}`}
                  />
                  <div className={` ${window.location.pathname === "/home" ? "active" : ""} text-center`}>
                    <p className="text-[12px] ">Home</p>
                  </div>
                </li>

                <li className="" onClick={handleChatHistoryClicked}>
                  <ClockCounterClockwise
                    size={16}
                    weight={`${viewChatHistory ? "fill" : "regular"}`}
                    className={`${viewChatHistory ? "text-[#18475A]" : "text-[#686868]"}`}
                  />
                  <div className={`${viewChatHistory ? "active" : ""} text-center`}>
                    <p className="text-[12px] flex flex-row gap-[4px]">
                      <span className="inner">Task</span> History
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            {isPopupVisible && <SupportPopup isVisible={isPopupVisible} onClose={() => setPopupVisible(false)} />}
          </div>
          <div className="flex flex-col gap-[8px] side-nav">
            <ul className="flex flex-col p-[8px] gap-[20px]">
              <li
                className="flex py-[8px] gap-[8px] text-[#686868] cursor-pointer"
                onClick={() => setPopupVisible(true)}
              >
                <Question size={16} />
                <div className="">
                  <p className="text-[12px] font-medium font-sans leading-[18px]">
                    {sidenavCollapsed ? "Help" : "Contact Help"}
                  </p>
                </div>
              </li>
              {/* <li className="" onClick={handleChatHistoryClicked}>
                                <Question size={16} weight="thin" className="text-[#949494]" />
                                <div className={`inner ${window.Location.pathname === "/lawyer-hub" ? "active" : ""} text-center`}>
                                    <p className="text-[12px] ">Lawyer Hub </p>
                                </div>
                            </li> */}
              {!sidenavCollapsed && (
                <li className={`mx-auto hidden md:flex`}>
                  <OnboardingNudge dispatch={dispatch} userMetadata={userMetadata} />
                </li>
              )}
              <li className="">
                <Dropdown
                  overlayStyle={{ zIndex: 4000 }}
                  dropdownRender={(menu) => (
                    <div className="nav-footer-dropdown flex flex-col items-start w-full gap-4 p-4 bg-white rounded-[5px] border">
                      <ul>
                        {userMetadata?.subscription_type === PLANS.SUBSCRIBER_BUSINESS ||
                        userMetadata?.subscription_type === PLANS.SUBSCRIBER_ENTERPRISE ? (
                          <li className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px] cursor-pointer">
                            <span
                              type="text"
                              className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"
                              id="navigation-account"
                              // href={portalSessionUrl} data-cb-type="portal"
                              onClick={getPortalSessionUrl}
                              target="blank"
                            >
                              Manage Subscription
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        {items.map((item) => (
                          <>
                            {item.label !== "Logout" ? (
                              <li
                                onClick={() => {
                                  if (item.label === "My Account") {
                                    dispatch(resetCreateNewPartyStatus());
                                    // openUserProfile(myUserProfile);
                                    openUserProfile({
                                      customPages: [
                                        {
                                          label: "Personalization",
                                          url: "/custom-page-1",
                                          mountIcon: (el) => {
                                            ReactDOM.render(<Sparkle size={16} weight="fill" />, el);
                                          },
                                          unmountIcon: (el) => {
                                            ReactDOM.render(<Sparkle size={16} />, el);
                                          },
                                          mount: (el) => {
                                            // Custom logic to mount the page
                                            ReactDOM.render(
                                              <Provider store={applicationStore}>
                                                {/* <SavedDataPrompt /> */}
                                                <OnboardingV2
                                                  user={user}
                                                  getToken={getToken}
                                                  sidenav={true}
                                                  moveNext={() => {
                                                    toast.success("Profile Updated");
                                                    window.location.reload();
                                                  }}
                                                />
                                              </Provider>,
                                              el
                                            );
                                            // el.innerHTML = '<div>Custom Page Content</div>';
                                          },
                                          unmount: (el) => {
                                            // Custom logic to unmount the page
                                            if (el) el.innerHTML = "";
                                          },
                                        },
                                      ],
                                    });
                                  } else {
                                    navigate("/my-referral");
                                  }
                                }}
                                className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px]  cursor-pointer"
                              >
                                <p
                                  type="text"
                                  className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100"
                                  id="navigation-account"
                                >
                                  {item.label}
                                </p>
                              </li>
                            ) : (
                              <li
                                className="hover:text-[#5e59ff] text-[16px] font-[500] p-0 h-fit w-100 leading-[35px]  cursor-pointer"
                                onClick={() => {
                                  const analytics = getAnalytics();
                                  analytics.reset();
                                  clearLocalStorageKeys();
                                }}
                              >
                                <SignOutButton redirectUrl={process.env.REACT_APP_POST_SIGNOUT_URL} />
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                  )}
                  trigger={["click"]}
                  className="hover:cursor-pointer hover:text-[#5e59ff]"
                >
                  <Space className="flex w-full">
                    <div
                      className={`relative flex h-[32px] w-[32px] flex-shrink-0  ${
                        sidenavCollapsed ? "rounded-full" : "rounded-[4px]"
                      }`}
                    >
                      <img
                        src={user?.imageUrl}
                        alt="profile"
                        className={`flex-shrink-0 ${sidenavCollapsed ? "rounded-full" : "rounded-[4px]"}`}
                        style={{ objectFit: "cover", width: "100%", height: "100%" }}
                      />
                      {userConfig?.sidenav?.iconOnUserImage
                        ? userConfig?.sidenav?.iconOnUserImage({ sidenavCollapsed })
                        : ""}
                    </div>
                    <div className="inner flex flex-col gap-[4px]">
                      <p className="text-[12px] font-medium pl-2">{user?.firstName}</p>
                      {userConfig?.sidenav?.displayPlanName}
                    </div>
                  </Space>
                </Dropdown>
              </li>
              <li
                className={`flex ${sidenavCollapsed ? "mx-auto" : "!justify-end px-[24px]"}`}
                onClick={changeSidenavCollapse}
              >
                <button className="border hidden md:flex h-[32px] w-[32px] rounded">
                  {sidenavCollapsed ? (
                    <ArrowLineRight size={16} className="text-[#141414]  mx-auto my-auto" />
                  ) : (
                    <ArrowLineLeft size={16} className="text-[#141414]  mx-auto my-auto" />
                  )}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ToastHandler />
      <ChatHistory />
      <DraftDocumentQuestionaireModal email={email} ref={draftDocumentModalRef} />

      {showLawyerCard && <LawyerCard />}
      {checkoutUrl && !showUpgradingPlan && <PaymentModal />}

      {/* Popups  */}
      {(showSubscriptionPopup ||
        showUpgradingPlan ||
        showBusinessPlanUnlocked ||
        showFindBestLawyer ||
        showMeetingConfirmation ||
        showNextSteps ||
        showScheduleMeeting) && <UpSellFlowPopup />}

      {showPersonalPlanThreadsExhausted && <PersonalAccountThreadsExhausted />}

      <PlanSelectionPopup isOpen={showUpgradePlanNudge} />
      <PaymentSuccessModal />
      <CallScheduledPopup />

      {smallScreenModalOpen && <SmallScreenModalComponent />}
      {showLearnMoreModal && <ReliabilityGuaranteedPopup />}
      <PersonalizePopup />

      {/* <WSInputBox /> */}
      {/* <WebsocketRefreshModal /> */}
    </>
  );
};

export default SideNav;
