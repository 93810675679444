import React from "react";
import { useDispatch, useSelector } from "react-redux";
import legalReviewImg from "src/images/review-request-img.svg";
import { trackAnalytics } from "src/pages/helpers";
import { setShowLearnMoreModal } from "src/pages/onboarding/onboardingSlice";

const NonDedicatedLawyerBanner = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.homeslice.email);

  return (
    <>
      <img src={legalReviewImg} alt="legalReviewImg" className="my-[24px]" />
      <div className="w-full px-8 gap-[24px] pr-[50px]">
        <h6 className="text-[#011513] text-[16px] md:text-[18px] font-semibold">
          Consult an expert lawyer — <br />
          Fast and Affordable
        </h6>
        <p className="text-[#353535] text-[14px] leading-[19.6px] font-normal mt-2">
          The chat will be sent to the specialized lawyer for context.{" "}
          <span
            className="text-[#18475A] cursor-pointer underline"
            onClick={() => {
              trackAnalytics("learn more clicked from Request a Lawyer Review aside", { user_email: email });
              dispatch(setShowLearnMoreModal(true));
            }}
          >
            Know More
          </span>
        </p>
      </div>
    </>
  );
};

export default NonDedicatedLawyerBanner;
