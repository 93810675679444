/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect, useState } from "react";
// import IndividualForm from "./IndividualForm";
// import Button from "../LawyerHub/Button";
// import { SignOutButton } from "@clerk/clerk-react";
import { useSelector, useDispatch } from "react-redux";
import { createUserAPI, getUserMetadata, setShowPersonalizePopup } from "../onboarding/onboardingSlice";
import { createNewParty, onboardUser } from "../../pages/Homepage/HomeSlice";
// import InhouseLogo from "../../images/inhouse-logo-v2.png";
import { getAnalytics, trackAnalytics } from "../helpers";
// import OnboardingStep1 from "../onboarding/OnboardingStep1";
// import OnboardingStep2 from "../onboarding/OnboardingStep2";
// import homePrint from "../../images/homePrint.svg";
// import tailor from "../../images/tailor.png";
// import background from "../../images/bg.png";
import TextInput from "./Inputs/TextInput";
import SelectInput from "./Inputs/Select";
import { stateOptionsNew } from "src/components/Constants";
import { Spinner } from "@phosphor-icons/react/dist/ssr";

const OnboardingV2 = ({ user, getToken, sidenav, moveNext, isInPopup }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const utm_source = queryParams.get("utm_source");
  const next = queryParams.get("next");

  // const { user: clerkUser } = useUser();

  const [firstName, setFirstName] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  // const [industry, setIndustry] = useState("");
  const [businessPurpose, setBusinessPurpose] = useState("");
  const [entityStructure, setEntityStructure] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [incorporationState, setIncorporationState] = useState("");
  const [stateOfResidence, setStateOfResidence] = useState("");
  // const [profileUpdated, setProfileUpdated] = useState(false);
  // const [profileUpdationError, setProfileUpdationError] = useState(false);
  const [createUserAttemptCount, setCreateUserAttemptCount] = useState(0);
  const [notice, setNotice] = useState("");
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(sidenav ? 0 : 0);

  const [showLoading, setShowLoading] = useState(false);
  const [address, setAddress] = useState("");

  const dispatch = useDispatch();
  const onboardUserStatus = useSelector((state) => state.homeslice.onboardUserStatus);
  const onboardFailureReason = useSelector((state) => state.homeslice.onboardFailureReason);
  // const error = useSelector((state) => state.onboarding.error);
  const createUserAPIStatus = useSelector((state) => state.onboarding.createUserAPIStatus);
  const userAlreadyExist = useSelector((state) => state.onboarding.userAlreadyExist);
  const partiesList = useSelector((state) => state.homeslice.partiesList);
  const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const createNewPartyStatus = useSelector((state) => state.homeslice.createNewPartyStatus);

  const getMyToken = async () => {
    const token = await getToken({ template: "Backend" });
    return token;
  };
  const getTokenValue = async () => {
    const token = await getMyToken();
    if (token && typeof token === "string") {
      localStorage.setItem("accessToken2", token);
    }
  };

  const submit = async () => {
    setShowLoading(true);
    setNotice("");

    var entityInfo = null;
    var individualInfo = null;
    individualInfo = {
      state_residence: stateOfResidence,
      address: address,
      name: fullName,
    };

    var isEntity = organizationName?.length > 0;
    if (isEntity) {
      var entityType;
      entityType = "Corporation";
      entityInfo = {
        name: organizationName,
        type: entityType,
        // state_incorporation: incorporationState,
        primary_business_state: businessState,
        address: address,
        business_purpose: businessPurpose,
      };
    }
    var primaryId = null;
    if (partiesList.length > 0) {
      var primaryParty = partiesList.filter((party) => party.is_primary === true);
      if (primaryParty.length > 0) {
        primaryId = primaryParty[0].id;
      }
    }

    const data = {
      id: primaryId,
      is_primary: true,
      full_name: fullName,
      entity_info: entityInfo,
      individual_info: individualInfo,
    };
    dispatch(createNewParty(data));
  };

  useEffect(() => {
    setNotice("");
    getTokenValue();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const analytics = getAnalytics();
    analytics.page("Onboarding page viewed", { user_email: user?.primaryEmailAddress?.emailAddress });
    if (utm_source === "signup") {
      setTimeout(() => {
        dispatch(createUserAPI());
      }, 3000);
    } else {
      dispatch(getUserMetadata());
      // dispatch(getSavedParties());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createUserAPIStatus === "success") {
      dispatch(getUserMetadata());
    } else if (createUserAPIStatus === "failed" && userAlreadyExist) {
      dispatch(getUserMetadata());
      // dispatch(getSavedParties());
    }
  }, [createUserAPIStatus, userAlreadyExist]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userMetadataFetchStatus === "failed") {
      if (createUserAttemptCount < 2) {
        setCreateUserAttemptCount(createUserAttemptCount + 1);
        dispatch(createUserAPI());
      }
      // dispatch(getSavedParties());
    }
  }, [userMetadataFetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userMetadata) {
      // if (!sidenav && userMetadata?.first_name) {
      //   window.location.href = "/home";
      // }
      var name = `${userMetadata?.first_name ? `${userMetadata?.first_name} ${userMetadata?.last_name}` : ""}`;
      setFullName(name);
      var companyName = userMetadata?.entity_info?.name;
      setOrganizationName(companyName);
      setStateOfResidence(userMetadata?.individual_info?.state_of_residence);
      setIncorporationState(userMetadata?.entityInfo?.state_incorporation);
      setBusinessState(userMetadata?.entity_info?.primary_business_state);
      setBusinessPurpose(userMetadata?.entity_info?.business_purpose || userMetadata?.individual_info?.description);
    }
  }, [userMetadata]);

  useEffect(() => {
    if (onboardUserStatus === "success" || createNewPartyStatus === "success") {
      setShowLoading(false);
      dispatch(getUserMetadata());
      // dispatch(getSavedParties());
      trackAnalytics(
        "profile_updated",
        { user_email: user?.primaryEmailAddress?.emailAddress, source: sidenav ? "sidenav" : "onboarding" },
        userMetadata
      );
      moveNext(next);
    } else if (onboardUserStatus === "failed" || createNewPartyStatus === "failed") {
      setShowLoading(false);
      if (onboardFailureReason) {
        setNotice(onboardFailureReason);
      } else {
        setNotice("Error saving data. Please try again.");
      }
    }
  }, [onboardUserStatus, createNewPartyStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`flex ${sidenav ? "flex-col" : "flex-col md:flex-row"} md:overflow-hidden`}>
      <div className="w-full h-full">
        <div className={`${isInPopup ? "hidden" : "block"} flex flex-col`}>
          <p className="fonnt-sans font-medium text-[16px] text-[#141414]">Personalization</p>
          <p className="font-normal font-sans text-[12px] text-[#686868]">
            Introduce yourself to get better and more personalized responses
          </p>
        </div>
        <hr className={` ${isInPopup ? "md:!mt-[15px]" : "!mt-4 "}`} />

        <div className="flex flex-col gap-[16px] w-full pt-3 overflow-y-scroll my-2">
          {/* <TextInput
            label={<span className="text-[14px] font-medium font-sans text-[#000000]">Your Name</span>}
            value={fullName}
            placeholder="Wile Coyote "
            onChange={(e) => setFullName(e.target.value)}
          /> */}
          <SelectInput
            label={<span className="text-[14px] font-medium font-sans text-[#000000]">State of residence</span>}
            options={stateOptionsNew}
            helpText="This helps identify the state laws applicable to your personal legal needs"
            value={stateOfResidence}
            placeholder="Select state of Residence"
            onChange={(e) => setStateOfResidence(e.target.value)}
          />
          <TextInput
            label={<span className="text-[14px] font-medium font-sans text-[#000000]">Company name (if relevant)</span>}
            value={organizationName}
            helpText="Where applicable, we will autofill this in relevant sections of your legal documents."
            placeholder="Acme Inc."
            onChange={(e) => setOrganizationName(e.target.value)}
          />
          <TextInput
            label={
              <span className="text-[14px] font-medium font-sans text-[#000000]">Tell us more about your business</span>
            }
            helpText="This helps the AI apply the correct regulations and industry standards"
            value={businessPurpose}
            placeholder="We are a leading manufacturer of consumer electronics Acme Inc."
            rows={3}
            onChange={(e) => setBusinessPurpose(e.target.value)}
          />
          {organizationName?.length > 0 && (
            <>
              {/* <SelectInput
                label={<span className="text-[14px] font-medium font-sans text-[#000000]">State of Incorporation</span>}
                options={stateOptionsNew}
                value={incorporationState}
                placeholder="Select state of Incorporation"
                onChange={(e) => setIncorporationState(e.target.value)}
              /> */}

              <SelectInput
                label={
                  <span className="text-[14px] font-medium font-sans text-[#000000]">Principal state of business</span>
                }
                helpText="This helps identify the state laws applicable to your business legal needs"
                options={stateOptionsNew}
                value={businessState}
                onChange={(e) => setBusinessState(e.target.value)}
                placeholder="Select Principal state of business"
              />
            </>
          )}
          <hr className={` ${isInPopup ? "md:!mt-[15px]" : "!mt-4 "}`} />
        </div>
      </div>
      <div className="w-full footer h-[62px]  flex justify-end items-center gap-[8px]">
        {/* <button className="px-[24px] py-[12px] rounded-[8px] font-medium font-sans text-[14px] text-[#011513]">
          Cancel
        </button> */}
        <button
          className="px-[24px] py-[12px] rounded-[8px] font-medium font-sans text-[14px] text-white bg-[#1B2B48]"
          disabled={createNewPartyStatus === "loading"}
          onClick={submit}
        >
          {" "}
          {showLoading ? <Spinner size={16} /> : "Save"}
        </button>
        {isInPopup && (
          <button
            className="pl-[24px] pr-[12px] py-[12px] rounded-[8px] font-medium font-sans text-[14px] text-[#011513]"
            onClick={() => dispatch(setShowPersonalizePopup(false))}
          >
            Dismiss
          </button>
        )}
      </div>
    </div>
  );
};

export default OnboardingV2;
