import React, { createContext, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  addRequestIds,
  markQuestionAnswered,
  setAskToDraftIntentWSResponse,
  setAwaitingDraftQuestionsResponse,
  setAwaitingResponse,
  setChangingDraftType,
  setChatInputMessage,
  setDocument,
  setDocumentChangeAction,
  setMessagingDisabled,
  setQuestions,
  setRegenerationHappening,
  setRetryWS,
  setRetryWSState,
  setScroll,
  setShowAskToDraftModal,
  setSubmittingQnA,
  setUpdatingQuestion,
  setupNewThread,
  skipFollowupQuestions,
  updateSelectedParty,
} from "./pages/chatpage/messageSlice";
import { useAuth } from "@clerk/clerk-react";
import { insertNewThread, updateThreadTitleLocal } from "./pages/chatpage/threadsSlice";
import applicationStore from "./persistence/stores/RootStore.js";

import { toast } from "react-toastify";
import { decreaseThreadsRemaining } from "./pages/onboarding/onboardingSlice";
import { PLANS } from "./components/Constants";
import { ErrorLogging } from "./components/DocumentViewer/helpers";
import { extractUUID, getSegmentId, isUUID, setCookie, trackAnalytics } from "./pages/helpers";
import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_WS_BASE_ENDPOINT;
  var usermetadata = useSelector((state) => state.onboarding.userMetadata);
  const [isConnected, setIsConnected] = useState(false);
  const [accesstoken, setAccesstoken] = useState(null);
  const { getToken } = useAuth();
  const socketRef = useRef(null);
  const retryIntervalRef = useRef(null);
  const [retryCount, setRetryCount] = useState(0);

  const randomString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const TAB_ID = "key-" + randomString(15);

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (socketRef.current) socketRef.current.close();
      if (retryIntervalRef.current) clearTimeout(retryIntervalRef.current);
    };
  }, [url, accesstoken, retryCount]);

  useEffect(() => {
    connectWebSocket();
  }, [usermetadata]);

  useEffect(() => {
    getTokenValue();
  }, [getToken]);

  useEffect(() => {
    const handleTabClose = () => {
      if (localStorage.getItem(TAB_ID)) {
        localStorage.removeItem(TAB_ID);
      }
      if (socketRef.current) {
        socketRef.current.close();
      }
    };

    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const getMyToken = async () => {
    const token = await getToken({ template: "Backend" });
    return token;
  };
  const getTokenValue = async () => {
    const token = await getMyToken();
    if (token && typeof token === "string") {
      setAccesstoken(token);
    }
  };

  const connectWebSocket = () => {
    var socket;
    const analytics = Analytics({
      // app: "awesome-app",
      plugins: [
        segmentPlugin({
          writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
        }),
      ],
    });

    const existingTab = localStorage.getItem(TAB_ID);
    if (existingTab) {
      // console.log("Another tab is already handling the WebSocket connection.");
      return;
    }
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      // console.log("returning. not creting new connection. state = ", socketRef.current.readyState);
      // return;
    }

    if (window.location.pathname !== "/try") {
      if (!accesstoken) {
        return;
      }
      if (!usermetadata?.subscription_type) {
        return;
      }
      socket = new WebSocket(`${url}/ws?token=${accesstoken}`);
    } else {
      // try {
      var id = getSegmentId();
      // console.log("received segment id", id);
      if (!id) {
        console.log("segment id not found");
        ErrorLogging({ error_type: `Segment ID not found`, error_message: `Segment ID not found` });
        return;
      }
      socket = new WebSocket(`${url}/ws?anonymous_user_id=${id}`);
    }

    localStorage.setItem(TAB_ID, randomString(5));

    socketRef.current = socket;

    socket.onopen = () => {
      setIsConnected(true);
      dispatch(setRetryWSState("idle"));
      dispatch(setRetryWS(false));
      setRetryCount(0);
      // alert(` connected!`);
    };

    socket.onclose = () => {
      dispatch(setRetryWSState("idle"));
      dispatch(setRetryWS(true));
      setIsConnected(false);
      // alert(` discconnected!`);

      if (localStorage.getItem(TAB_ID)) {
        localStorage.removeItem(TAB_ID);
      }

      retryIntervalRef.current = setTimeout(() => {
        if (
          !socketRef.current ||
          socketRef.current.readyState === WebSocket.CLOSED ||
          socketRef.current.readyState === WebSocket.CLOSING
        ) {
          setRetryCount((count) => count + 10);
          connectWebSocket();
        }
      }, 7000);
    };

    socket.onmessage = (event) => {
      var message = event.data;
      // event = { ...event, data: "Failed to connect, invalid token!" };
      // setMessages((prev) => [...prev, message]);
      // console.log(event);
      var usermetadata = applicationStore.getState().onboarding.userMetadata;
      var requestIds = applicationStore.getState().messageSlice.requestIds;
      try {
        if (event.data === "Connection successful!") {
          return;
        }
      } catch (e) {
        ErrorLogging({ error_type: `Error parsing JSON`, error_message: `${JSON.stringify(e)}` });
      }
      if (event.data.toLowerCase().search("failed") > -1) {
        try {
          console.log("resetting segment token and generating new anon id");
          localStorage.clear();
          analytics.reset();
          // analytics.generateAnonymousId();
          localStorage.removeItem("ajs_anonymous_id");
        } catch (e) {
          ErrorLogging({ error_type: `Failed to reset analytics`, error_message: `${JSON.stringify(e)}` });
        }
        getTokenValue();
        if (socketRef.current) {
          socketRef.current.close();
        }
        return;
      }

      try {
        message = JSON.parse(event.data);
      } catch (e) {
        console.log("Error parsing JSON: ", e, event);
        ErrorLogging({
          error_type: `Error parsing JSON`,
          error_message: `${JSON.stringify(e)}`,
          event: JSON.stringify(event),
        });

        return;
      }
      if (requestIds?.indexOf(message.trigger_ws_message_id) === -1) {
        return;
      }
      if (message.payload?.error || message?.error || message?.status_code > 299) {
        // dispatch(addToast({ type: "error", message: message?.payload?.error || message?.error }))
        // toast.error(message?.payload?.error || message?.error)
        var errorMessage = "";
        ErrorLogging({ error_type: `webSocket message error`, error_message: JSON.stringify(message) });
        switch (message.action) {
          case "chat/thread-not-created":
            if (message.payload?.error?.search("threads limit reached") > -1) {
              errorMessage = message.payload.error;
            }
            errorMessage = `Error creating thread. ${errorMessage || ""}`;
            break;
          case "chat/create-thread":
            errorMessage = "Error creating thread";
            break;
          case "chat/add-message":
            errorMessage = "Error adding message";
            // TODO : regenerate the question
            break;
          case "chat/new-message-available":
            errorMessage = "Failed to generate AI response. Please try again";
            break;
          case "chat/submit-and-create-document":
          case "chat/create-document":
            errorMessage = "Failed to create document. Please try again";
            // TODO : regenerate the question
            break;
          case "chat/update-question":
            errorMessage = "Error updating question";
            // TODO : regenerate the question
            break;
          case "chat/inferred-metadata":
            errorMessage = "Error inferring metadata";
            break;
          // TODO : handle the cases when user action was successful, but the backend failed. like sending new message fomr hte backend

          default:
            errorMessage = "";
            break;
        }
        if (errorMessage) {
          toast.error(errorMessage);
        }
        if (message?.payload?.allow_regeneration) {
          dispatch(addMessage({ new_messages: [{ message_type: "regenerate_ai_response" }] }));
        }
        dispatch(setAwaitingResponse(false));
        dispatch(setMessagingDisabled(false));
        return;
      }
      var scroll = applicationStore.getState().messageSlice.scroll;
      var chatInputMessage = applicationStore.getState().messageSlice.chatInputMessage;

      switch (message.action) {
        case "ai_title_available":
          dispatch(updateThreadTitleLocal(message.payload));
          break;

        case "chat/thread-created":
          // console.log(window.location.pathname)
          if (window.location.pathname === "/try") {
            setCookie("thread_id", message.payload.thread_id, 1);
          }
          dispatch(
            setupNewThread({
              threadId: message.payload.thread_id,
              threadType: "ask",
              messageId: message.payload.message_id,
              new_messages: message.payload.new_messages,
            })
          );
          if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
            dispatch(setMessagingDisabled(message.payload.messaging_disabled));
          }
          dispatch(
            insertNewThread({
              thread_id: message.payload.thread_id,
              thread_type: "ask",
              messaging_disabled: message.payload.messaging_disabled,
              id: message.payload.thread_id,
            })
          );
          // console.log("Thread created: ", event.data);
          break;

        case "chat/regeneration-not-allowed":
          toast.error("Regeneration not allowed. Please try again.");
          dispatch(setRegenerationHappening(false));
          break;
        case "chat/inferred-metadata":
          // console.log("Inferred metadata: ", event.data);
          dispatch(setAskToDraftIntentWSResponse(message.payload));
          dispatch(setAwaitingResponse(false));
          break;
        case "chat/set-questions":
          var payload = message.payload;
          var activeThreadData = applicationStore.getState().messageSlice.threadData;
          var email = applicationStore.getState().homeslice.email;
          var userMetadata = applicationStore.getState().onboarding.userMetadata;
          if (activeThreadData && activeThreadData.id === payload.thread_id) {
            dispatch(addMessage(payload));
            dispatch(setQuestions(payload.questions));
          }
          if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
            dispatch(setMessagingDisabled(message.payload.messaging_disabled));
          }
          dispatch(setAwaitingResponse(false));
          dispatch(setRegenerationHappening(false));
          trackAnalytics("received_questions", { email: email, chatId: activeThreadData?.id }, userMetadata);
          break;
        case "chat/message-added":
          // console.log("Message added: ", event.data);
          var payload = message.payload;
          var threadData = applicationStore.getState().messageSlice.threadData;
          // var chatInputMessage = applicationStore.getState().messageSlice.chatInputMessage;
          if (threadData && threadData.id === payload.thread_id) {
            payload["source"] = "user";
            payload["is_user_message"] = true;
            payload["message_text"] = chatInputMessage;
            payload["id"] = payload["message_id"];
            dispatch(addMessage(payload));
            dispatch(setChatInputMessage(""));
          }
          if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
            dispatch(setMessagingDisabled(message.payload.messaging_disabled));
          }
          dispatch(setShowAskToDraftModal({ show: false }));
          dispatch(setAwaitingResponse(true));
          break;
        case "chat/new-message-available":
          // console.log("New message available: ", event.data);
          var payload = message.payload;
          payload["source"] = "ai";
          payload["is_user_message"] = false;
          payload["text"] = payload["message_text"];
          payload["id"] = payload["message_id"];
          var threadData = applicationStore.getState().messageSlice.threadData;
          // var documentChangeAction = applicationStore.getState().messageSlice.documentChangeAction;
          // if (documentChangeAction) {
          //     dispatch(addMessage({
          //         ""
          //     }))
          //     dispatch(setDocumentChangeAction(null));
          // }
          if (threadData && threadData.id === payload.thread_id) {
            dispatch(addMessage(payload));
          }
          if (message.payload.messaging_disabled !== undefined && message.payload.messaging_disabled !== null) {
            dispatch(setMessagingDisabled(message.payload.messaging_disabled));
          } else if (message.payload.messaging_disabled === false) {
            dispatch(setMessagingDisabled(false));
            dispatch(setChangingDraftType(false));
          }
          if (message.payload?.information_messages) {
            message.payload.information_messages.forEach((element) => {
              dispatch(
                addMessage({
                  message_text: element,
                  message_type: "information_message",
                })
              );
            });
          }
          dispatch(setAwaitingResponse(false));
          dispatch(setSubmittingQnA(false));
          dispatch(setRegenerationHappening(false));
          break;
        case "chat/question-updated":
          // console.log("question updated: ", event.data);
          var updatingQuestion = applicationStore.getState().messageSlice.updatingQuestion;
          if (updatingQuestion.id) {
            if (updatingQuestion.action === "skip") {
              dispatch(skipFollowupQuestions(updatingQuestion.activeIndex));
            } else if (updatingQuestion.action === "answer") {
              dispatch(
                markQuestionAnswered({ index: updatingQuestion.activeIndex, response: updatingQuestion.response })
              );
            }
            // dispatch(setAwaitingDraftQuestionsResponse(false))
            dispatch(setUpdatingQuestion({}));
            dispatch(setAwaitingResponse(false));
            dispatch(setAwaitingDraftQuestionsResponse(false));
            // dispatch(setMessagingDisabled(false));
            dispatch(setSubmittingQnA(false));
          }
          break;
        case "chat/document-created":
          var payload = message.payload;
          var threadData = applicationStore.getState().messageSlice.threadData;
          if (threadData && threadData.id === payload.thread_id) {
            // dispatch(setDocument(payload));
            dispatch(addMessage(payload));
          }
          dispatch(setSubmittingQnA(false));
          dispatch(setAwaitingResponse(false));
          dispatch(setMessagingDisabled(false));
          dispatch(setRegenerationHappening(false));
          break;

        case "chat/party-updated":
          const partiesList = applicationStore.getState().homeslice.partiesList;
          const party = partiesList.find((party) => party.id === message.payload.party_id);
          dispatch(updateSelectedParty(party));
          dispatch(setAwaitingResponse(false));
          dispatch(setMessagingDisabled(false));
          break;
        case "chat/document-deleted":
          // console.log("Document deleted: ", event.data);
          break;
        case "ai-thread-title-available":
          // console.log("AI thread title available: ", event.data);
          break;
        default:
          // console.log("Unknown message: ", event.data);
          break;
      }
      if (scroll) {
        dispatch(setScroll(false));
      }
    };
    // Cleanup on component unmount
    return () => {
      // socket.close();
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (localStorage.getItem(TAB_ID)) {
        localStorage.removeItem(TAB_ID);
      }
    };
  };

  // }, [url, accesstoken, retryCount, retryWS]);

  // Function to send a message through WebSocket
  const sendMessage = (message, scroll, draftQuestions) => {
    var success = false;
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
      success = true;
    } else {
      toast.error("Connection lost. Please refresh the page and try again.");
      ErrorLogging({ error_type: `Socket connection not open/ active`, error_message: JSON.stringify(message) });
      dispatch(setAwaitingDraftQuestionsResponse(false));
      dispatch(setAwaitingResponse(false));
      // window.location.reload();
      return;
    }
    if (draftQuestions) {
      dispatch(setAwaitingDraftQuestionsResponse(true));
    } else {
      dispatch(setAwaitingResponse(true));
    }

    if (scroll) {
      dispatch(setScroll(true));
    }
    if (success){
      return success
    }
  };

  const createMessage = (threadType, api, payload) => {
    let r = (Math.random() + 1).toString(36).substring(7);
    // payload["type"] = threadType;
    var data = { ...payload, type: threadType };
    dispatch(addRequestIds(r));
    return {
      action: `chat/${api}`,
      payload: data,
      trigger_ws_message_id: r,
    };
  };

  // Handle incoming messages (optional)
  const [messages, setMessages] = useState([]);

  return (
    <WebSocketContext.Provider
      value={{
        isConnected,
        messages,
        sendMessage,
        createMessage,
        socketRef,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

// Hook to use WebSocket context in any component
export const useWebSocketContext = () => useContext(WebSocketContext);
