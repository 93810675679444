import React, { useEffect } from "react";
import Button from "../Button";
import { ArrowRight } from "@phosphor-icons/react";
import PlanCards from "../components/PlanCards";
import BuyLegalReviewCreditImg from "../../../images/buy-legal-review-credit.png";
import { useDispatch, useSelector } from "react-redux";
import { setShowSubscriptionPopup } from "src/pages/onboarding/onboardingSlice";
import { X } from "@phosphor-icons/react/dist/ssr";
import { getCheckoutUrl, setSelectedPaymentPlanName, setUserSelectedPlan } from "src/pages/chatpage/chatSlice";
import { toast } from "react-toastify";
import { PLANS, PLAN_TYPES, plans } from "src/components/Constants";
import Switch from "../components/Switch";
import { Spin } from "antd";

const SubscriptionPopup = () => {
  const dispatch = useDispatch();
  const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
  const closePopup = () => {
    dispatch(setShowSubscriptionPopup(false));
  };
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const [planType, setPlanType] = React.useState(PLAN_TYPES.MONTHLY);
  const [plan, setPlan] = React.useState(null);

  const checkoutUrlStatus = useSelector((state) => state.chat.getCheckoutUrlStatus);
  const checkoutUrl = useSelector((state) => state.chat.checkoutUrl);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const subscription_type = userMetadata?.subscription_type;

  useEffect(() => {
    setPlanType(PLAN_TYPES.MONTHLY);
    setPlan(null);
  }, [showSubscriptionPopup]);

  let title = "";
  let subTitle = "";
  let availablePlans = [];
  if (subscription_type === "free_tier") {
    title = "Lawyer reviews are a premium feature";
    subTitle = "Choose a plan to proceed";
    availablePlans = plans;
  } else if (subscription_type === "subscriber_business") {
    title = "Lawyer reviews are a premium feature";
    subTitle = "Choose a plan to proceed";
    availablePlans = plans.slice(0, 2);
    plans[1].button.disabled = true;
  }

  useEffect(() => {
    if (plan) {
      var data;
      if (plan === PLANS.ADHOC) {
        data = {
          payment_type: "ad_hoc_legal_review",
          subscription_info: null,
          legal_review_thread_id: threadData?.id,
          redirect_url: `${process.env.REACT_APP_URL}/?x-vercel-protection-bypass=${process.env.REACT_APP_VERCEL_PROTECTION_BYPASS}`,
        };
      } else if (plan === PLANS.SUBSCRIBER_BUSINESS || plan === PLANS.SUBSCRIBER_ENTERPRISE) {
        data = {
          payment_type: "subscription",
          subscription_info: {
            subscription_type: plan,
            payment_frequency: planType,
          },
          legal_review_thread_id: threadData?.id,
          redirect_url: `${process.env.REACT_APP_URL}/?x-vercel-protection-bypass=${process.env.REACT_APP_VERCEL_PROTECTION_BYPASS}`,
        };
      }
      dispatch(setUserSelectedPlan(plan));
      dispatch(getCheckoutUrl(data));
      dispatch(setSelectedPaymentPlanName(plan));
    }
  }, [plan]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (["idle", "loading", "success", undefined].indexOf(checkoutUrlStatus) === -1) {
      toast.error("Failed to get checkout url");
    }
  }, [checkoutUrlStatus]);

  useEffect(() => {
    if (checkoutUrl) {
      closePopup();
      // dispatch(setShowCheckoutModal(true))
    }
  }, [checkoutUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="px-8 py-8">
      {/* Title */}
      <div className="flex justify-between w-full">
        <h5 className="italic text-[24px] leading-[33.6px] w-full text-center font-[Lora] font-[600]">{title}</h5>
        <X size={32} className="text-[#949494] cursor-pointer" onClick={closePopup} />
      </div>
      {subTitle && <p className="text-base font-normal text-center text-[#353535]">{subTitle}</p>}

      {/* Yellow card */}
      {/* <div className='bg-[#DCA218]/10 w-full max-h-[155px]   flex border !border-[#DCA21866]/40 rounded-lg mt-6'>
                <div className='w-1/2 py-6 pl-6'>
                    <p className='text-[#353535] text-sm font-normal'>Need a one-time legal review?</p>
                    <p className='text-[#011513] text-lg font-semibold mt-1'>Buy a legal review credit at only $49</p>
                    <Button
                        disabled={checkoutUrlStatus === 'loading'}
                        className={'py-2 mt-3 text-sm px-3'}
                        text={<>Buy Now <ArrowRight className='ml-2' size={20} /></>}
                        onClick={() => { setPlan(PLANS.ADHOC) }}
                    />
                </div>
                <div className='w-1/2'>
                    <img src={BuyLegalReviewCreditImg} className='object-contain w-full h-full' alt='Specialized lawyer' />
                </div>
            </div> */}
      <div className="flex items-center justify-between mt-[20px]">
        {<p className="text-lg font-semibold">Or subscribe to InHouse </p>}
        {/* <PlanToggle planType={planType} setPlanType={setPlanType} /> */}
        <Switch
          left={{
            value: PLAN_TYPES.YEARLY,
            label: "Yearly",
            info: "Save $480",
          }}
          right={{
            value: PLAN_TYPES.MONTHLY,
            label: "Monthly",
          }}
          value={planType}
          onToggle={setPlanType}
        />
      </div>
      <PlanCards
        cards={availablePlans}
        billCycle={planType}
        PLAN_TYPES={PLAN_TYPES}
        setPlan={setPlan}
        wrapperClassName="mt-[20px]"
        checkoutUrlStatus={checkoutUrlStatus}
      />
      {checkoutUrlStatus === "loading" && <Spin fullscreen tip="Loading..."></Spin>}
    </div>
  );
};

export default SubscriptionPopup;
