import React from "react";
import { useSelector } from "react-redux";
import Imhoff from "src/images/Imhoff.png";

const ImhoffLegal = () => {
  const referralCategoryText = useSelector((state) => state.onboarding.referralCategoryText);
  return (
    <div className="w-full md:w-[572px]  bg-[#ECF5F3] px-[32px] py-[48px] flex flex-col gap-[20px]">
      <p className="font-[lora] font-bold italic text-[24px] text-[#011513]">{referralCategoryText} for free</p>

      <div className="flex gap-[8px]">
        <img src={Imhoff} alt="Imhoff" />
        <p className="md:mr-10 font-sans text-[12px] font-[400] text-[#666666]">
          Partnering with America’s largest criminal defense firms -
          <span className="font-[500] text-[#222222]"> Imhoff & Associates operating since 20+ years </span>
        </p>
      </div>
    </div>
  );
};

export default ImhoffLegal;
