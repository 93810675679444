import React, { useEffect, useState } from "react";
import USFlag from "src/images/us-flag.png";
import { stateOptionsNew } from "src/components/Constants";
import MMLegal from "./MMLegal";
import ImhoffLegal from "./ImhoffLegal";

const ReferralAside = (props) => {
  const {
    name,
    setName,
    Email,
    setEmail,
    stateOfResidence,
    setStateOfResidence,
    phoneNumber,
    setPhoneNumber,
    text,
    setText,
    referralCategory,
    notice,
  } = props;
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    switch (referralCategory) {
      case "criminal":
        setBanner(<ImhoffLegal />);
        break;
      default:
        setBanner(<MMLegal />);
    }
  }, [referralCategory]);
  return (
    <div className="flex flex-col w-full">
      {banner}
      <div>
        <div className="px-[32px] pt-[30px] pb-[16px] flex flex-wrap flex-col md:flex-row gap-x-[24px] gap-y-[20px]">
          <div className="flex flex-col gap-[4px] w-full md:w-[242px] h-full">
            <label className="font-sans font-medium text-[14px] text-[#141414]">Name</label>
            <input
              className="border-[1px] border-[#D4D4D4] p-[16px] h-[48px] w-full text-[14px] font-normal rounded-[96px]"
              type="text"
              name="name"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {notice?.name && <p className="text-red-500">Please provide your name</p>}
          </div>

          <div className="flex flex-col gap-[4px] w-full md:w-[242px] h-full">
            <label className="font-sans font-medium text-[14px] text-[#141414]">State of Residence</label>
            <select
              className={`border-[1px] w-full border-[#D4D4D4] py-[12px] h-[48px] text-[14px] font-normal rounded-[96px] outline-none md:text-[14px] px-[16px] text-[#302F2F] placeholder:font-normal  focus:!border-[#18475A] `}
              name="residence"
              value={stateOfResidence}
              onChange={(e) => setStateOfResidence(e.target.value)}
            >
              <option value="">Select State</option>
              {Object.keys(stateOptionsNew).map((stateKey) => (
                <option key={stateKey} value={stateKey}>
                  {stateOptionsNew[stateKey]}
                </option>
              ))}
            </select>
            {notice?.stateOfResidence && <p className="text-red-500">Please select a state</p>}
          </div>

          <div className="flex flex-col gap-[4px] w-full md:w-[242px] h-full">
            <label className="font-sans font-medium text-[14px] text-[#141414]">
              Email <span className="text-[#686868] font-normal text-[12px]">(optional)</span>{" "}
            </label>
            <input
              className="border-[1px] border-[#D4D4D4] p-[16px] h-[48px] w-full text-[14px] font-normal rounded-[96px]"
              type="email"
              name="email"
              placeholder="Enter your email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {notice?.email && <p className="text-red-500">Please enter a valid email</p>}
          </div>

          <div className="flex flex-col gap-[4px] w-full md:w-[242px] h-full">
            <label className="font-sans font-medium text-[14px] text-[#141414]">Phone Number</label>
            <div className="w-full h-[48px] border-[1px] border-[#D4D4D4] flex items-center bg-white rounded-[96px]">
              <div
                className="px-[12px] text-[14px] font-normal w-[58px] text-sans text-[#686868] rounded-[8px] flex flex-row items-center gap-[4px]"
                title="Currently available only in the USA"
              >
                <img src={USFlag} className="w-[15px]" alt="🇺🇸" />
                <span>+1</span>
              </div>
              <input
                type="number"
                name="telephone"
                placeholder="Enter phone no."
                className="border-x-[1px] p-[16px] h-full w-full text-[14px] font-normal font-sans rounded-r-[96px]"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            {notice?.phoneNumber && <p className="text-red-500">Please enter a valid phone number</p>}
          </div>

          <div className="flex flex-col gap-[8px] w-full pb-2">
            <p className="text-[14px] font-[500] font-sans text-[#353535] ">
              Anything else we should know? <span className="text-[#686868] font-normal text-[12px]">(optional)</span>{" "}
            </p>
            <textarea
              className="border px-3 py-3 h-[90px] border-[#D1D1D1] w-full rounded-lg text-[12px] md:text-[14px] font-normal"
              value={text}
              placeholder={`Outline your concern for personalized legal support \n Eg: I need help reviewing a rental agreement...`}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralAside;
