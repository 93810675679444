import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setWordCountPopup } from "src/pages/chatpage/messageSlice";
const WordLimitPopup = ({ onProceed }) => {
  const disptch = useDispatch();
  const wordCountPopup = useSelector((state) => state.messageSlice.wordCountPopup);

  const proceed = () => {
    onProceed();
    disptch(setWordCountPopup(false));
  };
  if (!wordCountPopup) return null;

  return (
    <div className="fixed top-0 left-0 inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[200]">
      <div className="w-[800px] h-[333px] gap-[32px] bg-white p-6 rounded-lg shadow-lg flex flex-col">
        <h2 className="text-xl font-bold">The text in the chat box is too long</h2>
        <hr></hr>
        <div className="flex flex-col gap-[8px]">
          <p className="font-medium text-[18px] leading-[28px] font-sans text-[#686868]">
            The content in the chatbox is longer than the 10,000-word limit.We can only process up to 10K words at a
            time currently.
          </p>
          <p className="font-semibold text-[18px] font-sans text-[#353535]">
            We will only consider the first 10,000 words of your text. Would you like to proceed?
          </p>
        </div>
        <div className="flex justify-end mt-6 space-x-4">
          <button
            onClick={() => disptch(setWordCountPopup(false))}
            className="px-[24px] py-[12px] rounded-[8px] border"
          >
            Cancel
          </button>
          <button onClick={proceed} className="px-[24px] py-[12px] rounded-[8px] border bg-[#1B2B48] text-white">
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default WordLimitPopup;
