import React from "react";
import SubscriptionCard from "./SubscriptionCard";
import { setShowLearnMoreModal } from "src/pages/onboarding/onboardingSlice";
import { useDispatch } from "react-redux";

const PlanCards = (props) => {
  const { wrapperClassName, PLAN_TYPES, setPlan, checkoutUrlStatus, cards = [], billCycle = "MONTHLY" } = props;
  const dispatch = useDispatch();
  return (
    <>
      <div className="w-full justify-center items-center gap-[4px] mt-[10px]">
        <p className="font-bold italic text-[32px] leading-[44px] font-[lora] text-center text-[#1B2B48]">
          Take charge of your legal needs{" "}
        </p>
        <p className="text-[#686868] font-normal text-[16px] leading-[25px] font-sans text-center">Choose a plan</p>
      </div>
      <div className={`flex gap-6 flex-col md:flex-row ${wrapperClassName}`}>
        {Object.keys(cards).map((key) => (
          <SubscriptionCard
            data={cards[key]}
            billCycle={billCycle}
            PLAN_TYPES={PLAN_TYPES}
            planPublicName={key}
            setPlan={setPlan}
            checkoutUrlStatus={checkoutUrlStatus}
          />
        ))}
      </div>
      {/* {JSON.stringify(cards)} */}

      <p className="mt-6 text-[12px] font-normal italic font-sans text-center">
        *Unlimited 30-minute sessions, one per topic, to ask legal questions or have documents reviewed.{" "}
        <span
          className="underline cursor-pointer"
          onClick={() => {
            dispatch(setShowLearnMoreModal(true));
          }}
        >
          {" "}
          Learn More
        </span>
      </p>
    </>
  );
};

export default PlanCards;
