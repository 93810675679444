import React from "react";
import Button from "./Button";
import { ArrowRight } from "@phosphor-icons/react";
import SpecialisedLawyerImg from "../../images/specialised-lawyer.png";
import { useDispatch } from "react-redux";
import { setShowScheduleMeeting } from "../onboarding/onboardingSlice";

const SpecialisedLawyer = () => {
  const dispatch = useDispatch();

  const scheduleMeeting = () => {
    dispatch(setShowScheduleMeeting({ show: true, lawyer_details: {} }));
  };
  return (
    <div className="flex flex-col items-center justify-center mt-6 pb-10">
      <h6 className="text-2xl font-medium">Let's find a lawyer specialized for your needs</h6>
      <p className="mt-2 text-sm font-normal text-center">
        We'll match you with a lawyer specializing in your industry and <br />
        legal requirements, covering all 50 states.
      </p>

      <div className="bg-[#DCA21814] w-full max-h-52   flex border !border-[#DCA218]/10 rounded-lg mt-6">
        <div className="w-1/2 py-8 pl-8">
          <p className="text-[#141414] text-base font-semibold">Schedule a 1-on-1 call with our legal support team</p>
          <p className="text-[#353535] text-sm font-normal mt-2">
            Tell us about your legal needs, and we'll connect you with a lawyer who's best suited for your case.
          </p>
          <Button
            className={"py-2 mt-4 cursor-pointer"}
            onClick={scheduleMeeting}
            text={
              <>
                Schedule a call <ArrowRight className="ml-2" size={20} />
              </>
            }
          />
        </div>
        <div className="w-1/2">
          <img src={SpecialisedLawyerImg} className="object-contain w-full h-full" alt="Specialised lawyer" />
        </div>
      </div>
    </div>
  );
};

export default SpecialisedLawyer;
