import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useDispatch, useSelector } from "react-redux";
import SavedDataModalComponent from "../../components/Prompts/SavedDataModalComponent";
import animationData from "src/lotties/Loader-animation";
import {
  getAskThreadMessages,
  getPresignedUrlAnon,
  loadThreadTryPage,
  resetFileUploadProgress,
  resetIsChatFull,
  resetThreadData,
  setAwaitingCounter,
  setAwaitingResponse,
  setCreationInProgress,
  setLastMessageId,
  setMessagingDisabled,
  setNewThreadMessage,
  setSentences,
  updateReferenceDoc,
  updateUploadedFileNameAnon,
  uploadFile,
} from "./messageSlice";
import { resetChatPage } from "./chatSlice";
import { resetData } from "./threadsSlice";
import LegalReviewRequestAside from "../LawyerHub/LegalReviewRequestAside/index.js";
import { getCookie, getSegmentId, readDataFromLocalStorage, setCookie, trackAnalytics } from "../helpers";
import UpgradePopupTryPage from "./UpgradeToCounselPopup";
import { useWebSocketContext } from "src/WebsocketProvider";
import SignupPopup from "./SignupPopup";
import applicationStore from "src/persistence/stores/RootStore";
import TryPageChat from "./TryPageChat";
import LandingPage from "./LandingPage";
import Lottie from "react-lottie";
import { toast } from "react-toastify";
import { campaignIdMappings, campaignNameToDetailMappings, campaignUtmSourceMappings } from "src/components/Constants";

const TryPage = () => {
  const dispatch = useDispatch();
  const { isSignedIn } = useUser();

  const messageListRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [threadType, setThreadType] = React.useState("ask");
  const [query, setQuery] = React.useState("");
  const [inputMessage, setInputMessage] = React.useState("");
  const [chatId, setChatId] = React.useState("");
  const [steps, setSteps] = React.useState(["Analyzing your request", `Reviewing account info`, "Preparing response"]);
  const [threadMessages, setThreadMessages] = React.useState([]);
  // const [sentences, setSentences] = React.useState([]);
  const [shimmerNotAllowed, setShimmerNotAllowed] = React.useState(false);
  const [messagesLength, setMessagesLength] = React.useState(0);
  const [page, setPage] = React.useState("loading");
  const { sendMessage, createMessage, socketRef } = useWebSocketContext();
  const [referenceDocId, setReferenceDocId] = React.useState(null);
  const [referenceDoc, setReferenceDoc] = React.useState(null);
  const [uploadPercentage, setUploadPercentage] = React.useState(0);
  const [loadingText, setLoadingText] = React.useState("");
  const [generatingDraft, setGeneratingDraft] = React.useState(false);
  const [utmSource, setUtmSource] = React.useState(null);
  const [utmCampaign, setUtmCampaign] = React.useState(null);
  const [utmThreadId, setUtmThreadId] = React.useState(null);
  const [utmMedium, setUtmMedium] = React.useState(null);
  const [utmData, setUtmData] = React.useState({});

  const getThreadStatus = useSelector((state) => state.messageSlice.getThreadStatus);
  const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const showAskToDraftModal = useSelector((state) => state.messageSlice.showAskToDraftModal);
  const email = useSelector((state) => state.homeslice.email);
  const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
  const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
  const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
  const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
  const submittingQnA = useSelector((state) => state.messageSlice.submittingQnA);
  const regenerationHappening = useSelector((state) => state.messageSlice.regenerationHappening);
  const userConfig = useSelector((state) => state.onboarding.userConfig);
  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);
  const presignedUrl = useSelector((state) => state.messageSlice.presignedUrl);
  const fileuploadError = useSelector((state) => state.messageSlice.fileuploadError);
  const GptThreadNotFoundException = useSelector((state) => state.messageSlice.GptThreadNotFoundException);
  const messagingDisabled = useSelector((state) => state.messageSlice.messagingDisabled);

  const queryParams = new URLSearchParams(window.location.search);

  const createUtmThread = () => {
    var utm_datum = {
      utm_campaign: queryParams.get("utm_campaign"),
      utm_source: queryParams.get("utm_source"),
      utm_medium: queryParams.get("utm_medium"),
      thread_id: queryParams.get("thread_id"),
    };
    if (queryParams.get("utm_campaign")) {
      const creationInProgress = applicationStore.getState().messageSlice.creationInProgress;
      const thread = applicationStore.getState().messageSlice.threadData;
      if (creationInProgress || thread.id) {
        return;
      }
      setPage("loading");
      setLoadingText("Initiating new chat");
      var utm_campaign = queryParams.get("utm_campaign");
      var utm_campaign_split = utm_campaign.split("_");
      var utm_campaign_name = utm_campaign_split?.[utm_campaign_split.length - 3].toLowerCase();

      var campaignId = campaignIdMappings[utm_campaign];
      // debugger;
      var campaignUtmSource = campaignUtmSourceMappings[utm_campaign];
      var message;
      if (campaignId === "others") {
        // case :  when campaignId is not present in the mapping
        message = createMessage("draft", "create-thread", {
          message: campaignNameToDetailMappings[utm_campaign_name],
          reference_doc_storage_id: null,
          draft_type_id: null,
          utm_source: campaignUtmSource,
        });
      } else if (!campaignId) {
        // case : when campaign is unknown
        message = createMessage("draft", "create-thread", {
          message: `Draft a ${utm_campaign_name}`,
          reference_doc_storage_id: null,
          draft_type_id: null,
          utm_source: campaignUtmSource,
        });
      } else {
        // case: when campaignid is present in the mapping
        message = createMessage("draft", "create-thread", {
          message: null,
          reference_doc_storage_id: null,
          draft_type_id: campaignId,
          utm_source: campaignUtmSource,
        });
      }
      if (!creationInProgress) {
        if (socketRef?.current?.readyState !== 1) {
          setTimeout(() => {
            return createUtmThread();
          }, 1000);
        } else {
          dispatch(setCreationInProgress(true));
          trackAnalytics("trypage-new-thread-sending-first-message", { anonymous_id: getSegmentId(), ...utm_datum });
          sendWsMessage(message);
          localStorage.setItem("utm_campaign", utm_campaign);
        }
      }
    } else {
      // setPage("landing");
    }
  };

  useEffect(() => {
    dispatch(resetThreadData());
    dispatch(setMessagingDisabled(false));
    dispatch(resetChatPage());
    dispatch(resetData());
    dispatch(resetIsChatFull());
    setUtmCampaign(queryParams.get("utm_campaign"));
    setUtmSource(queryParams.get("utm_source"));
    setUtmMedium(queryParams.get("utm_medium"));
    setUtmThreadId(queryParams.get("thread_id"));

    var datum = {
      utm_campaign: queryParams.get("utm_campaign"),
      utm_source: queryParams.get("utm_source"),
      utm_medium: queryParams.get("utm_medium"),
      utm_thread_id: queryParams.get("thread_id"),
    };
    setUtmData(datum);

    if (!queryParams.get("utm_campaign") || queryParams.get("utm_campaign") === localStorage.getItem("utm_campaign")) {
      // case : utm campaign is either not present, or it is already read and thread has been generated: we will load data from localstorage
      setTimeout(() => {
        var cookieFromHomepage = getCookie("userInput");
        var recentlyReadUserInput = getCookie("alreadyReadInput");
        if (cookieFromHomepage && cookieFromHomepage !== recentlyReadUserInput) {
          setCookie("alreadyReadInput", cookieFromHomepage, 7);
          setInputMessage(cookieFromHomepage);
          setTimeout(() => {
            trackAnalytics("try_page:new_thread:from_cookie", { anonymous_id: getSegmentId(), ...utmData });
            onsubmit(cookieFromHomepage);
            setInputMessage("");
            setPage("chat");
          }, 1000);
        } else {
          // TODO: read data from localstorage
          readDataFromLocalStorage(setChatId, loadingText, dispatch, setPage);
        }
      }, 1000);
    }
    if (queryParams.get("utm_campaign") && queryParams.get("utm_campaign") !== localStorage.getItem("utm_campaign")) {
      // case : utm campaign is present and it is not read yet/ there doesn't exist data in localstorage
      if (queryParams.get("utm_source") === "lawyergpt") {
        // case: gpt based chat
        var threadid = queryParams.get("thread_id");
        if (!threadid) {
          setPage("landing");
        } else {
          // list all the messages of the thread
          dispatch(getAskThreadMessages(threadid));
          localStorage.setItem("thread_id", threadid);
        }
      } else if (queryParams.get("utm_source") === "adwords") {
        // case : utm campaign is present and it is not read yet/ there doesn't exist data in localstorage
        createUtmThread();
      } else {
        setPage("landing");
      }
    }
  }, []);

  useEffect(() => {
    if (GptThreadNotFoundException) {
      if (!(queryParams.get("thread_id") && queryParams.get("utm_source") === "lawyergpt")) {
        setPage("landing");
      }
      var threadid = queryParams.get("thread_id");
      trackAnalytics("trypage-new-thread-sending-first-message", { anonymous_id: getSegmentId(), ...utmData });
      var message = createMessage("draft", "create-thread", {
        gpt_context_id: threadid,
        type: "draft",
        utm_source: utmSource,
      });
      sendWsMessage(message);
    }
  }, [GptThreadNotFoundException]);

  useEffect(() => {
    if (isSignedIn) {
      if (getCookie("thread_id")) {
        window.location.href = "/chat/" + getCookie("thread_id");
      } else {
        window.location.href = "/home";
      }
    }
  }, [isSignedIn]);

  // setSteps();

  const submit = () => {
    var message = createMessage("draft", "submit-and-create-document", {
      thread_id: threadData?.id,
      additional_context: inputMessage,
    });
    if (socketRef?.current?.readyState !== 1) {
      setTimeout(() => {
        return submit();
      }, 1000);
    } else {
      dispatch(setMessagingDisabled(true));
      dispatch(setAwaitingResponse(true));
      setShimmerNotAllowed(false);
      sendWsMessage(message);
      setGeneratingDraft(true);
    }
  };

  useEffect(() => {
    if (threadData?.messages?.length > 6) {
      scrollContainerToBottom(messageListRef);
    }
    if (threadData?.messages?.length > 0) {
      setPage("chat");
    }
    if (queryParams.get("utm_source") === "lawyergpt") {
      if (threadData?.messages?.length === 1 && !messagingDisabled) {
        dispatch(setMessagingDisabled(true));
        setTimeout(() => {
          submit();
        }, 1000);
      }
      if (threadData?.messages?.length > 1 && threadData?.document?.id) {
        setGeneratingDraft(false);
      }
    }
  }, [scroll, threadData?.messages]); // eslint-disable-line

  useEffect(() => {
    if (threadData.messages?.length > 1) {
      if (threadData?.document?.url || threadData?.document?.google_doc_id) {
        setSteps(["Analyzing your request", "Conducting legal research", "Updating your document"]);
      } else {
        setSteps(["Analyzing your request", "Conducting legal research ", "Preparing response"]);
      }
      var uniqueMessages = threadData.messages.filter(
        (message, index, self) =>
          index ===
          self.findIndex(
            (t) => t.text === message.text && t.message_type === message.message_type && t.id === message.id
          )
      );
      setThreadMessages(uniqueMessages);
      let lastMessageId = null;
      for (let i = uniqueMessages.length - 1; i >= 0; i--) {
        if (
          uniqueMessages[i].id !== null &&
          uniqueMessages[i].id !== "" &&
          uniqueMessages[i].message_type !== "regenerate_ai_response"
        ) {
          lastMessageId = uniqueMessages[i].id;
          break;
        }
      }

      if (lastMessageId !== null) {
        dispatch(setLastMessageId(lastMessageId));
      }
    } else {
      setThreadMessages(threadData.messages);
      let lastMessageId = null;
      for (let i = threadData?.messages?.length - 1; i >= 0; i--) {
        if (
          threadData.messages[i]?.id !== null &&
          threadData.messages[i]?.id !== "" &&
          threadData.messages[i]?.message_type !== "regenerate_ai_response"
        ) {
          lastMessageId = threadData.messages[i].id;
          break;
        }
      }

      if (lastMessageId !== null) {
        dispatch(setLastMessageId(lastMessageId));
      }
    }
    if (threadData?.messages && threadData?.messages.length > 0) {
      var lastMessageType = threadData.messages[threadData.messages.length - 1].message_type;
      if (lastMessageType === "draft_qna_message") {
        dispatch(setSentences(["Provide answers to the questions above to continue"]));
        dispatch(setMessagingDisabled(true));
        if (submittingQnA) {
          setShimmerNotAllowed(false);
        } else {
          setShimmerNotAllowed(true);
        }
      } else if (lastMessageType === "regenerate_ai_response") {
        dispatch(
          setSentences([
            "Unfortunately the AI wasn't able to handle your last response, use the regenerate button above to try again",
          ])
        );
        dispatch(setMessagingDisabled(true));
        if (regenerationHappening) {
          setShimmerNotAllowed(false);
        } else {
          setShimmerNotAllowed(true);
        }
      } else {
        dispatch(setSentences(["Message Inhouse"]));
        setShimmerNotAllowed(false);
      }
    }
  }, [scroll, threadData.messages, getThreadStatus, window.location.href]); // eslint-disable-line

  useEffect(() => {
    setInterval(() => {
      saveDataToCookies("interval");
    }, 20000);
  }, []);

  useEffect(() => {
    scrollContainerToBottom(messageListRef);
  }, [awaitingResponse, threadData?.messages]); // eslint-disable-line

  useEffect(() => {
    if (threadData?.messages?.length !== messagesLength) {
      saveDataToCookies("useeffect");
      setMessagesLength(threadData?.messages?.length);
    }
  }, [threadData?.messages]); // eslint-disable-line

  useEffect(() => {
    if (threadData?.id) {
      if (referenceDocId)
        dispatch(updateReferenceDoc({ reference_doc_storage_id: referenceDocId, name: referenceDoc.name }));
    }
  }, [threadData?.id]); // eslint-disable-line

  const scrollContainerToBottom = (messageListRef) => {
    if (messageListRef.current) {
      if (threadData?.messages?.length > 6) {
        messageListRef.current.scrollTop += 200;
      }
    }
  };

  const saveDataToCookies = (source) => {
    var thread = applicationStore.getState().messageSlice.threadData;
    // var messages = thread?.messages;
    if (thread?.id) {
      var newMessages = JSON.stringify(thread?.messages);
      localStorage.setItem("threadMessages", newMessages);
      localStorage.setItem("questions", JSON.stringify(thread?.questions));
      localStorage.setItem("document", JSON.stringify(thread?.document));
      localStorage.setItem("thread_id", JSON.stringify(thread?.id));
      localStorage.setItem("reference_doc", thread?.reference_doc ? JSON.stringify(thread?.reference_doc) : null);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      // toast.error("No file selected.");
      return;
    }
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (["docx", "pdf"].includes(fileExtension)) {
      trackAnalytics("trypage-attach-document-clicked", { anonymous_id: getSegmentId(), ...utmData });
      // console.log("Valid file selected:", file);
      dispatch(getPresignedUrlAnon());
      dispatch(resetFileUploadProgress());
      setUploadPercentage(0);
      setReferenceDoc(file);
    } else {
      toast.error("Invalid file format. Only docx and pdf are allowed.");
      setReferenceDoc(null);
    }
  };
  const onsubmit = (msg) => {
    var message = msg || inputMessage;
    // window.analytics.track("new ask thread. sending first message", { "user_email": email, "message": inputMessage })

    dispatch(setNewThreadMessage(message));
    var docId = referenceDocId;
    if (uploadPercentage === 100 && referenceDoc) {
      docId = referenceDocId;
    } else {
      docId = null;
    }
    var wsmessage;
    if (threadData.id) {
      trackAnalytics("trypage-additional-message", {
        anonymous_id: getSegmentId(),
        thread_id: threadData.id,
        ...utmData,
      });
      wsmessage = createMessage(threadType, "add-message", { message: message, thread_id: threadData.id });
    } else {
      trackAnalytics("trypage-new-thread-sending-first-message", { anonymous_id: getSegmentId(), ...utmData });
      wsmessage = createMessage("ask", "create-thread", { message: message, reference_doc_storage_id: docId });
      setPage("loading");
    }
    sendWsMessage(wsmessage);
  };

  const sendWsMessage = (message) => {
    const awaitingCounter = applicationStore.getState().messageSlice.awaitingCounter;
    if (awaitingCounter > 20) {
      setCookie("alreadyReadInput", "", 1);
      // window.location.reload();
    }
    dispatch(setMessagingDisabled(true));
    dispatch(setAwaitingResponse(true));
    if (socketRef?.current?.readyState !== 1) {
      dispatch(setAwaitingCounter(awaitingCounter + 1));
      setTimeout(() => {
        sendWsMessage(message);
      }, 1000);
    } else {
      sendMessage(message, true);

      setInputMessage("");
    }
  };

  useEffect(() => {
    if (fileuploadError) {
      toast.error(`File Upload failed. Please try again.`);
      setUploadPercentage(0);
      setReferenceDocId(null);
      setReferenceDoc(null);
    }
  }, [fileuploadError]); // eslint-disable-line

  useEffect(() => {
    if (uploadPercentage === 100) {
      setReferenceDocId(presignedUrl?.storage_id);
    }
  }, [uploadPercentage]); // eslint-disable-line

  useEffect(() => {
    if (referenceDoc && presignedUrl) {
      var filename = referenceDoc.name;
      dispatch(updateUploadedFileNameAnon({ filename: filename, storage_id: presignedUrl?.storage_id }));
      trackAnalytics("ask thread: reference doc uploaded", {
        anonymous_id: getSegmentId(),
        user_email: email,
        filename: filename,
        ...utmData,
      });
      // window.analytics.track("ask thread: reference doc uploaded", { "user_email": email, "filename": filename });
      dispatch(
        uploadFile({
          file: referenceDoc,
          data: presignedUrl.upload_fields,
          url: presignedUrl.url,
          onUploadProgress: (progress) => {
            setTimeout(() => {
              setUploadPercentage(progress);
            }, 3000);
          },
        })
      );
    }
  }, [presignedUrl]); // eslint-disable-line
  return (
    <>
      {page === "loading" && (
        <>
          <div className="flex flex-col justify-center items-center h-screen gap-[40px]">
            <Lottie options={defaultOptions} height={80} width={80} />
            {loadingText && <p className="text-center">{loadingText}</p>}
          </div>
        </>
      )}
      {page === "landing" && (
        <>
          <LandingPage
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            onsubmit={onsubmit}
            referenceDoc={referenceDoc}
            handleFileChange={handleFileChange}
            uploadPercentage={uploadPercentage}
            setReferenceDoc={setReferenceDoc}
            fileuploadSuccess={uploadPercentage}
          />
        </>
      )}
      {page === "chat" && (
        <TryPageChat
          threadData={threadData}
          messageListRef={messageListRef}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          onsubmit={onsubmit}
          awaitingResponse={awaitingResponse}
          showAskToDraftModal={showAskToDraftModal}
          showSubscriptionPopup={showSubscriptionPopup}
          showUpgradingPlan={showUpgradingPlan}
          showBusinessPlanUnlocked={showBusinessPlanUnlocked}
          showNextSteps={showNextSteps}
          showScheduleMeeting={showScheduleMeeting}
          email={email}
          setPage={setPage}
          threadType={threadType}
          setSteps={setSteps}
          userConfig={userConfig}
          dispatch={dispatch}
          defaultOptions={defaultOptions}
          handleFileChange={handleFileChange}
          uploadPercentage={uploadPercentage}
          referenceDoc={referenceDoc}
          setReferenceDoc={setReferenceDoc}
          setReferenceDocId={setReferenceDocId}
          queryParams={queryParams}
          generatingDraft={generatingDraft}
        />
      )}
      <UpgradePopupTryPage />
      <SavedDataModalComponent />
      <SignupPopup />
      {showRequestLegalReviewAside && <LegalReviewRequestAside />}
    </>
  );
};

export default TryPage;
