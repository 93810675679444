import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import BaseLayout from "../layouts/BaseLayout";
import useValidatePageAccess from "../../hooks/useValidatePageAccess";
import TopNav from "./Navs/TopNav";
import DocIcon from "src/images/doc-icon.png";
import ChatBoxComponent from "./components/ChatBoxComponent";
import { useDispatch, useSelector } from "react-redux";
import { renderMessage } from "../../components/DocumentViewer/helpers";
import SavedDataModalComponent from "../../components/Prompts/SavedDataModalComponent";
import UpgradeToUnlockDocument from "./UpgradeToUnlockDocument";
import Lottie from "react-lottie";
import animationData from "src/lotties/Loader-animation";
import DocImage from "src/images/DocImage.png";

import {
  addInfoMessage,
  addMessage,
  getAskThreadMessages,
  resetIsChatFull,
  resetThreadData,
  setAskToDraftIntentWSResponse,
  setAwaitingCounter,
  setAwaitingDraftQuestionsResponse,
  setAwaitingResponse,
  setChatInputMessage,
  setLastMessageId,
  setLegalReviewRequested,
  setMessagingDisabled,
  setSentences,
  setThreadTitle,
} from "./messageSlice";
import { resetChatPage } from "./chatSlice";
import { useWebSocketContext } from "../../WebsocketProvider";
import { resetData, resetReferenceDocDownloadUrl, setOpenUpgradeToProPopup } from "./threadsSlice";
import AskToDraftModal from "../../components/chat/Modals/AskToDraftModal";
import DraftDocumentQuestionaireModal from "./Modals/DraftDocumentQuestionaireModal";
import LegalReviewRequestAside from "../LawyerHub/LegalReviewRequestAside/index.js";
import UpSellFlowPopup from "../LawyerHub/UpsellFlowPopup";
import { ArrowBendDoubleUpRight, ArrowDown, ArrowRight, Briefcase } from "@phosphor-icons/react";
import {
  countInputMessageWords,
  getFirstTenkWords,
  legalReviewAction,
  openPricingModal,
  setCookie,
  trackAnalytics,
} from "../helpers";
import {
  resetRequestLegalReviewFailureReason,
  setCurrentPageLegalReviewStatus,
  setDocumentNameShortHand,
  setShowUpgradePlanPopupChatPage,
} from "../onboarding/onboardingSlice";
import { setSelectedParties } from "../Homepage/HomeSlice";
import { setWordCountPopup } from "./messageSlice";
import WordLimitPopup from "src/components/WordLimitPopup";
import ChatActionsModal from "src/components/chat/Modals/ChatActionsModal";
import { PLANS_V2 } from "src/components/Constants";
import UpgradeToProPopup from "./UpgradeToProPopup";
import ChatSummary from "./ChatSummary";
import applicationStore from "src/persistence/stores/RootStore";

const ChatPageComponent = () => {
  const dispatch = useDispatch();
  const { isLoaded } = useUser();
  let { threadType, chatId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);

  const [inputMessage, setInputMessage] = useState("");
  const [steps, setSteps] = useState([]);
  const [isScrollToBottomButtonVisible, setIsScrollToBottomButtonVisible] = useState(false);
  const [threadMessages, setThreadMessages] = useState([]);
  // const [sentences, setSentences] = useState([]);
  const [shimmerNotAllowed, setShimmerNotAllowed] = useState(false);
  const { sendMessage, createMessage, socketRef } = useWebSocketContext();

  const chatpageRef = useRef(null);
  const messageListRef = useRef(null);
  const bottomRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getThreadStatus = useSelector((state) => state.messageSlice.getThreadStatus);
  const awaitingResponse = useSelector((state) => state.messageSlice.awaitingResponse);
  const threads = useSelector((state) => state.threads.threads);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const email = useSelector((state) => state.homeslice.email);
  const showAskToDraftModal = useSelector((state) => state.messageSlice.showAskToDraftModal);
  const draftDocumentModalOpen = useSelector((state) => state.modalSlice.draftDocumentModalOpen);
  const showRequestLegalReviewAside = useSelector((state) => state.onboarding.showRequestLegalReviewAside);
  const openUpgradeToProPopup = useSelector((state) => state.threads.openUpgradeToProPopup);
  const showSubscriptionPopup = useSelector((state) => state.onboarding.showSubscriptionPopup);
  const showUpgradingPlan = useSelector((state) => state.onboarding.showUpgradingPlan);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
  const showFindBestLawyer = useSelector((state) => state.onboarding.showFindBestLawyer);
  const showMeetingConfirmation = useSelector((state) => state.onboarding.showMeetingConfirmation);
  const showNextSteps = useSelector((state) => state.onboarding.showNextSteps);
  const showScheduleMeeting = useSelector((state) => state.onboarding.showScheduleMeeting);
  const scroll = useSelector((state) => state.messageSlice.scroll);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const currentPageLegalReviewStatus = useSelector((state) => state.onboarding.currentPageLegalReviewStatus);
  const metadata = useSelector((state) => state.onboarding.userMetadata);
  const requestLegalReviewStatus = useSelector((state) => state.onboarding.requestLegalReviewStatus);
  const submittingQnA = useSelector((state) => state.messageSlice.submittingQnA);
  const regenerationHappening = useSelector((state) => state.messageSlice.regenerationHappening);
  const userConfig = useSelector((state) => state.onboarding.userConfig);
  const markLegalReviewCallScheduledStatus = useSelector(
    (state) => state.onboarding.markLegalReviewCallScheduledStatus
  );
  const isChatFull = useSelector((state) => state.messageSlice.isChatFull);

  useEffect(() => {
    if (requestLegalReviewStatus === "success" || markLegalReviewCallScheduledStatus === "success") {
      dispatch(
        addMessage({
          new_messages: [{ message_type: "legal_review_message" }],
        })
      );
    }
  }, [requestLegalReviewStatus, markLegalReviewCallScheduledStatus]);

  useEffect(() => {
    dispatch(resetRequestLegalReviewFailureReason());
    dispatch(resetChatPage());
    dispatch(resetData());
    dispatch(resetThreadData());
    dispatch(setSelectedParties({}));
    dispatch(getAskThreadMessages(chatId));
    dispatch(setAskToDraftIntentWSResponse({}));
    dispatch(setAwaitingResponse(false));
    dispatch(setMessagingDisabled(false));
    dispatch(setAwaitingDraftQuestionsResponse(false));
    dispatch(resetReferenceDocDownloadUrl());
    dispatch(resetIsChatFull());

    setSteps([
      "Analyzing your request",
      `Reviewing account info on ${metadata?.entity_info?.name || metadata?.individual_info?.first_name}`,
      "Preparing response",
    ]);

    getCurrentLegalReviewStatus();

    // dispatch(getThread(chatId));
    if (queryParams) {
      var next = queryParams.get("next");
      var open_pro = queryParams.get("open_pro");
      var open_counsel = queryParams.get("open_counsel");
      if (next === "pro" || open_pro === "true") {
        // dispatch(
        //   setShowUpgradePlanPopupChatPage({ show: true, plans: PLANS_V2.subscriber_business, intent: "inhouse_pro" })
        // );
        openPricingModal(
          dispatch,
          metadata,
          email,
          "chat",
          "chat:unlock_document_button_clicked",
          PLANS_V2.subscriber_business,
          setOpenUpgradeToProPopup
        );
      } else if (next === "counsel" || open_counsel === "true") {
        legalReviewAction(dispatch, email, metadata, threadData);
        // dispatch(
        //   setShowUpgradePlanPopupChatPage({
        //     show: true,
        //     plans: PLANS_V2.subscriber_enterprise,
        //     intent: "inhouse_counsel",
        //   })
        // );
      }
    }
  }, [chatId]); // eslint-disable-line

  useEffect(() => {
    if (
      queryParams.get("upgrade") === "true" &&
      threadData?.id &&
      threadData?.messages?.filter((message) => message.message_type === "doc-unlocked").length === 0
    ) {
      dispatch(addInfoMessage());
    }
  }, [queryParams, threadData]); // eslint-disable-line

  useEffect(() => {
    setInputMessage("");
  }, [chatId]); // eslint-disable-line

  useEffect(() => {
    if (threadData?.messages?.length > 6) {
      scrollContainerToBottom(messageListRef);
    }
  }, [scroll, threadData.messages]); // eslint-disable-line

  useEffect(() => {
    if (threadData.messages?.length > 1) {
      if (threadData?.document?.url || threadData?.document?.google_doc_id) {
        setSteps(["Analyzing your request", "Conducting legal research", "Updating your document"]);
      } else {
        setSteps(["Analyzing your request", "Conducting legal research ", "Preparing response"]);
      }
      var uniqueMessages = threadData.messages.filter(
        (message, index, self) =>
          index ===
          self.findIndex(
            (t) => t.text === message.text && t.message_type === message.message_type && t.id === message.id
          )
      );
      setThreadMessages(uniqueMessages);
      let lastMessageId = null;
      for (let i = uniqueMessages.length - 1; i >= 0; i--) {
        if (
          uniqueMessages[i].id !== null &&
          uniqueMessages[i].id !== "" &&
          uniqueMessages[i].message_type !== "regenerate_ai_response"
        ) {
          lastMessageId = uniqueMessages[i].id;
          break;
        }
      }

      if (lastMessageId !== null) {
        dispatch(setLastMessageId(lastMessageId));
      }
    } else {
      setThreadMessages(threadData.messages);
      let lastMessageId = null;
      for (let i = threadData?.messages?.length - 1; i >= 0; i--) {
        if (
          threadData.messages[i]?.id !== null &&
          threadData.messages[i]?.id !== "" &&
          threadData.messages[i]?.message_type !== "regenerate_ai_response"
        ) {
          lastMessageId = threadData.messages[i].id;
          break;
        }
      }

      if (lastMessageId !== null) {
        dispatch(setLastMessageId(lastMessageId));
      }
    }
    if (threadData?.messages && threadData?.messages.length > 0) {
      var lastMessageType = threadData.messages[threadData.messages.length - 1].message_type;
      if (lastMessageType === "draft_qna_message") {
        dispatch(setSentences(["Provide answers to the questions above to continue"]));
        dispatch(setMessagingDisabled(true));
        if (submittingQnA) {
          setShimmerNotAllowed(false);
        } else {
          setShimmerNotAllowed(true);
        }
      } else if (lastMessageType === "regenerate_ai_response") {
        dispatch(
          setSentences([
            "Unfortunately the AI wasn't able to handle your last response, use the regenerate button above to try again",
          ])
        );
        dispatch(setMessagingDisabled(true));
        if (regenerationHappening) {
          setShimmerNotAllowed(false);
        } else {
          setShimmerNotAllowed(true);
        }
      } else {
        dispatch(setSentences(["Message Inhouse"]));
        setShimmerNotAllowed(false);
      }
    }
  }, [scroll, threadData.messages, getThreadStatus, window.location.href]); // eslint-disable-line

  useEffect(() => {
    getCurrentLegalReviewStatus();
  }, [lawyerHubData]); // eslint-disable-line

  useEffect(() => {
    if (getThreadStatus === "success") {
      var results = [];
      Object.keys(threads).forEach((key) => {
        const filtered = threads[key].filter((item) => {
          return item.id === chatId;
        });
        if (filtered.length > 0) {
          results = results.concat(filtered);
        }
      });
      if (results.length > 0) {
        dispatch(setThreadTitle(results[0].title));
      }
    }
  }, [getThreadStatus, threads]); // eslint-disable-line

  useValidatePageAccess();

  const onsubmit = () => {
    const wordCount = countInputMessageWords(inputMessage);
    if (wordCount <= 10000) {
      trackAnalytics(
        `${threadData.thread_type} thread: additional message`,
        {
          user_email: email,
          thread_id: chatId,
          message: inputMessage,
        },
        metadata
      );
      // window.analytics.track(`${threadData.thread_type} thread: additional message`, {
      //     "user_email": email, "thread_id": chatId, "message": inputMessage
      // })
      dispatch(setChatInputMessage(inputMessage));
      var message = createMessage(threadType, "add-message", { message: inputMessage, thread_id: chatId });
      sendWsMessage(message);

      dispatch(setMessagingDisabled(true));
    } else {
      dispatch(setWordCountPopup(true));
    }
  };

  const viewDocument = () => {
    trackAnalytics(`${threadData.thread_type} thread: view document on thread`);
    var url = threadData?.document.url
      ? threadData?.document?.url
      : `https://docs.google.com/document/d/${threadData?.document?.google_doc_id}`;
    window.open(url, "_blank");
  };
  const sendWsMessage = (message) => {
    console.log("sending ws message");
    const awaitingCounter = applicationStore.getState().messageSlice.awaitingCounter;
    if (awaitingCounter > 20) {
      setCookie("alreadyReadInput", "", 1);
      window.location.reload();
    }
    dispatch(setMessagingDisabled(true));
    dispatch(setAwaitingResponse(true));
    if (socketRef?.current?.readyState !== 1) {
      console.log("sending ws message awaitingCounter= ", awaitingCounter);
      dispatch(setAwaitingCounter(awaitingCounter + 1));
      setTimeout(() => {
        sendWsMessage(message);
      }, 1000);
    } else {
      sendMessage(message, true);
      setInputMessage("");
    }
  };
  const scrollContainerToBottom = (messageListRef) => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop += 150;
    }
  };

  const onSumbitWithTenThousandWords = () => {
    const inpMessage = getFirstTenkWords(inputMessage);
    trackAnalytics(
      `${threadData.thread_type} thread: additional message`,
      {
        user_email: email,
        thread_id: chatId,
        message: inputMessage,
      },
      metadata
    );
    dispatch(setChatInputMessage(inpMessage));
    var message = createMessage(threadType, "add-message", { message: inputMessage, thread_id: chatId });
    sendWsMessage(message);
    dispatch(setMessagingDisabled(true));
  };

  const getCurrentLegalReviewStatus = () => {
    if (lawyerHubData) {
      var legalReview = lawyerHubData?.find((item) => item.thread_id === threadData.id);
      if (
        legalReview &&
        threadData.messages.find((item) => item.message_type === "legal_review_message") === undefined
      ) {
        dispatch(setLegalReviewRequested(true));
      }
      dispatch(setCurrentPageLegalReviewStatus(legalReview));
    }
  };

  useEffect(() => {
    // console.log(messageListRef.current, bottomRef.current, getThreadStatus)
    if (!messageListRef.current || !bottomRef.current || getThreadStatus !== "success") return;
    console.log("Setting observer");
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update visibility state
        setIsScrollToBottomButtonVisible(!entry.isIntersecting);
      },
      {
        root: messageListRef.current, // The scrollable container
        rootMargin: "0px 0px 100px 0px", // 100px from the bottom
        threshold: 0, // Trigger if even 1px is visible
      }
    );

    observer.observe(bottomRef.current);

    // Cleanup on component unmount
    return () => observer.disconnect();
  }, [getThreadStatus, messageListRef.current, bottomRef.current]);

  return (
    <>
      {isLoaded ? (
        <BaseLayout>
          <div className="flex flex-col w-[100%]">
            <WordLimitPopup onProceed={onSumbitWithTenThousandWords} />
            <div className="flex flex-col justify-between h-screen">
              <TopNav page="chat"></TopNav>
              {/* {JSON.stringify(threadData)} */}
              <div className="h-[57px] w-full flex md:hidden"></div>
              {getThreadStatus === "loading" && threadMessages.length === 0 && (
                <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                  <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div>
                  <p className="text-black">Fetching thread data...</p>
                </div>
              )}
              {getThreadStatus === "failed" && threadMessages.length === 0 && (
                <div className="w-full h-full flex flex-col justify-center items-center gap-[8px]">
                  {/* <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div> */}
                  <p className="text-center text-black">
                    Failed to fetch thread data. <br /> Please refresh the page and try again
                  </p>
                </div>
              )}

              {(getThreadStatus === "success" || threadMessages) && (
                <>
                  <div
                    className="py-[24px] mx-auto overflow-y-auto gap-[12px] justify-end flex-1  w-full"
                    id="scrolling_div"
                    ref={messageListRef}
                  >
                    {threadMessages.map((message, index) => (
                      <div className="px-3 md:px-0 md:w-[736px] mx-auto">
                        {renderMessage(
                          message,
                          index,
                          threadData,
                          "full",
                          email,
                          threadType,
                          false,
                          setSteps,
                          userConfig
                        )}
                      </div>
                    ))}

                    {threadData?.document?.id && !threadData?.document?.isLocked && !awaitingResponse && (
                      <>
                        <div className="flex flex-col md:w-[736px] px-3 md:px-0 md:mx-auto">
                          <div
                            className="flex flex-col w-full  mx-auto gap-0 mt-4 cursor-pointer"
                            onClick={viewDocument}
                          >
                            <div className="rounded-t-[14px] flex flex-row w-full border border-[#E4E4E4] p-0">
                              <div className="left px-[24px] py-[12px] h-[56px]">
                                <img src={DocImage} alt="doc" className="w-[32px] h-[32px] object-contain" />
                              </div>
                              <div className="bg-white  right font-[600] text-[16px] px-[24px] py-[14px] w-full rounded-b-0 rounded-r-[14px] my-auto h-[56px]">
                                {threadData?.document?.doc_title_shorthand}
                              </div>
                            </div>
                            <div className="relative w-full h-[500px] border border-[#E4E4E4]">
                              <iframe
                                src={`https://docs.google.com/document/d/${threadData?.document?.id}/preview`}
                                className="absolute top-0 left-0 w-full h-full"
                                title={threadData?.document?.doc_title_shorthand}
                              ></iframe>
                              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-white opacity-100">
                                <div className="absolute bottom-0 left-0 w-full flex justify-center p-4">
                                  <button
                                    target="_blank"
                                    rel="noreferrer"
                                    className="bg-[#1B2B48] text-white font-bold items-center justify-center rounded  flex flex-row w-[262px] h-[40px] gap-[12px]"
                                  >
                                    <span className="my-auto text-[14px]">View Document</span>{" "}
                                    <span className="my-auto">
                                      <ArrowRight size={16} className="text-white" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-col md:w-[736px] mx-auto mt-[24px] mb-[12px] gap-[12px] md:gap-[16px]">
                          <hr />
                          <div className="flex flex-row gap-[8px]">
                            <ArrowBendDoubleUpRight size={20} className="text-[#949494] scale-y-[-1]" />
                            <span className="text-[13px] md:text-[16px] text-[#949494] leading-[25.6px] font-[500]">
                              Suggested Actions
                            </span>
                          </div>
                          <button
                            className="cursor-pointer w-fit flex flex-row px-[16px] py-[8px] text-[#222222] rounded-[98px] border border-[#D1D1D1] bg-[#FFFFFF] text-[13px] md:text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                            onClick={() => {
                              if (threadData?.document?.doc_title_shorthand) {
                                dispatch(
                                  setDocumentNameShortHand(
                                    `Get your ${threadData?.document?.doc_title_shorthand || "document"} reviewed `
                                  )
                                );
                              } else {
                              }

                              legalReviewAction(dispatch, email, metadata, threadData);
                            }}
                          >
                            <Briefcase size={16} className="text-[#DF8A64] my-auto" />
                            <span className="my-auto">
                              {metadata?.lawyer_info?.name
                                ? `Consult ${metadata?.lawyer_info?.name}`
                                : `Have a lawyer finalize this ${
                                    threadData?.document?.doc_title_shorthand || "document"
                                  }`}
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                    {threadData?.document?.isLocked && (
                      <div className="px-3 md:px-0 md:w-[736px] mx-auto">
                        <UpgradeToUnlockDocument
                          threadData={threadData}
                          dispatch={dispatch}
                          metadata={metadata}
                          email={email}
                        />
                      </div>
                    )}

                    {awaitingResponse && !shimmerNotAllowed && (
                      <div className="mt-[12px] md:w-[736px] mx-auto px-3">
                        <div className=" flex flex-row justify-items-start gap-[10px] px-[14px]">
                          <div className="w-[32px]">
                            <Lottie options={defaultOptions} height={32} width={32} className="my-auto" />
                          </div>
                          <p className="text-[14px] font-[400] font-sans my-auto">
                            <span className="bg-gradient-to-r from-[#4F45F4] via-[#7E1586] to-[#DA6390] bg-clip-text text-transparent">
                              Analyzing your request...
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                    <p ref={bottomRef} className=""></p>
                  </div>
                  <div className="relative flex flex-col w-full">
                    {isScrollToBottomButtonVisible && (
                      <div
                        onClick={() => bottomRef.current.scrollIntoView({ behavior: "smooth" })}
                        className="absolute px-1 py-1 text-gray-500 -translate-x-1/2 border !border-gray-400 rounded-full cursor-pointer -top-4 bg-white left-1/2"
                      >
                        <ArrowDown size={16} />
                      </div>
                    )}
                    <ChatBoxComponent
                      size="full"
                      inputMessage={inputMessage}
                      setInputMessage={setInputMessage}
                      onsubmit={onsubmit}
                      // sentences={sentences?.length > 0 ? sentences : null}
                      privacyLink={true}
                      isChatFull={isChatFull}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {showAskToDraftModal && <AskToDraftModal />}

          {draftDocumentModalOpen && <DraftDocumentQuestionaireModal email={email} ref={chatpageRef} />}
          {showRequestLegalReviewAside && <LegalReviewRequestAside />}
          {(showSubscriptionPopup ||
            showUpgradingPlan ||
            showBusinessPlanUnlocked ||
            showFindBestLawyer ||
            showMeetingConfirmation ||
            showNextSteps ||
            showScheduleMeeting) && <UpSellFlowPopup />}
          {openUpgradeToProPopup && <UpgradeToProPopup />}
        </BaseLayout>
      ) : (
        <>
          {isLoaded ? (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
              {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
              {/* <div class="rotating-cursor !h-[50px]"></div> */}
              <p className="text-black text-[40px]">{isLoaded}</p>
            </div>
          )}
        </>
      )}
      <SavedDataModalComponent />
      <ChatActionsModal />
    </>
  );
};

export default ChatPageComponent;
