/* eslint-disable jsx-a11y/aria-role */
import { toast } from "react-toastify";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import BaseLayout from "../layouts/BaseLayout";
import useValidatePageAccess from "../../hooks/useValidatePageAccess";
import TopNav from "./Navs/TopNav";
import { LightbulbFilament } from "@phosphor-icons/react";
import ChatBoxComponent from "./components/ChatBoxComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getPresignedUrl,
  resetFileUploadProgress,
  resetPresignedUrl,
  resetRedirectTo,
  setAwaitingResponse,
  setMessagingDisabled,
  setNewThreadMessage,
  updateUploadedFileName,
  uploadFile,
} from "./messageSlice";
import { useWebSocketContext } from "../../WebsocketProvider";
import { setShowExploreMoreModal } from "../../modalSlice";
import ExploreMoreModalComponent from "../../components/chat/Modals/ExploreMoreModal";
import DraftDocumentQuestionaireModal from "./Modals/DraftDocumentQuestionaireModal";
import { trackAnalytics } from "../helpers";
import { setShowRequestLegalReviewAside } from "../onboarding/onboardingSlice";
import AskAvatar from "../../images/AskAvatar.svg";
const AskPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);

  var { message } = location.state || {};
  const draftDocumentModalRef = useRef(null);

  const { isLoaded } = useUser();
  const [inputMessage, setInputMessage] = useState(message || "");
  const [referenceDocId, setReferenceDocId] = useState(null);
  const [referenceDoc, setReferenceDoc] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [askSuggestions, setAskSuggestions] = useState([]);
  const { sendMessage, createMessage } = useWebSocketContext();

  const redirectTo = useSelector((state) => state.messageSlice.redirectTo);
  const presignedUrl = useSelector((state) => state.messageSlice.presignedUrl);
  const email = useSelector((state) => state.homeslice.email);
  const allSuggestions = useSelector((state) => state.homeslice.allSuggestions);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  const { fileuploadError } = useSelector((state) => state.messageSlice);

  useEffect(() => {
    dispatch(setMessagingDisabled(false));
    dispatch(setAwaitingResponse(false));
    dispatch(resetPresignedUrl());
    dispatch(resetFileUploadProgress());
    dispatch(setShowRequestLegalReviewAside(false));
    trackAnalytics("ask_homepage_visited", { user_email: email }, userMetadata);
    if (queryParams.get("clear") === "true") {
      setInputMessage("");
      navigate("/ask");
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (allSuggestions && allSuggestions?.ask_examples?.length > 0) {
      var askExamples = allSuggestions.ask_examples
        .map((value) => ({ ...value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map((value) => value)
        .slice(0, 3);
      setAskSuggestions(askExamples);
    }
  }, [allSuggestions]); // eslint-disable-line

  useEffect(() => {
    if (queryParams.get("clear") === "true") {
      setInputMessage("");
      navigate("/ask");
    }
  }, [queryParams]); // eslint-disable-line

  useEffect(() => {
    if (fileuploadError) {
      toast.error(`File Upload failed. Please try again.`);
      setUploadPercentage(0);
      setReferenceDocId(null);
      setReferenceDoc(null);
    }
  }, [fileuploadError]); // eslint-disable-line

  useEffect(() => {
    if (uploadPercentage === 100) {
      setReferenceDocId(presignedUrl?.storage_id);
    }
  }, [uploadPercentage]); // eslint-disable-line

  useEffect(() => {
    setInputMessage(message);
  }, [message]); // eslint-disable-line

  useEffect(() => {
    if (redirectTo) {
      var redirectUrl = redirectTo;
      dispatch(resetRedirectTo(null));
      navigate(redirectUrl);
    }
  }, [redirectTo]); // eslint-disable-line

  useEffect(() => {
    if (referenceDoc && presignedUrl) {
      var filename = referenceDoc.name;
      dispatch(updateUploadedFileName({ filename: filename, storage_id: presignedUrl?.storage_id }));
      trackAnalytics("ask thread: reference doc uploaded", { user_email: email, filename: filename }, userMetadata);
      // window.analytics.track("ask thread: reference doc uploaded", { "user_email": email, "filename": filename });
      dispatch(
        uploadFile({
          file: referenceDoc,
          data: presignedUrl.upload_fields,
          url: presignedUrl.url,
          onUploadProgress: (progress) => {
            setTimeout(() => {
              setUploadPercentage(progress);
            }, 3000);
          },
        })
      );
    }
  }, [presignedUrl]); // eslint-disable-line
  useValidatePageAccess();

  const onsubmit = () => {
    trackAnalytics("new ask thread. sending first message", { user_email: email, message: inputMessage }, userMetadata);
    trackAnalytics(
      "dashboard_ask_send_chat_button_clicked",
      { user_email: email, message: inputMessage },
      userMetadata
    );
    // window.analytics.track("new ask thread. sending first message", { "user_email": email, "message": inputMessage })
    // if (isUploading) {
    //   toast.error(`File Upload is in progress.`);
    // }
    dispatch(setNewThreadMessage(inputMessage));
    var docId = referenceDocId;
    if (uploadPercentage === 100 && referenceDoc) {
      docId = referenceDocId;
    } else {
      docId = null;
    }
    var message = createMessage("ask", "create-thread", { message: inputMessage, reference_doc_storage_id: docId });
    var response = sendMessage(message, true);
    if (!response) {
      dispatch(setMessagingDisabled(true));
    }
  };

  const exploreAllClicked = () => {
    trackAnalytics("Explore all clicked (askpage)", { user_email: email }, userMetadata);
    // window.analytics.track("Explore all clicked (homepage)", { "user_email": email });
    dispatch(setShowExploreMoreModal(true));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      // toast.error("No file selected.");
      return;
    }
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (["docx", "pdf"].includes(fileExtension)) {
      // console.log("Valid file selected:", file);
      dispatch(getPresignedUrl());
      dispatch(resetFileUploadProgress());
      setUploadPercentage(0);
      setReferenceDoc(file);
    } else {
      toast.error("Invalid file format. Only docx and pdf are allowed.");
      setReferenceDoc(null);
    }
  };

  const getCategoryName = (categoryId) => {
    var category = allSuggestions?.categories?.find((category) => category.id === categoryId);
    return category?.name;
  };

  return (
    <>
      {isLoaded ? (
        <BaseLayout>
          <div className="flex flex-col w-screen h-screen">
            <div className="flex flex-col justify-between w-full h-[80%] md:pb-[56px] md:pt-0">
              <TopNav page="ask_home"></TopNav>
              <div className="ask_page_central_content flex flex-col my-auto gap-y-[32px]">
                <div className="w-full flex flex-col gap-[24px] justify-center items-center md:hidden h-[318px]">
                  <img src={AskAvatar} alt="AI" className="w-[32px] h-[32px]" />
                  <p className="font-medium text-[24px] leading-[33px] text-center">
                    Ask Inhouse AI <br />
                    your legal queries
                  </p>
                </div>
                <h3 className="justify-center md:flex flex-row gap-[10px] hidden">
                  <LightbulbFilament size={24} />
                  <span>Quick suggestions to get started</span>
                </h3>
                <div className="flex flex-row w-full overflow-y-scroll px-[12px]">
                  <div className="mx-auto flex flex-row justify-between gap-[16px] ">
                    {askSuggestions.slice(0, 3).map((suggestion, index) => (
                      <div
                        className="cursor-pointer flex flex-col w-[245px] md:w-[261px] bg-[#F7F7F7] md:bg-white rounded-[8px] border gap-[10px] px-[26px] py-[14px]"
                        onClick={() => {
                          setInputMessage(suggestion.actual_prompt);
                        }}
                      >
                        <h4 className="text-[10px] md:text-[12px] md:leading-[28px] text-[#686868] uppercase font-[500] tracking-[2%]">
                          {getCategoryName(suggestion.category_id)}
                        </h4>
                        <p className="text-[12px] w-full font-[500] md:text-[16px] md:leading-[28px]">
                          {suggestion.display_prompt}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <p
                  className="text-center text-[#C61DCA] text-[12px] hidden md:block font-[600] uppercase cursor-pointer"
                  onClick={exploreAllClicked}
                >
                  Explore More
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full md:pb-[56px]">
              <ChatBoxComponent
                size="full"
                allowAttachReference={true}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                onsubmit={onsubmit}
                referenceDoc={referenceDoc}
                handleFileChange={handleFileChange}
                percentage={uploadPercentage}
                setReferenceDoc={setReferenceDoc}
                fileuploadSuccess={uploadPercentage}
              />
            </div>
          </div>

          <DraftDocumentQuestionaireModal email={email} ref={draftDocumentModalRef} />
          <ExploreMoreModalComponent />
        </BaseLayout>
      ) : (
        <>
          {isLoaded ? (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
              {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
              {/* <div class="rotating-cursor !h-[50px]"></div> */}
              <p className="text-black text-[40px]">{isLoaded}</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AskPage;
