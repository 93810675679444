import React, { useEffect, useState } from "react";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import { formatText } from "../helpers";
import { OpenAiLogo } from "@phosphor-icons/react";

const LawGptSummary = (props) => {
  const { chatInfo } = props;
  const [shortMessage, setShortMessage] = useState(null);
  const [showCollapse, setShowCollapse] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const words = chatInfo?.payload?.text ? chatInfo?.payload?.text.split(" ") : [];
    setShortMessage(words.slice(0, 250).join(" ") + (words.length > 250 ? "..." : ""));
    setShowCollapse(words.length > 250);
  }, [chatInfo?.text]);

  return (
    <div className="flex flex-col gap-[12px] bg-white rounded-[12px] border-[#E4E4E4] border-[1px] pt-[12px] pb-[16px] px-[16px]">
      <p className="flex flex-row gap-[8px]">
        <span className="">
          <OpenAiLogo size={20} />
        </span>
        <span className="text-[14px] font-[500]">Imported from LawGPT</span>
      </p>
      <div className="flex gap-[4px] bg-[#F2F2F280] pb-[16px] px-[12px]">
        <div className="chat">
          <Markdown remarkPlugins={[remarkGfm]}>
            {expanded ? formatText(chatInfo?.payload?.text) : formatText(shortMessage)}
          </Markdown>
          {showCollapse && (
            <span className="text-blue-500 text-[14px] cursor-pointer underline" onClick={() => setExpanded(!expanded)}>
              {expanded ? "See less" : "See more"}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default LawGptSummary;
