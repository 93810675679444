import React from "react";
import Button from "../Button";
import { Check } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { PLANS } from "src/components/Constants";
import { trackAnalytics } from "src/pages/helpers";

const SubscriptionCard = (props) => {
  const { data, billCycle, PLAN_TYPES, setPlan, checkoutUrlStatus } = props;

  const {
    name,
    planPublicName,
    borderClassName,
    icon,
    pricing,
    paymentFrequency,
    paymentFrequencyText,
    featuresHeading,
    features,
    btnTitle,
    buttonClass,
    paymentPlanDisabled,
    paymentPlanName,
    iconSize,
  } = data;

  const metadata = useSelector((state) => state.onboarding.userMetadata);
  const email = useSelector((state) => state.homeslice.userEmail);
  const [title, setTitle] = React.useState("");
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  const buttonClick = () => {
    trackAnalytics("SubscriptionCard", { user_email: email, action: "Upgrade Plan", plan: planPublicName }, metadata);
    setPlan(paymentPlanName);
  };

  React.useEffect(() => {
    if (metadata.subscription_type === paymentPlanName) {
      setTitle("Current Plan");
      setIsButtonDisabled(true);
    } else {
      setTitle("Upgrade Now");
      setIsButtonDisabled(false);
    }
  }, [metadata]);

  return (
    <div
      className={`overflow-hidden w-full md:w-[440px] rounded-xl ${
        borderClassName ? "border-[1.5px] selected-plan-border" : "border"
      } relative pb-10`}
    >
      {/* {badge.show && <div className={`absolute top-0 right-0 text-[10px] font-bold rounded-bl-xl py-2 px-4 bg-[#1B2B48] uppercase`}></div>} */}
      <div className={`flex flex-col gap-[12px] px-6 pt-6 pb-3 border-bottom`}>
        <div className="flex md:flex-col gap-[12px]">
          <div className="h-[52px] flex items-center">
            <img src={icon} className={`${iconSize}`} />
          </div>
          {name}
        </div>
        <div>
          <p className="text-[#011513] font-[lora] text-[36px] leading-[50px]  font-bold">
            {pricing}{" "}
            <span className="font-normal text-[20px] leading-[44.8px] text-[#353535]">{paymentFrequency}</span>{" "}
          </p>
          <p className="text-[#686868] text-[10px] font-normal">{paymentFrequencyText}</p>
        </div>
      </div>
      <div className="px-4 mt-6">
        <p className="font-semibold text-[14px] text-[#444444]">{featuresHeading}</p>
        <ul className="pb-5">
          {features?.map((feature) => (
            <li
              className={`text-sm  mt-3 font-normal flex items-center gap-[10px] text-[#353535] ${
                feature && "!font-bold"
              }`}
            >
              {" "}
              <span className="w-[16px] h-[16px] rounded-[48px]">
                <Check
                  size={14}
                  className={`mr-2 ${
                    name === "Ask Inhouse" ? "bg-[#FAFAFA]" : "bg-[#ECF5F3] text-[#248370]"
                  } rounded-full p-[2px]`}
                />
              </span>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="absolute bottom-4 w-full px-[10px] py-[10px]">
        {btnTitle && (
          <div className="">
            <Button
              disabled={checkoutUrlStatus === "loading" || isButtonDisabled}
              text={title}
              buttonClass={buttonClass}
              onClick={() => {
                buttonClick();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
