import React, { useEffect, useState } from "react";
// import copy from "../../images/copy.svg";
import { ArrowBendDoubleUpRight, Briefcase, PenNib } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import AskAvatar from "../../images/AskAvatar.png";
import DraftAvatar from "../../images/DraftAvatar.png";
import NewAvatar from "../../images/NewAvatar.svg";
import { setAskToDraftIntentWSResponse, setShowAskToDraftModal } from "../../pages/chatpage/messageSlice";
import {
  setDocumentNameShortHand,
  setReferralCategory,
  setReferralCategoryText,
  setShowRequestLegalReviewAside,
} from "src/pages/onboarding/onboardingSlice";
import { legalReviewAction, trackAnalytics } from "src/pages/helpers";
import MessageRender from "./MessageRender";
import FileAttachmentPrompt from "../Prompts/FileAttachmentPrompt";
import { useWebSocketContext } from "src/WebsocketProvider";
import { PLANS } from "../Constants";
// import { legalReviewButtonClickedIntent } from "src/pages/onboarding/onboardingSlice";
// import ChangeParty from "../Prompts/ChangeParty";
// import ReferenceThreadPrompt from "../Prompts/ReferenceThreadPrompt";

const ChatMessage = ({
  index,
  isAIReply,
  chatInfo,
  message_type,
  isLastReply,
  chatId,
  selectedThread,
  legalReviewRequested,
  isFirstAiMessage,
  sharedpage,
  threadData,
  userConfig,
}) => {
  // const currentDate = new Date(chatInfo?.created_at);
  // const options = {
  //     month: "short",
  //     day: "numeric",
  //     year: "numeric",
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //     timeZoneName: "short",
  // };

  // const [animate, setAnimate] = useState(false);
  const [buttonsToShow, setButtonsToShow] = useState([]);
  const [showLegalReviewRequestButton, setShowLegalReviewRequestButton] = useState(true);
  const [showTemplateButton, setShowTemplateButton] = useState(true);
  const [showHyperLinkButton, setShowHyperLinkButton] = useState(true);
  const [threadType, setThreadType] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [threadMessages, setThreadMessages] = useState([]);

  const [message, setMessage] = useState(chatInfo?.payload?.text || chatInfo?.payload?.message_text);

  const dispatch = useDispatch();

  const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
  const templateSaveStatus = useSelector((state) => state.chat.templateSaveStatus);
  const email = useSelector((state) => state.homeslice.email);
  const changesAccepted = useSelector((state) => state.chat.changesAccepted);
  const lastConversationalMessage = useSelector((state) => state.chat.lastConversationalMessage);
  const saveAsTemplateChats = useSelector((state) => state.chat.saveAsTemplateChats);
  const legalReviewRequestedChats = useSelector((state) => state.chat.legalReviewRequestedChats);
  const viewChatLinks = useSelector((state) => state.chat.viewChatLinks);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const draftTypeSuggestions = useSelector((state) => state.homeslice.draftTypes);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  const { sendMessage, createMessage } = useWebSocketContext();

  const lawyerName = "Consult " + userMetadata?.lawyer_info?.name;

  useEffect(() => {
    if (message_type === "ask_message") {
      setThreadType("ask");
      setAvatar(AskAvatar);
    } else if (message_type === "draft_message") {
      setThreadType("draft");
      setAvatar(DraftAvatar);
    }
    setAvatar(NewAvatar);
  }, [message_type]);

  useEffect(() => {
    setShowHyperLinkButton(viewChatLinks[viewChatLinks.length - 1] === chatInfo.id);
    updatebuttonsToShow();
  }, [viewChatLinks]); // eslint-disable-line

  useEffect(() => {
    setShowTemplateButton(saveAsTemplateChats[saveAsTemplateChats.length - 1] === chatInfo.id);
    updatebuttonsToShow();
  }, [saveAsTemplateChats]); // eslint-disable-line

  useEffect(() => {
    var show = legalReviewRequestedChats[legalReviewRequestedChats.length - 1] === chatInfo.id;
    setShowLegalReviewRequestButton(show);
    updatebuttonsToShow();
  }, [legalReviewRequestedChats]); // eslint-disable-line

  useEffect(() => {
    const newAiMessage = localStorage.getItem("newAiMessage");
    if (newAiMessage && newAiMessage === chatInfo.id && isAIReply) {
      // setAnimate(true);
      localStorage.removeItem("newAiMessage");
    }
  }, [getThreadStatus]); // eslint-disable-line

  const getCategoryName = (categoryId) => {
    var category = draftTypeSuggestions?.find((draft) => draft.id === categoryId);
    return category?.name || category?.category;
  };

  const hasAcceptEditSuggestionsButton = () => {
    if (chatInfo?.payload?.quick_action_buttons?.length > 0) {
      return chatInfo?.payload?.quick_action_buttons.some((btn) => btn.type === "accept_edit_suggestions");
    }
    return false;
  };
  const showQuickActionButton = (btn) => {
    if (btn.type === "accept_edit_suggestions" || btn.type === "quick_response") {
      if (isLastReply) {
        return true;
      }
      return false;
    }
    if (btn.type === "legal_review_request") {
      var legalReview = lawyerHubData?.find((item) => item.thread_id === chatId);
      if (legalReview) {
        return false;
      }
      if (showLegalReviewRequestButton) {
        if (legalReviewRequested) {
          return false;
        }
        // if this is the last conversational message, then show the legal review button
        if (showLegalReviewRequestButton) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    }
    if (btn.type === "hyperlink" && showHyperLinkButton) {
      return true;
    }
    if (btn.type === "save_as_template") {
      if (selectedThread?.template) {
        return false;
      }
      if (showTemplateButton) {
        if (templateSaveStatus !== "success") {
          return true;
        }
      }
    }

    if (isLastReply || lastConversationalMessage === chatInfo.id) {
      if (changesAccepted) {
        return true;
      }
      if (hasAcceptEditSuggestionsButton()) {
        return false;
      }
      return true;
    }
  };

  const showLegalButton = () => {
    var legalReview = lawyerHubData?.find((item) => item.thread_id === chatId);
    if (legalReview) {
      return false;
    }
    return true;
  };
  const updatebuttonsToShow = () => {
    if (chatInfo?.payload?.quick_action_buttons?.length > 0) {
      var buttons = [];
      chatInfo?.payload?.quick_action_buttons.map((btn) => {
        if (showQuickActionButton(btn)) {
          buttons.push(btn);
        }
      });
      setButtonsToShow(buttons);
      return [];
    }
  };

  const openReferralDrawer = (btn) => {
    trackAnalytics(
      "chat:legal_review_referral_request_clicked",
      { user_email: email, action: "referral", chatId: chatId, text: btn.text },
      userMetadata
    );
    dispatch(setReferralCategory(btn.referral_category));
    dispatch(setShowRequestLegalReviewAside(true));
    dispatch(setReferralCategoryText(btn.text));
  };

  const openDraftIntent = (btn) => {
    // 3 types of plans :free, business, enterprise
    // enterprise: no restrictions, user will perform the action. in this case, open ask to draft intent
    // subscriber / free : check if threads_remaining is 0, if yes, open popup, else open ask to draft intent

    trackAnalytics(
      "dashboard_ask_quick_action_button_clicked",
      { user_email: email, action: "draft", chatId: chatId, text: btn.text },
      userMetadata
    );

    var message = createMessage("chat", "add-message", {
      thread_id: threadData?.id,
      convert_to_draft: true,
      button_click_type: null,
      message: btn.text,
    });
    sendMessage(message);

    // dispatch(setAskToDraftIntentWSResponse({}));
    // dispatch(setAskToDraftIntentWSResponse({}));
    // dispatch(setShowAskToDraftModal({ show: true, intent: { draft_prompt: btn.text, ask_thread_id: threadData.id } }));
  };

  useEffect(() => {
    var uniqueMessages = threadData.messages.filter(
      (message, index, self) =>
        index ===
        self.findIndex((t) => t.text === message.text && t.message_type === message.message_type && t.id === message.id)
    );
    setThreadMessages(uniqueMessages);
  }, [threadData?.messages]);

  useEffect(() => {
    updatebuttonsToShow();
  }, []);

  return (
    <div className="flex flex-col max-w-full w-screen md:w-full px-0">
      <div className="flex flex-col w-[100%]">
        {index === 1 && threadData?.reference_doc?.file_name && (
          <FileAttachmentPrompt referenceDoc={threadData.reference_doc} threadData={threadData}></FileAttachmentPrompt>
        )}
        <MessageRender
          message={message}
          index={index}
          userType={chatInfo?.is_user_message ? "user" : "ai"}
          avatar={NewAvatar}
          isFirstAiMessage={isFirstAiMessage}
          sharedpage={sharedpage}
          chatInfo={chatInfo}
          userMetadata={userMetadata}
          threadMessages={threadMessages}
        />
      </div>
      {/* TODO: i think these qab should only be visible if this is the last message */}
      {!sharedpage && (
        <>
          {chatInfo?.payload?.quick_action_buttons?.length > 0 && index === threadMessages.length - 1 && (
            <div className="flex flex-col my-[12px] gap-[12px] md:gap-[16px]">
              <hr />
              <div className="flex flex-row gap-[8px]">
                <ArrowBendDoubleUpRight size={20} className="text-[#949494] scale-y-[-1]" />
                <span className="text-[13px] md:text-[16px] text-[#949494] leading-[25.6px] font-[500]">
                  Suggested Actions
                </span>
              </div>
              <div className="flex gap-[12px] flex-wrap">
                {chatInfo?.payload?.quick_action_buttons.map((btn, index) => (
                  <>
                    {btn.type === "legal_review_request" && showLegalButton() && (
                      <button
                        key={index}
                        className="cursor-pointer w-fit flex flex-row px-[16px] py-[8px] text-[#222222] rounded-[98px] border border-[#D1D1D1] bg-[#FFFFFF] text-[13px] md:text-[14px] leading-[19.6px] font-[500] gap-[8px]"
                        onClick={() => {
                          if (btn.referral_category && userMetadata?.subscription_type !== PLANS.INHOUSE_COUNSEL) {
                            if (
                              btn.referral_category === "criminal" ||
                              btn.referral_category === "business_litigation" ||
                              btn.referral_category === "accidents_and_injuries"
                            ) {
                              openReferralDrawer(btn);
                              return;
                            }
                          }
                          if (threadData?.document?.doc_title_shorthand) {
                            dispatch(
                              setDocumentNameShortHand(
                                `Get your  ${threadData?.document?.doc_title_shorthand || "document"} reviewed `
                              )
                            );
                          } else {
                            dispatch(setDocumentNameShortHand(btn.text));
                          }
                          legalReviewAction(dispatch, email, userMetadata, threadData);
                        }}
                      >
                        <Briefcase size={16} className="text-[#DF8A64] my-auto" />
                        <span className="my-auto">{userMetadata?.lawyer_info?.name ? lawyerName : btn.text}</span>
                      </button>
                    )}
                    {btn.type === "draft" && (
                      <button
                        key={index}
                        onClick={() => {
                          openDraftIntent(btn);
                        }}
                        className="cursor-pointer w-fit flex flex-row px-[16px] py-[8px] bg-[#FFFFFF] rounded-[98px] border border-[#D1D1D1] text-[#222222] text-[13px] md:text-[14px] leading-[19.6px] font-sans font-[500] gap-[8px]"
                      >
                        <PenNib size={16} className="text-[#248370] my-auto" />
                        <span className="my-auto">{btn.text}</span>
                      </button>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatMessage;
