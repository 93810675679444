import { useAuth, useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useValidatePageAccess from "src/hooks/useValidatePageAccess";
import { createUserAPI, getUserMetadata, setShowUpgradePlanNudge } from "../onboarding/onboardingSlice";
import { identifyAnalytics } from "../helpers";
import { Warning } from "@phosphor-icons/react";

const PostAuthOption2 = () => {
  // post auth option 1 vs option 2
  // option 1: the first call goes to the backend to create the user. If the user is already created, then metadata is checked
  // option 2: we get metadata first. if response is 401, user is not created. call createUserAPI.
  // if response is 200, user is created. check if user has parties. if yes, redirect to homepage. if no, redirect to onboarding.
  useValidatePageAccess();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const next = queryParams.get("next");

  const [createUserAttemptCount, setCreateUserAttemptCount] = useState(0);
  const [accessTokenValue, setAccessTokenValue] = useState(null);

  const userMetadataFetchStatus = useSelector((state) => state.onboarding.userMetadataFetchStatus);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  // const userIdentified = useSelector((state) => state.onboarding.userIdentified);

  const { user, isSignedIn } = useUser();
  const { getToken } = useAuth();

  const getMyToken = async () => {
    const token = await getToken({ template: "Backend" });
    return token;
  };
  const getTokenValue = async () => {
    const token = await getMyToken();
    if (token && typeof token === "string") {
      localStorage.setItem("accessToken2", token);
      setAccessTokenValue(token);
    }
  };

  const clearTryData = () => {
    localStorage.removeItem("thread_id");
  };
  useEffect(() => {
    if (isSignedIn && user?.fullName) {
      clearTryData();
      identifyAnalytics(user.primaryEmailAddress.emailAddress, userMetadata, dispatch);
    }
  }, [isSignedIn, user, userMetadata]);

  useEffect(() => {
    if (accessTokenValue) {
      console.log("atv found. fetching metadata");
      var thread_id = queryParams.get("thread_id");
      dispatch(getUserMetadata({ thread_id: thread_id }));
    } else {
      console.log("atv not found");
      getTokenValue();
    }
  }, [accessTokenValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if the profile is not created (status = 401), then create the user
    // if the profile is created but profile is not completed(parties not created), (status = 404), then redirect to onboarding
    // if the profile is created and profile is completed (status = 200), then fetch parties information, and rest will be taken care of next useEffect
    var thread_id = queryParams.get("thread_id");

    if (userMetadataFetchStatus === "success") {
      switch (next) {
        case "ask":
          navigate(`/ask`);
          break;
        case "draft":
          navigate(`/home?open_draft=legal review request`);
          break;
        case "review":
          navigate(`/`);
          break;
        case "membership":
          dispatch(setShowUpgradePlanNudge(true));
          navigate("/home?open_membership=true");
          break;
        case "pro":
          if (thread_id && thread_id !== "null") {
            navigate(`/chat/${thread_id}?open_pro=true`);
          } else {
            navigate(`/home?open_pro=true`);
          }
          break;
        case "counsel":
          if (thread_id && thread_id !== "null") {
            navigate(`/chat/${thread_id}?open_counsel=true`);
          } else {
            navigate(`/home?open_counsel=true`);
          }
          break;
        default:
          navigate(`/`);
      }
      // } else if (userMetadataFetchStatus === "profile-not-completed") {
      //   if (thread_id) {
      //     navigate(`/onboarding?next=${next}&thread_id=${thread_id}`);
      //   } else {
      //     navigate(`/onboarding?next=${next}`);
      //   }
      // } else if (userMetadataFetchStatus === "profile-not-created") {
      //   if (thread_id) {
      //     navigate(`/onboarding?next=${next}&thread_id=${thread_id}`);
      //   } else {
      //     navigate(`/onboarding?next=${next}`);
      //   }
    }
    // else if (userMetadataFetchStatus === "success") {
    //     dispatch(getSavedParties());
    // }
  }, [userMetadataFetchStatus]); // eslint-disable-line

  return (
    <>
      {userMetadataFetchStatus !== "success" &&
      userMetadataFetchStatus !== "loading" &&
      userMetadataFetchStatus !== "idle" ? (
        <div className="w-full h-screen flex justify-center items-center flex-col gap-[8px]">
          <div className="w-[458px] h-[82px] rounded-[8px] border px-[16px] py-[15px] flex gap-[8px] justify-center items-start bg-[#FFF6F5] ">
            <span className="w-[24px] h-[24px]">
              <Warning size={24} className="text-red-700" />
            </span>
            <p className="font-sans text-[16px] font-medium leading-[25px]">
              We're experiencing a temporary issue loading data. Please refresh the app to try again.{" "}
            </p>
          </div>
          <p className="font-sans font-medium text-[14px] leading-[22px] text-[#333333]">
            Reach out to the{" "}
            <a
              href="mailto:support@inhouse.app"
              target="_blank"
              rel="noreferrer"
              className="underline decoration-solid text-black"
            >
              support team
            </a>{" "}
            if the problem persists
          </p>
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-col justify-center justify-items-center gap-[10px]">
          {/* <div className="flex flex-"> */}
          {createUserAttemptCount >= 5 ? (
            <>
              <p className="mx-auto">We are facing some issues.</p>
              <button
                className="cursor-pointer rounded-[8px] flex flex-row gap-[8px] items-center justify-center  text-white bg-[#302F2F] font-[600] text-[14px] py-[12px] px-[24px] my-auto mx-auto"
                onClick={() => {
                  dispatch(createUserAPI());
                  setCreateUserAttemptCount(0);
                }}
              >
                Retry
              </button>
            </>
          ) : (
            <>
              <div className="mx-auto w-10 h-10 border-2 border-t-0 border-[#000] rounded-full animate-spin"></div>
              <p className="mx-auto">Setting up your legal space.</p>
            </>
          )}
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default PostAuthOption2;
