import { ArrowRight, ShareFat } from "@phosphor-icons/react";
import { PopupButton } from "react-calendly";
import { useDispatch } from "react-redux";
import { setShowFindBestLawyer, setShowScheduleMeeting } from "src/pages/onboarding/onboardingSlice";

import UCLaw from "src/images/uc-law.svg";
import SandeepAhuja from "src/images/sandeep-ahuja.svg";
import UCLALawSchool from "src/images/ucla-law-school.svg";
import MattJanda from "src/images/matt-janda.svg";
import WillS from "src/images/will-s.svg";
import Alberta from "src/images/alberta.svg";
import USCGold from "src/images/USCgold.svg";
import { X } from "@phosphor-icons/react/dist/ssr";
import { resetRequestLegalReviewFailureReason } from "src/pages/onboarding/onboardingSlice";

const lawyers = [
  {
    name: "Sandeep Aujla",
    qualification: "UC Law",
    experience: "13+ years in practice",
    companyLogo: UCLaw,
    profilePic: SandeepAhuja,
  },
  {
    name: "Matt Janda",
    qualification: "UCLA",
    experience: "11 years in practice",
    companyLogo: UCLALawSchool,
    profilePic: MattJanda,
  },

  {
    name: "Alberta Lopez",
    qualification: "USC",
    experience: "10+ years in practice",
    companyLogo: USCGold,
    profilePic: Alberta,
  },

  {
    name: "Will S. Chuchawat",
    qualification: "UCLA",
    experience: "15+ years in practice",
    companyLogo: UCLALawSchool,
    profilePic: WillS,
  },

  {
    name: "Sandeep Aujla",
    qualification: "UC Law",
    experience: "13+ years in practice",
    companyLogo: UCLaw,
    profilePic: SandeepAhuja,
  },
];

const FindBestLawyer = () => {
  const dispatch = useDispatch();
  const scheduleCall = () => {
    dispatch(setShowScheduleMeeting({ show: true, lawyer_details: {} }));
    dispatch(setShowFindBestLawyer(false));
    dispatch(resetRequestLegalReviewFailureReason());
  };

  return (
    <>
      <div className="flex flex-end justify-end w-full p-3">
        <X
          size={24}
          className="cursor-pointer"
          onClick={() => {
            dispatch(setShowFindBestLawyer(false));
            dispatch(resetRequestLegalReviewFailureReason());
          }}
        />
      </div>
      <div className="flex flex-col items-center pt-4 pb-8">
        <p className="text-2xl font-bold text-[#011513]">Let's find the best lawyer for your needs </p>
        <p className="text-sm font-normal text-[#686868] mt-1 text-center">
          With a network of over 1,000 lawyers practicing in all 50 states and in all <br />
          fields, we’ll find you a highly rated and AI-friendly lawyer for any situation
        </p>
        <ul className="flex gap-4 mt-8 w-[812px]">
          {lawyers.map((lawyer) => (
            <li className="w-[180px] -left-10 relative flex-none h-[200px] bg-[#E79571]/10 rounded-xl px-3 py-4">
              <div className="px-[4px] py-[4px] border border-[#748A8C] rounded-full w-fit">
                <div className="px-[2px] py-[2px] border border-[#748A8C] rounded-full w-fit">
                  <img alt="lawyer pic" className="object-cover w-10 h-10 rounded-full" src={lawyer.profilePic} />
                </div>
              </div>
              <p className="mt-2 text-xs font-semibold">
                {lawyer.name}, <br /> {lawyer.qualification}
              </p>
              <p className="text-[#686868] text-xs font-normal mt-1">{lawyer.experience}</p>
              <div class="h-[36px] rounded-md border mt-2 bg-white w-fit p-[8px]">
                <img alt="lawyer company" src={lawyer.companyLogo} className="h-[20px] object-cover" />
              </div>
            </li>
          ))}
        </ul>

        <p className="text-lg font-bold text-[#353535] mt-8">Schedule a 1-on-1 call with our legal support team</p>
        <p className="text-[#686868] text-sm font-normal">
          We'll share a variety of options after learning more about your specific needs.
        </p>
        <div className="flex justify-end w-full mt-12 px-[36px]">
          {/* <div className='flex items-center'>
                        <p className='text-base font-medium text-[#353535]' >Already have a lawyer? <span className='underline'> Share chat link</span></p>
                        <ShareFat className='ml-2' size={16} />
                    </div> */}
          <div>
            <button
              className={`bg-[#1B2B48] text-white font-medium py-2 px-6 rounded-lg flex items-center`}
              onClick={scheduleCall}
            >
              <div className="flex flex-row gap-[12px]">
                <span className="my-auto">Schedule a call</span>
                <ArrowRight size={16} className="my-auto text-white" />
                {/* <img src={ArrowRightImage} width={16} height={16} alt="->" className="my-auto" /> */}
              </div>
            </button>
            {/* <PopupButton
                        url="https://calendly.com/ryanwenger"
                        rootElement={document.getElementsByTagName("body")[0]}

                        pageSettings={{
                            backgroundColor: 'white',
                            hideEventTypeDetails: true,
                            primaryColor: '#5E59FF',
                            textColor: '#434343'
                        }}
                        text={<div className="flex flex-row gap-[12px]">
                            <span className="my-auto">Schedule a call</span>
                            <ArrowRight size={16} className="my-auto text-white" />
                        </div>}
                    /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FindBestLawyer;
