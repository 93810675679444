import { ArrowBendDoubleUpRight, PenNib } from "@phosphor-icons/react";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import applicationStore from "src/persistence/stores/RootStore";
import { useWebSocketContext } from "src/WebsocketProvider";
import { setAwaitingCounter } from "./messageSlice";
import { useDispatch } from "react-redux";

const LawGptAdditionalDetails = (props) => {
  const { threadData } = props;
  const [inputMessage, setInputMessage] = React.useState("");
  const [hideComponent, setHideComponent] = React.useState(false);

  const { sendMessage, createMessage, socketRef } = useWebSocketContext();
  const dispatch = useDispatch();

  const submit = () => {
    var message = createMessage("draft", "submit-and-create-document", {
      thread_id: threadData?.id,
      additional_context: inputMessage,
    });
    if (socketRef?.current?.readyState !== 1) {
      setTimeout(() => {
        return submit();
      }, 1000);
    } else {
      sendWsMessage(message);
    }
  };

  const sendWsMessage = (message) => {
    const awaitingCounter = applicationStore.getState().messageSlice.awaitingCounter;
    if (awaitingCounter > 20) {
      return;
    }
    if (socketRef?.current?.readyState !== 1) {
      dispatch(setAwaitingCounter(awaitingCounter + 1));
      setTimeout(() => {
        sendWsMessage(message);
      }, 1000);
    } else {
      sendMessage(message, true);
      setInputMessage("");
    }
  };

  useEffect(() => {
    if (threadData?.id) {
      const uniqueMessages = threadData.messages.filter(
        (message, index, self) =>
          index === self.findIndex((m) => m.id === message.id && m.message_type === message.message_type)
      );
      const lastMessage = uniqueMessages[uniqueMessages.length - 1];
      if (lastMessage.message_type === "law_gpt_additional_details") {
        setHideComponent(true);
      } else {
        setHideComponent(false);
      }
    }
  }, [threadData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {hideComponent ? (
        <div className="flex flex-col gap-[12px] mb-[24px] mt-[16px]">
          <div className="flex flex-wrap gap-[8px]">
            <ArrowBendDoubleUpRight
              size={20}
              className="text-[#686868]"
              style={{ transform: "scaleY(-1)" }}
            ></ArrowBendDoubleUpRight>

            <span className="text-[16px] font-[600] text-[#141414]">
              Do you have any modifications or additional context?
            </span>
            <span className="text-[16px] font-[400] text-[#686868]">(Optional)</span>
          </div>
          <div
            className={`flex items-start space-x-2 input-group-area group w-full gap-[12px] py-[16px] pl-[6px] md:pl-[16px] md:pr-[16px]  border focus-within:!border-black bg-white rounded-[10px] md:rounded-[16px] `}
          >
            <div className="w-full flex justify-between items-center">
              <TextArea
                autoSize={{
                  minRows: window.location.pathname === "/home" ? 2 : 1,
                  maxRows: 6,
                }}
                //  rows={5}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    (e.ctrlKey || e.metaKey) &&
                    !e.shiftKey &&
                    (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                  ) {
                    onsubmit();
                  }
                }}
                className={`focus:!border-0 focus:!shadow-none form-control border-0 disabled:bg-white disabled:!border-0 disabled:!shadow-none placeholder-[#949494] text-[14px] leading-[21px] 
                  md:text-[18px] md:leading-[25.6px]`}
                placeholder="What’d you like Inhouse AI to consider in the document?"
              />
            </div>
          </div>
          <div className="flex justify-end w-full ">
            <button
              className="px-[16px] py-[8px] bg-[#1B2B48] text-white flex flex-row gap-[8px] rounded-[98px] w-full md:w-auto justify-center items-center"
              onClick={submit}
            >
              <PenNib className="text-white my-auto" size={16} />
              <span className="my-auto">
                {threadData?.document?.doc_title_shorthand
                  ? `Draft a ${threadData?.document?.doc_title_shorthand}`
                  : "Create Draft"}
              </span>
            </button>
          </div>
          <div className="h-[30px]"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LawGptAdditionalDetails;
