import { homePageClickAction, openPricingModal, trackAnalytics } from "src/pages/helpers";
import {
  Bicycle,
  Buildings,
  Lightning,
  OfficeChair,
  VideoCamera,
  FileText,
  PenNib,
  ChatsCircle,
  Gavel,
} from "@phosphor-icons/react";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { useDispatch } from "react-redux";
import TalkToDedicatedLawyerButton from "src/pages/chatpage/Navs/TalkToDedicatedLawyerButton";
import AskInhouseImg from "../images/AskInhouseImg.svg";
import InhouseProIcon from "../images/InhouseProIcon.svg";
import InhouseCounselIcon from "../images/InhouseCounselIcon.svg";
import TalkToLawyerButton from "src/pages/chatpage/Navs/TalkToLawyerButton";
import DedicatedLawyerBanner from "src/pages/LawyerHub/LegalReviewRequestAside/DedicatedLawyerBanner";
import NonDedicatedLawyerBanner from "src/pages/LawyerHub/LegalReviewRequestAside/NonDedicatedLawyerBanner";
import threeLawyerImg from "src/images/3-lawyers-bg.svg";
import ratingStars from "src/images/rating-star.svg";
import ProPopupBannerImg from "../images/Pro-popup-banner-img.svg";
import { setShowLearnMoreModal } from "src/pages/onboarding/onboardingSlice";
import LegalReviewRequestPersonal from "src/pages/LawyerHub/LegalReviewRequestAside/Personal";
import LegalReviewRequestBusiness from "src/pages/LawyerHub/LegalReviewRequestAside/Business";

export const stateOptions = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const companyExistance = [
  { label: "Not Started", value: "not-started" },
  { label: "0 - 2 years", value: "0-2" },
  { label: "2 - 10 years", value: "2-10" },
  { label: "10+ years", value: "10+ years" },
];

export const numberOfEmployees = [
  { label: "0", value: "0" },
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "50+", value: "50+" },
  // { label: "201-500", value: "201-500" },
  // { label: "501-1000", value: "501-1000" },
  // { label: "1000+", value: "1000+" },
];

export const legalWorkOption = [
  { label: "Self-help", value: "Self-help" },
  { label: "Online Legal Services", value: "Online Legal Services" },
  { label: "Outside Counsel", value: "Outside Counsel" },
  { label: "Inside Counel", value: "Inside Counel" },
  { label: "Other", value: "Other" },
];

export const legalWorkTypePeriod = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Quarterly", value: "Quarterly" },
  { label: "Annually", value: "Annually" },
  { label: "Other", value: "Other" },
];

export const legalWorkTypes = [
  { label: "Employment/HR", value: "employment/HR" },
  { label: "Drafting Contracts", value: "drafting contracts" },
  { label: "Reviewing Contracts", value: "reviewing contracts" },
  { label: "Litigation", value: "litigation" },
  // { label: "Intellectual Property", value: "Intellectual Property" },
  // { label: "Licensing/Regulations", value: "Licensing/Regulations" },
  { label: "Other", value: "Other" },
];

export const annualLegalEXpenses = [
  { label: "Self Help", value: "Self Help" },
  { label: "under $5K", value: "under $5K" },
  { label: "$5K - $25K", value: "$5K - $25K" },
  { label: "over $25K", value: "over $25K" },
  // { label: "$500k-$2M", value: "$500k-$2M" },
  // { label: "Over $2M", value: "Over $2M" },
];

export const industryArray = [
  { label: "Technology", value: "Technology" },
  { label: "Retail", value: "Retail" },
  { label: "Agency", value: "Agency" },
  { label: "Consulting", value: "Consulting" },
  { label: "E-Commerce", value: "E-Commerce" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Manufacturing ", value: "Manufacturing " },
  { label: "Professional Services", value: "Professional Services" },
  { label: "Other", value: "Other" },
];

// export const entityOptions = [
//   { value: "c_corporation", label: "C-corporation" },
//   { value: "llc", label: "LLC" },
//   { value: "s_corporation", label: "S-corporation" },
//   { value: "partnership", label: "Partnership" },
//   { value: "sole_proprietorship", label: "Sole Proprietorship" },
//   { value: "non_profit", label: "Non Profit" },
//   { value: "other", label: "Other" },
// ];
// export const entityOptions = {
//   corporation: "Corporation",
//   llc: "LLC",
//   partnership: "Partnership",
//   sole_proprietorship: "Sole Proprietorship",
//   non_profit: "Non Profit",
//   other: "Other",
// }

export const PLANS = {
  // ADHOC: "ad_hoc_legal_review",

  // this is before personal/ business selection
  PRE_ONBOARDING: "pre_onboarding",

  // personal accounts
  INHOUSE_ASK: "free_tier", // old free user. only for legacy purposes
  PERSONAL_FREE: "personal_free", // this is the new free users. personal account + free. 5 limited tasks per month + unlimited legal reviews

  // business accounts
  BUSINESS_TRIAL: "business_trial", // this lasts for 14 days
  BUSINESS_TRIAL_ENDED: "business_trial_ended", // after expiry of business trial
  INHOUSE_PRO: "subscriber_business", // Inhouse Pro
  INHOUSE_COUNSEL: "subscriber_enterprise", // Inhouse counsel,
  SUBSCRIBER_BUSINESS: "subscriber_business", // Inhouse Pro
  SUBSCRIBER_ENTERPRISE: "subscriber_enterprise", // Inhouse counsel,
};

export const FREE_TIER = "free_tier";
export const subscriber_enterprise = "subscriber_enterprise";

export const Inhouse_ASK = "Ask Inhouse";
export const Inhouse_PRO = "Inhouse Pro";
export const Inhouse_COUNSEL = "Inhouse Counsel";

export const PLANS_V2 = {
  free_tier: {
    name: Inhouse_ASK,
    baseName: "Inhouse Ask",
    titleClassName: "",
    borderClassName: "",
    title: <p className="font-sans font-semibold text-[20px] leading-[30px]">Inhouse Ask</p>,
    icon: AskInhouseImg,
    iconSize: "w-[32px] h-[32px]",
    subTitle: "For occasional legal questions or personal use",
    pricing: "$0",
    paymentFrequency: "",
    paymentFrequencyText: "FREE FOREVER",
    featuresHeading: "",
    features: [
      <p className="text-[14px] leading-[21px] text-[#444444] font-[500]">
        <span className="text-[#222222] font-[600]">Instant Legal Advice</span> from our AI on any issue
      </p>,
      <p className="text-[14px] leading-[21px] text-[#444444]">
        Pay-as-you-go <span className="text-[#222222]">lawyer support </span>
      </p>,
      // <p className="text-[14px] leading-[21px] text-[#444444]">
      //   <span className="text-[#222222]">On-demand lawyer support</span> @$99
      // </p>,
    ],
    btnTitle: "Current Plan",
    buttonClass: "ask-inhouse-plan-button w-full",
    isHighlighted: false,
    paymentPlanName: "free_tier",
    paymentPlanDisabled: true,
    ctaText: <></>,
  },
  // inhouse pro
  subscriber_business: {
    name: (
      <p className="font-sans font-semibold text-[20px] leading-[30px]">
        Inhouse <span className="text-[#248370]">Pro</span>
      </p>
    ),
    baseName: "Inhouse Pro",
    titleClassName: "",
    borderClassName: "",
    title: Inhouse_PRO,
    icon: InhouseProIcon,
    iconSize: "w-[32px] h-[32px]",
    subTitle: "For startups and early entrepreneurs",
    pricing: "$49",
    paymentFrequency: "/mo",
    paymentFrequencyText: "CANCEL ANYTIME",
    featuresHeading: "",
    textLine1: "Upgrade to Inhouse Pro",
    textLine2: (
      <p className="font-[lora] font-bold italic text-[30px] text-[#353535]">
        Unlock your Breach of <br /> Contract Notice at just <span className="text-[#248370]"> $49.</span>
      </p>
    ),
    features: [
      <p className="font-normal text-[14px] font-sans text-[#353535]">
        <span className="text-[#011513] font-medium">Instant advice</span> from our custom AI on any legal issue or
        documents
      </p>,
      <p className="font-normal text-[14px] font-sans text-[#353535]">
        Draft <span className="text-[#011513] font-medium">Unlimited</span> legal documents with AI.
      </p>,
      <p className="font-normal text-[14px] font-sans text-[#353535]">
        <span className="text-[#011513] font-medium">Save</span> your legal document and research.
      </p>,
    ],

    featuresDedicatedPage: [
      <p className="font-normal text-[14px] font-sans text-[#353535]">
        <span className="text-[#011513] font-medium">Instant advice</span> from our custom AI on any legal issue or
        documents
      </p>,
      <p className="font-normal text-[14px] font-sans text-[#353535]">
        Draft <span className="text-[#011513] font-medium">Unlimited</span> legal documents with AI.
      </p>,
      <p className="font-normal text-[14px] font-sans text-[#353535]">
        <span className="text-[#011513] font-medium">Save</span> your legal document and research.
      </p>,
    ],
    btnName: "Upgrade to Inhouse Pro",
    learnMore: (
      // TODO: Add href
      <p className="font-sans font-normal text-[12px]">
        <a href="#" className="text-[#4F54E9]">
          Learn More
        </a>
      </p>
    ),
    rightSection: (
      <div className="right w-[334px] h-full flex flex-col justify-between bg-[#ECF5F3] py-16">
        <img src={ProPopupBannerImg} alt="Right image" />
      </div>
    ),
    btnTitle: "Upgrade Now",
    buttonClass: "inhouse-pro-plan-button w-full",
    isHighlighted: true,
    paymentPlanName: "subscriber_business",
    paymentPlanDisabled: false,
    ctaText: (
      <>
        Upgrade to Inhouse Pro
        <span>
          <ArrowRight size={18} />
        </span>{" "}
      </>
    ),
  },
  // inhouse counsel
  subscriber_enterprise: {
    name: (
      <p className="font-sans font-semibold text-[20px] leading-[30px]">
        Inhouse <span className="text-[#248370]">Counsel</span>
      </p>
    ),
    baseName: "Inhouse Counsel",
    titleClassName: "",
    borderClassName: "true",
    title: "Inhouse Counsel",
    icon: InhouseCounselIcon,
    iconSize: "w-[51px] h-[51px]",
    subTitle: "Suited for businesses ready to grow",
    pricing: "$149",
    paymentFrequency: "/mo",
    paymentFrequencyText: "CANCEL ANYTIME",
    featuresHeading: "Everything on Inhouse Pro, plus:",
    features: [
      <p className="text-[14px] leading-[21px] font-normal text-[#444444]">
        <span className="text-[#222222] font-[500]">Unlimited* </span>
        Lawyer Consultation{" "}
      </p>,
      <p className="text-[14px] leading-[21px] font-normal text-[#444444]">
        <span className="text-[#222222] font-[500]">Unlimited* </span>
        Lawyer Reviewed Documents{" "}
      </p>,
      <p className="text-[14px] font-[400] leading-[21px] text-[#444444]">
        <span className="text-[#222222] font-[500]">Annual </span> Legal Health Checks
      </p>,
      <p className="text-[14px] leading-[21px] font-[400] text-[#444444]">
        <span className="text-[#222222] font-[500]">Assisted </span> Onboarding
      </p>,
    ],
    featuresDedicatedPage: [
      <p className="text-[14px] leading-[21px] font-normal text-[#444444]">
        <span className="text-[#222222] font-[500]">Top-Tier Expertise - </span> Get matched with a highly rated, U.S.
        licensed lawyer with 7+ years of experience
      </p>,
      <p className="text-[14px] leading-[21px] font-normal text-[#444444]">
        <span className="text-[#222222] font-[500]">Efficient & Focused - </span>
        Your attorney reviews your chat history in advance, so you get straight to the key issues.
      </p>,
      <p className="text-[14px] font-[400] leading-[21px] text-[#444444]">
        <span className="text-[#222222] font-[500]">Money-Back Guarantee - </span>
        Gain clarity on the issues and your next steps or get your money back.
      </p>,
      <p className="text-[14px] leading-[21px] font-[400] text-[#444444]">
        <span className="text-[#222222] font-[500]">Unlimited Consultations - </span>
        Get expert help on any legal issue, as often as you need.* Cancel anytime.
      </p>,
    ],
    btnTitle: "Upgrade Now",
    buttonClass: "inhouse-counsel-button w-full",
    isHighlighted: false,
    paymentPlanName: "subscriber_enterprise",
    paymentPlanDisabled: false,
    textLine1: "Upgrade to Inhouse Counsel",
    textLine2: (
      <p className="font-[lora] font-bold italic text-[30px] text-[#353535]">
        Talk to an expert lawyer about IP Protection at just <span className="text-[#248370]"> $149.</span>
      </p>
    ),
    btnName: "Schedule Call",
    learnMore: (
      // TODO: Add href
      <p className="font-sans font-normal text-[10px]">
        *Unlimited 30-minute sessions, one per topic, to ask legal questions or have documents reviewed. 
        <a href="https://www.inhouse.app/pricing" target="blank" className="text-[#4F54E9]">
          Learn More
        </a>
      </p>
    ),
    rightSection: (
      <div className="right md:w-[38%] md:max-w-[334px] w-full h-full flex flex-col justify-between bg-[#ECF5F3] py-16">
        <p className="px-4 font-medium font-sans text-[14px] leading-[18px] text-center text-[#353535]">
          Trusted legal network of <span className="text-[#248370] font-semibold"> 2,000+ lawyers</span> specializing in{" "}
          <span className="text-[#248370] font-semibold">25+ practice areas </span>{" "}
        </p>
        <img src={threeLawyerImg} alt="Three lawyers" />
        <div className="flex justify-center items-center flex-col gap-[4px]">
          <img src={ratingStars} alt="Rating" />
          <p className="font-sans font-medium text-[12px] text-center">
            Rated by <span className="font-semibold"> 2,400+ </span> clients
          </p>
        </div>
      </div>
    ),
    ctaText: (
      <>
        <span>
          <VideoCamera size={18} />
        </span>{" "}
        Schedule a Meeting Now
      </>
    ),
  },
};

export const PLAN_TYPES = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

export const entityOptions = {
  Corporation: "Corporation",
  LLC: "LLC",
  Partnership: "Partnership",
  "Sole Proprietorship": "Sole Proprietorship",
  "Non Profit": "Non Profit",
  Other: "Other",
};

export const stateOptionsNew = {
  Alabama: "Alabama",
  Alaska: "Alaska",
  Arizona: "Arizona",
  Arkansas: "Arkansas",
  California: "California",
  Colorado: "Colorado",
  Connecticut: "Connecticut",
  Delaware: "Delaware",
  Florida: "Florida",
  Georgia: "Georgia",
  Hawaii: "Hawaii",
  Idaho: "Idaho",
  Illinois: "Illinois",
  Indiana: "Indiana",
  Iowa: "Iowa",
  Kansas: "Kansas",
  Kentucky: "Kentucky",
  Louisiana: "Louisiana",
  Maine: "Maine",
  Maryland: "Maryland",
  Massachusetts: "Massachusetts",
  Michigan: "Michigan",
  Minnesota: "Minnesota",
  Mississippi: "Mississippi",
  Missouri: "Missouri",
  Montana: "Montana",
  Nebraska: "Nebraska",
  Nevada: "Nevada",
  "New Hampshire": "New Hampshire",
  "New Jersey": "New Jersey",
  "New Mexico": "New Mexico",
  "New York": "New York",
  "North Carolina": "North Carolina",
  "North Dakota": "North Dakota",
  Ohio: "Ohio",
  Oklahoma: "Oklahoma",
  Oregon: "Oregon",
  Pennsylvania: "Pennsylvania",
  "Rhode Island": "Rhode Island",
  "South Carolina": "South Carolina",
  "South Dakota": "South Dakota",
  Tennessee: "Tennessee",
  Texas: "Texas",
  Utah: "Utah",
  Vermont: "Vermont",
  Virginia: "Virginia",
  Washington: "Washington",
  "West Virginia": "West Virginia",
  Wisconsin: "Wisconsin",
  Wyoming: "Wyoming",
};

export const plans = [
  {
    isActive: true,
    title: "Unlimited Plan",
    titleIcon: <Buildings className="ml-2 text-[#DCA218]" size={14} />,
    billCycle: "MONTHLY",
    planName: PLANS.SUBSCRIBER_ENTERPRISE,
    price: "189",
    priceYearly: "149",
    button: {
      text: "Upgrade to Unlimited Plan",
      className: "!font-bold",
      // onClick={() => {setPlan(PLANS.SUBSCRIBER_BUSINESS)}}
    },
    badge: {
      text: "MOST Popuplar",
      className: "",
      show: true,
    },
    features: [
      { text: "Unlimited legal tasks ✨", highlight: true },
      { text: "Dedicated Lawyer" },
      { text: "Unlimited Legal Reviews ✨", highlight: true },
    ],
  },
  {
    isActive: false,
    title: "Essential Plan",
    titleIcon: <OfficeChair className="ml-2" size={14} />,
    billCycle: "MONTHLY",
    price: "59",
    priceYearly: "49",
    planName: PLANS.SUBSCRIBER_BUSINESS,
    button: {
      secondary: true,
      text: "Upgrade to Essential Plan",
    },
    features: [
      { text: "10 legal tasks/month" },
      { text: "Dedicated Lawyer" },
      // { text: '1 lawyer review/month' },
      // { text: 'Additional Review costs  $49 / each' },
    ],
  },

  {
    isActive: false,
    title: "Starter Plan",
    titleIcon: <Bicycle className="ml-2" size={14} />,
    planName: PLANS.SUBSCRIBER_BUSINESS,
    button: {
      text: "Your Current Plan",
      disabled: true,
    },
    features: [{ text: "2 legal tasks" }, { text: "Pay $49 per Lawyer Verification" }],
  },
];

export const ReviewStatus = Object.freeze({
  pending_lawyer_matching: "pending_lawyer_matching",
  in_progress: "in_progress",
  completed: "completed",
});

export const ApiStatus = Object.freeze({
  success: "success",
  error: "error",
  loading: "loading",
  idle: "idle",
});

export const userData = {
  paymentSuccessModal: {
    free_tier: {},
    subscriber_business: {
      title: "Welcome to Inhouse Pro",
      subTitle: (
        <p className="text-[#353535] text-[16px] font-[400]">
          Draft, store and edit <span className="font-[700]">unlimited</span> legal documents with our AI
        </p>
      ),
      redirectText: "Unlocking your document",
      ctaText: (
        <>
          Upgrade to Inhouse Pro
          <span>
            <ArrowRight size={18} />
          </span>{" "}
        </>
      ),
    },
    subscriber_enterprise: {
      title: "Welcome to Inhouse Counsel",
      subTitle: (
        <p className="text-[#353535] text-[16px] font-[400]">
          Enjoy <span className="font-[700]">unlimited</span> legal consultations from experienced attorneys with your
          upgrade.
        </p>
      ),
      redirectText: "",
      ctaText: (
        <>
          <span>
            <VideoCamera size={18} />
          </span>{" "}
          Schedule a Meeting Now
        </>
      ),
    },
  },
  homepage: {
    topnav: {
      free_tier: ({ dispatch, metadata, email, page }) => {
        return (
          <>
            <div className="flex flex-row gap-[8px]">
              <button
                className="text-[#84610e] md:text-[#353535] md:bg-[#fbf6e7] md:border-[1px] border-[#DCA218] h-[42px] md:rounded-[8px] gap-[8px]  px-[12px] flex flex-row"
                onClick={() => {
                  openPricingModal(dispatch, metadata, email, "home", "home:topnav:upgrade_button_clicked");
                }}
              >
                <span className="my-auto font-semibold text-[13px] md:text-[14px] leading-[19.6px] text-underline ">
                  Upgrade to Premium{" "}
                </span>
                <span className="my-auto">
                  <Lightning weight="fill" size={16} className="text-[#DCA218] my-auto" />
                </span>
              </button>
            </div>
          </>
        );
      },
      subscriber_business: ({ dispatch, metadata, email, page }) => {
        return (
          <>
            <div className="flex flex-row gap-[8px]">
              <button
                className="text-[#84610e] md:text-[#353535] md:bg-[#fbf6e7] md:border-[1px] border-[#DCA218]  h-[42px] md:rounded-[8px] gap-[8px]  px-[12px] flex flex-row"
                onClick={() => {
                  openPricingModal(dispatch, metadata, email, "home", "home:topnav:upgrade_button_clicked");
                }}
              >
                <span className="my-auto font-semibold text-[13px] md:text-[14px] leading-[19.6px] text-underline ">
                  Upgrade to Premium{" "}
                </span>
                <span className="my-auto">
                  <Lightning weight="fill" size={16} className="text-[#DCA218] my-auto" />
                </span>
              </button>
              {/* <TalkToLawyerButton userMetadata={metadata} email={email} /> */}
            </div>
          </>
        );
      },
      subscriber_enterprise: ({ dispatch, metadata, email, page }) => {
        return <TalkToDedicatedLawyerButton userMetadata={metadata} />;
      },
    },

    talktolawyerbanner: {
      free_tier: {
        showBanner: true,
        banner_text_line1: "Specialized legal advice @ Affordable Rates",
        banner_text_line2: "2000+ AI friendly lawyers practicing in all 50 states of the USA",
        banner_text_line1_sm: "Specialized legal advice @ Affordable Rates",
        banner_text_line2_sm: "2000+ AI friendly lawyers across USA",
        button: <ArrowRight size={24} className="md:mt-[75px]" />,
      },
      subscriber_business: {
        showBanner: true,
        banner_text_line1: "Specialized legal advice @ Affordable Rates",
        banner_text_line2: "2000+ AI friendly lawyers practicing in all 50 states of the USA",
        banner_text_line1_sm: "Specialized legal advice @ Affordable Rates",
        banner_text_line2_sm: "2000+ AI friendly lawyers across USA",
        button: <ArrowRight size={24} className="md:mt-[75px]" />,
      },
      subscriber_enterprise: {
        showBanner: false,
        banner_text_line1: "talk to a lawyer specialized for your needs",
        banner_text_line2: "2000+ AI friendly lawyers practicing in all 50 states of the USA",
        banner_text_line1_sm: "Specialized legal advice @ Affordable Rates",
        banner_text_line2_sm: "2000+ AI friendly lawyers across USA",
        button: <ArrowRight size={24} className="md:mt-[75px]" />,
      },
    },
  },
  sidenav: {
    iconOnUserImage: {
      free_tier: () => {},
      subscriber_business: ({ sidenavCollapsed }) => {
        return (
          <span
            className={`absolute top-0 right-0 translate-x-[35%] translate-y-[-20%] ${
              sidenavCollapsed ? "block" : "hidden"
            }`}
          >
            <Lightning size={16} className=" text-[#DCA218]" weight="fill" />
          </span>
        );
      },
      subscriber_enterprise: ({ sidenavCollapsed }) => {
        return (
          <span
            className={`absolute top-0 right-0 translate-x-[35%] translate-y-[-20%] ${
              sidenavCollapsed ? "block" : "hidden"
            }`}
          >
            <Lightning size={16} className=" text-[#DCA218]" weight="fill" />
          </span>
        );
      },
    },
    displayPlanName: {
      free_tier: <></>,
      subscriber_business: (
        <>
          <p className="bg-[#fff8e6] px-[8px] py-[4px] font-sans font-medium text-[10px] leading-[12px] text-[#686868] flex justify-center items-center gap-[4px] rounded-[4px]">
            Inhouse Pro{" "}
            <span>
              {" "}
              <Lightning size={10} className=" text-[#DCA218]" weight="fill" />
            </span>{" "}
          </p>
        </>
      ),
      subscriber_enterprise: (
        <>
          <p className="bg-[#fff8e6] px-[8px] py-[4px] font-sans font-medium text-[10px] leading-[12px] text-[#686868] flex justify-center items-center gap-[4px] rounded-[4px]">
            Inhouse Counsel{" "}
            <span>
              {" "}
              <Lightning size={10} className=" text-[#DCA218]" weight="fill" />
            </span>{" "}
          </p>
        </>
      ),
    },
    planName: {
      free_tier: "free tier",
      subscriber_business: "subscriber_business",
      subscriber_enterprise: "subscriber_enterprise",
    },
  },
  chat: {
    draftDocument: {
      islocked: "This document is locked by another user. Please try again later.",
      "subscription-plan-popup": "2 options only instead of 3",
    },
  },
  legalReview: {
    isTextMandatory: {
      home: true,
      thread: false,
    },
    dedicatedLawyer: {
      banner: <DedicatedLawyerBanner />,
      text: "soemthing",
      buttonCta: "something",
      buttonClasses: "seomthing",
      formLabel: "Optional details",
      formPlaceholder: "Enter details about your legal needs here.",
      isTextMandatory: "something",
      phoneNumberLabel: "Phone Number",
      phonenumberplaceholder: "Enter phone number",
      requestSuccessMessage: "something",
      footer: "",
      cta: "",
    },
    nonDedicatedLawyer: {
      banner: <NonDedicatedLawyerBanner />,
      text: "soemthing",
      buttonCta: "something",
      buttonClasses: "seomthing",
      formLabel: "Optional details",
      formPlaceholder: "Enter details about your legal needs here.",
      isTextMandatory: "something",
      phoneNumberLabel: "Phone Number",
      phonenumberplaceholder: "Enter phone number",
      requestSuccessMessage: "something",
      footer: "",
      cta: "",
    },
  },
  legalReviewAsideOld: {
    free_tier: ({
      dispatch,
      metadata,
      email,
      trackAnalytics,
      legalReiviewImg,
      requestLegalReviewStatus,
      text,
      notice,
      setText,
      loading,
      setShowLearnMoreModal,
      phoneNumber,
      setPhoneNumber,
      legalReview,
    }) => {
      return (
        <LegalReviewRequestPersonal
          email={email}
          trackAnalytics={trackAnalytics}
          dispatch={dispatch}
          metadata={metadata}
          legalReiviewImg={legalReiviewImg}
          requestLegalReviewStatus={requestLegalReviewStatus}
          text={text}
          notice={notice}
          setText={setText}
          loading={loading}
          setShowLearnMoreModal={setShowLearnMoreModal}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          legalReview={legalReview}
        />
      );
    },
    subscriber_business: ({
      dispatch,
      metadata,
      email,
      trackAnalytics,
      legalReiviewImg,
      requestLegalReviewStatus,
      text,
      notice,
      setText,
      loading,
      setShowLearnMoreModal,
      phoneNumber,
      setPhoneNumber,
      legalReview,
    }) => {
      return (
        <LegalReviewRequestBusiness
          email={email}
          trackAnalytics={trackAnalytics}
          dispatch={dispatch}
          metadata={metadata}
          notice={notice}
          legalReiviewImg={legalReiviewImg}
          requestLegalReviewStatus={requestLegalReviewStatus}
          text={text}
          setText={setText}
          loading={loading}
          setShowLearnMoreModal={setShowLearnMoreModal}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          legalReview={legalReview}
        />
      );
    },
  },
  lawyerHubData: {},
};

export const userDataTryPage = {
  upgrade_to_counsel: {
    textLine1: "Upgrade to Inhouse Counsel",
    textLine2: (
      <p className="font-[lora] font-bold italic text-[30px] text-[#353535]">
        Talk to an expert lawyer about IP Protection at just <span className="text-[#248370]"> $149.</span>
      </p>
    ),
    features: [
      <p className="text-[14px] leading-[21px] text-[#686868] font-[500]">
        <span className="text-[#011513] font-medium">Unlimited</span> lawyer consultations on any topics
      </p>,
      <p className="text-[14px] leading-[21px] text-[#686868] font-[500]">
        <span className="text-[#011513] font-medium">Unlimited</span> legal document reviews{" "}
      </p>,
      <p className="text-[14px] leading-[21px] text-[#686868] font-[500]">
        <span className="text-[#011513] font-medium">Cancel</span> anytime. No commitments
      </p>,
    ],
    btnName: "Schedule Call",
    learnMore: (
      // TODO: Add href
      <p className="font-sans font-normal text-[10px]">
        Legal consultations are 30-min sessions <br /> with an expert lawyer.{" "}
        <a href="https://www.inhouse.app/pricing" target="blank" className="text-[#4F54E9]">
          Learn More
        </a>
      </p>
    ),
    rightSection: (
      <div className="right md:w-[38%] md:max-w-[334px] w-full h-full flex flex-col justify-between bg-[#ECF5F3] py-16">
        <p className="px-4 font-medium font-sans text-[14px] leading-[18px] text-center text-[#353535]">
          Trusted legal network of <span className="text-[#248370] font-semibold"> 2,000+ lawyers</span> specializing in{" "}
          <span className="text-[#248370] font-semibold">25+ practice areas </span>{" "}
        </p>
        <img src={threeLawyerImg} alt="Three lawyers" />
        <div className="flex justify-center items-center flex-col gap-[4px]">
          <img src={ratingStars} alt="Rating" />
          <p className="font-sans font-medium text-[12px] text-center">
            Rated by <span className="font-semibold"> 2,400+ </span> clients
          </p>
        </div>
      </div>
    ),
  },
  upgrade_to_pro: {
    textLine1: "Upgrade to Inhouse Pro",
    textLine2: (
      <p className="font-[lora] font-bold italic text-[30px] text-[#353535]">
        Unlock your Breach of <br /> Contract Notice at just <span className="text-[#248370]"> $49.</span>
      </p>
    ),
    features: [
      <p className="font-normal text-[14px] font-sans text-[#353535]">
        Draft <span className="text-[#011513] font-medium">Unlimited</span> documents
      </p>,
      <p className="font-normal text-[14px] font-sans text-[#353535]">
        Access your legal documents<span className="text-[#011513] font-medium"> anytime.</span>
      </p>,
    ],
    btnName: "Upgrade to Inhouse Pro",
    learnMore: (
      // TODO: Add href
      <p className="font-sans font-normal text-[12px]">
        <a href="#" className="text-[#4F54E9]">
          Learn More
        </a>
      </p>
    ),
    rightSection: (
      <div className="right w-[334px] h-full flex flex-col justify-between bg-[#ECF5F3] py-16">
        <img src={ProPopupBannerImg} alt="Right image" />
      </div>
    ),
  },
};

export const suggestionBtnsLandingPage = [
  {
    icon: <PenNib size={16} className="text-[#13866F] my-auto" />,
    text: "Draft an NDA",
    hover: "hover:border-[#248370] hover:!bg-[#ECF5F3]",
  },
  {
    icon: <FileText size={16} className="text-[#DF8A64] my-auto" />,
    text: "Review this document",
    hover: "hover:border-[#DF8A64] hover:!bg-[#FDF7F4]",
    func: "Review",
  },

  {
    icon: <ChatsCircle size={16} className="text-[#A843AA] my-auto" />,
    text: "Start my own company",
    hover: "hover:border-[#A843AA] hover:!bg-[#FAEEFA]",
  },
  {
    icon: <Gavel size={16} className="text-[#18475A] my-auto" />,
    text: "Find a lawyer",
    hover: "hover:border-[#1B2B48] hover:!bg-[#FAFAFA]",
  },
];

export const campaignNameToDetailMappings = {
  testask: "Draft a Test Ask",
  nda: "NDA",
  termsofservice: "Draft a Terms of Service",
  privacypolicy: "Draft a Privacy Policy",
  partnershipagreement: "Draft a Partnership Agreement",
  shareholderagreement: "Draft a Shareholder Agreement",
};

export const campaignUtmSourceMappings = {
  testask: "test-ask",
  testdraft: "test-draft",
  sd_inhouseapp_search_trypage_employeeagreement_us_march2025: "employment-agreement",
  sd_inhouseapp_search_employeeagreement_us_march2025: "employment-agreement",
  sd_inhouseapp_search_trypage_ic_agreement_us_march2025: "ic-agreement",
  sd_inhouseapp_search_trypage_msa_us_march2025: "msa",
  sd_inhouseapp_search_trypage_partnershipagreement_us_march2025: "partnership-agreement",
  sd_inhouseapp_search_trypage_shareholderagreement_us_march2025: "shareholder-agreement",
};

export const campaignIdMappings = {
  testdraft: "recML9wVs9dseQ52c",
  sd_inhouseapp_search_trypage_employeeagreement_us_march2025: "reck1iUWfzg3FblCr",
  sd_inhouseapp_search_employeeagreement_us_march2025: "reck1iUWfzg3FblCr",
  nda: "others",
  termsofservice: "others",
  privacypolicy: "others",
  sd_inhouseapp_search_trypage_ic_agreement_us_march2025: "rec6iawmiLFxfvRX2",
  sd_inhouseapp_search_trypage_msa_us_march2025: "recwLxd3OSlhdNxQi",
  sd_inhouseapp_search_trypage_partnershipagreement_us_march2025: "others",
  sd_inhouseapp_search_trypage_shareholderagreement_us_march2025: "others",
};
