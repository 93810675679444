import { OpenAiLogo, SealCheck } from "@phosphor-icons/react";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import React from "react";
import inhouseImg from "src/images/inhouse-rounded-logo.svg";

const InhouseCounselIntro = () => {
  return (
    <div className="gap-[29px] flex flex-col bg-white rounded-[12px] border-[#E4E4E4] border-[1px] py-[29px]">
      <div className="flex flex-row mx-auto gap-[11px] mt-[20px]">
        <span className="w-[36px] h-[36px]  rounded-full flex items-center justify-center">
          <OpenAiLogo className="text-[#686868]" size={36} />
        </span>{" "}
        <ArrowRight size={17} className="my-auto" />
        <span>
          <img src={inhouseImg} alt="Inhouse Counsel" className="w-[36px] h-[36px]" />
        </span>
      </div>
      <div className="flex flex-col gap-[4px] mx-auto md:w-[431px] text-center">
        <h1 className="font-[lora] font-[700] italic text-[20px] md:text-[24px] leading-[145%]">
          Drafting your Pro Document
        </h1>
        {/* <p className="text-[#666666] text-[10px] md:text-[12px] mx-auto">
          The #1 Legal AI for Business Owners and Individuals
        </p> */}
        <p className="text-[#666666] text-[10px] md:text-[12px] mx-auto">
          Powered by{" "}
          <a href="https://www.inhouse.app" target="blank" className="underline ">
            Inhouse
          </a>
        </p>
      </div>
      <div className="flex  gap-[20px] text-[12px] text-[#686868] mx-auto w-full">
        <ul className="flex md:flex-row flex-col gap-[10px] mx-auto">
          <li>
            <div className="flex flex-row gap-[4px]">
              <SealCheck className="text-[#027A48]" weight="fill" size={19} />
              <p>Supported by 2k+ lawyers on demand</p>
            </div>
          </li>
          <li>
            <div className="flex flex-row gap-[4px]">
              <SealCheck className="text-[#027A48]" weight="fill" size={19} />
              <p>Guaranteed accuracy</p>
            </div>
          </li>
          <li>
            <div className="flex flex-row gap-[4px]">
              <SealCheck className="text-[#027A48]" weight="fill" size={19} />
              <p>
                Better legal reasoning via the Playbook<sup>™</sup>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InhouseCounselIntro;
