import React, { useEffect } from "react";
import { openPricingModal, trackAnalytics } from "../helpers";
import { PLANS_V2 } from "src/components/Constants";
import {
  setDocumentNameShortHand,
  setShowSignupToUpgradeModal,
  setShowUpgradePlanPopupChatPage,
} from "../onboarding/onboardingSlice";
import DocImage from "src/images/DocImage.png";
import { ArrowRight } from "@phosphor-icons/react";
import { setOpenUpgradeToProPopup } from "./threadsSlice";

const UpgradeToUnlockDocument = (props) => {
  const { threadData, dispatch, metadata, email } = props;
  const [text, setText] = React.useState("");

  const handleButtonClick = () => {
    var pageType = window.location.pathname === "/try" ? "try" : "chat";

    trackAnalytics(`${pageType}:unlock_document_button_clicked`, {
      document_id: threadData?.document?.doc_id,
      document_title: threadData?.document?.doc_title,
      user_email: email,
      chatId: threadData?.id,
      path: window.location.pathname,
    });
    if (window.location.pathname === "/try") {
      handleButtonClickTryPage();
    } else {
      handleButtonClickLoggedin();
    }
  };

  const handleButtonClickTryPage = () => {
    // alert("we will need to open signup --> upgrade to pro $49 journey modal");
    dispatch(
      setShowSignupToUpgradeModal({
        show: true,
        plans: PLANS_V2.subscriber_business,
        intent: "inhouse_pro",
        slide: "signup",
      })
    );
  };

  const handleButtonClickLoggedin = () => {
    dispatch(setDocumentNameShortHand(threadData?.document?.doc_title_shorthand));

    openPricingModal(
      dispatch,
      metadata,
      email,
      "chat",
      "chat:unlock_document_button_clicked",
      PLANS_V2.subscriber_business,
      setOpenUpgradeToProPopup
    );
  };

  useEffect(() => {
    if (window.location.pathname === "/try") {
      setText("Create Account and Unlock Access");
    } else {
      setText("Upgrade to Unlock Document");
    }
  }, []);

  return (
    <>
      <div className="flex flex-col w-full  mx-auto gap-0 mt-4 cursor-pointer" onClick={handleButtonClick}>
        <div className="rounded-t-[14px] flex flex-row w-full border border-[#E4E4E4] p-0">
          <div className="left px-[24px] py-[12px] min-h-[56px] my-auto">
            <img src={DocImage} alt="doc" className="w-[32px] h-[32px] object-contain my-auto" />
          </div>
          <div className="bg-white  right font-[600] text-[16px] px-[24px] py-[14px] w-full rounded-b-0 rounded-r-[14px] my-auto min-h-[56px]">
            {threadData?.document?.doc_title_shorthand}
          </div>
        </div>
        <div className="relative w-full h-[500px] border border-[#E4E4E4]">
          <iframe
            src={`https://docs.google.com/document/d/${threadData?.document?.id}/preview`}
            className="absolute top-0 left-0 w-full h-full"
            title={threadData?.document?.doc_title_shorthand}
          ></iframe>
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-white opacity-100">
            <div className="absolute bottom-0 left-0 w-full flex justify-center p-4">
              <button className="bg-[#1B2B48] text-white font-bold items-center justify-center rounded  flex flex-row w-[262px] h-[40px] gap-[12px]">
                <span className="my-auto text-[14px]">Access Full Document</span>{" "}
                <span className="my-auto">
                  <ArrowRight size={16} className="text-white" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpgradeToUnlockDocument;
