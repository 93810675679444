import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Check, Copy, DownloadSimple, Link, PencilSimpleLine, ShareFat, Spinner, Trash } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { generateSharedId, updateTitle } from "src/pages/chatpage/threadsSlice";
import { toast } from "react-toastify";
import { X } from "@phosphor-icons/react/dist/ssr";
import { trackAnalytics } from "src/pages/helpers";
import {
  generateDownloadDraftUrl,
  resetUpdatedTitle,
  setShowChatActionModal,
  setThreadSelectedToShare,
} from "src/pages/chatpage/threadsSlice";
import ShareThreadModal from "./ShareThreadModal";
import DeleteThreadModal from "./DeleteThreadModal";
import UpdateThreadTitleModal from "src/components/chat/Modals/UpdateThreadTitleModal";
import TextArea from "antd/es/input/TextArea";

const ChatActionsModal = () => {
  const dispatch = useDispatch();
  const [actionOnThread, setActionOnThread] = useState(""); // actions that can be done on a thread i.e Rename, Delete, Share
  const [thread, setThread] = useState({});
  const [updateActionLoading, setUpdateActionLoading] = useState(false);
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("");
  const [threadInfo, setThreadInfo] = useState({});
  const [updateThreadInfo, setUpdateThreadInfo] = useState({}); // thread which we are updating
  const [renameActiveId, setRenameActiveId] = useState(null);
  const [newTitle, setNewTitle] = useState("");

  const email = useSelector((state) => state.homeslice.email);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const lawyerHubData = useSelector((state) => state.onboarding.lawyerHubData);
  const downloadDraftUrlStatus = useSelector((state) => state.threads.downloadDraftUrlStatus);
  const showChatActionModal = useSelector((state) => state.threads.showChatActionModal);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const threads = useSelector((state) => state.threads.threads);

  useEffect(() => {
    // if (threadInfo) {
    //   setThread(threadInfo.thread);
    //   setDuration(threadInfo.duration);
    // }
    if (threadData) {
      var d = null;
      for (const [key, value] of Object.entries(threads)) {
        if (value.some((t) => t.id === threadData.id)) {
          setDuration(key);
          d = key;
          break;
        }
      }
      setThread(threadData);
      setThreadInfo({ thread: threadData, duration: d });
    }
  }, [threadData]);

  const onPerformThreadAction = (thread, action, duration = null) => {
    setUpdateThreadInfo({ thread: thread, duration: duration });
    setActionOnThread(action);
    switch (action) {
      case "Share":
        trackAnalytics("dashboard_history_tab_clicked_share_clicked", { user_email: email }, userMetadata);
        dispatch(setThreadSelectedToShare(thread.id));
        dispatch(generateSharedId({ thread: thread, duration: duration }));
        break;
      case "Rename":
        trackAnalytics("dashboard_history_tab_clicked_edit_clicked", { user_email: email }, userMetadata);
        dispatch(resetUpdatedTitle());
        setRenameActiveId(thread.id);
        break;
      case "Download":
        trackAnalytics("dashboard_history_tab_clicked_download_file_clicked", { user_email: email }, userMetadata);
        dispatch(generateDownloadDraftUrl({ thread: thread, duration: duration }));
        break;
      case "Delete":
        trackAnalytics("dashboard_history_tab_clicked_delete_clicked", { user_email: email }, userMetadata);
        break;
      default:
        break;
    }
  };

  const updatedTitle = useSelector((state) => state.threads.updatedTitle);

  useEffect(() => {
    if (updatedTitle?.status === "success") {
      setActionOnThread("");
      setLoading(false);
      setRenameActiveId("");
    }
  }, [updatedTitle]); // eslint-disable-line

  const updateThreadTitle = (newTitle) => {
    dispatch(updateTitle({ id: thread?.id, title: newTitle, duration: duration }));
  };

  useEffect(() => {
    setNewTitle(thread?.title);
  }, [thread?.title, showChatActionModal]);

  const close = () => {
    // setActionOnThread("");
    setRenameActiveId(null);
    dispatch(setShowChatActionModal(false));
  };

  return (
    <>
      <Modal
        centered
        open={showChatActionModal}
        onOk={() => setActionOnThread("")}
        className="rounded-[16px] bg-[#fff] md:overflow-hidden px-0 mx-0 !w-screen"
        footer={null}
      >
        <div className="flex flex-col w-full top-[500px] py-[20px] gap-[20px]">
          <div className="flex justify-between w-full px-[12px]">
            <h1
              className={`text-[18px] font-[500] pl-[12px] text-[#18475A] font-sans ${
                actionOnThread === "Rename" ? "text-[#353535]" : "text-[#18475A]"
              }`}
            >
              {actionOnThread === "Rename" ? "Rename this chat" : threadData?.title}
            </h1>
            <X size={24} className="cursor-pointer" onClick={() => close()} />
          </div>
          <hr className="mx-[16px]" />
          {/* 
      action:
        1. share: new modal. close this one
        2. rename: same modal
        3. download: same modal
        4. delete: same modal
       */}
          <div className="w-full flex flex-col justify-between px-[32px] gap-[32px] ">
            {actionOnThread === "" && (
              <>
                <div className={`bg-white rounded-t-0 rounded-[12px] right-[0px]`}>
                  <ul className="flex flex-col gap-[24px] py-[16px] pr-[24px]">
                    {/* {thread.google_doc_id && (
                      <>
                        {downloadDraftUrlStatus !== "loading" && (
                          <li
                            className="flex flex-row gap-[8px]"
                            onClick={() => {
                              onPerformThreadAction(thread, "Download", duration);
                            }}
                          >
                            <span>
                              <DownloadSimple size={16} className="my-auto text-[#B6B6B6]" />
                            </span>
                            <span className="text-[#353535] my-auto text-[14px] leading-[12px]">Download File</span>
                          </li>
                        )}
                        {downloadDraftUrlStatus === "loading" && (
                          <li className="flex flex-row gap-[8px]">
                            <span className="text-[14px] leading-[12px">Downloading File...</span>
                          </li>
                        )}
                      </>
                    )} */}
                    <li
                      className="flex flex-row items-center gap-[10px]"
                      onClick={() => {
                        onPerformThreadAction(thread, "Rename", duration);
                      }}
                    >
                      <span>
                        <PencilSimpleLine size={16} className="my-auto text-[#353535]" />
                      </span>
                      <span className="text-[#011513] font-medium my-auto text-[16px] leading-[25px]">Rename</span>
                    </li>
                    <li
                      className="flex flex-row items-center gap-[10px]"
                      onClick={() => {
                        onPerformThreadAction(thread, "Share", duration);
                      }}
                    >
                      <span>
                        <ShareFat size={16} className="my-auto text-[#353535]" />{" "}
                      </span>
                      <span className="text-[#011513] font-medium my-auto text-[16px] leading-[25px]">Share</span>
                    </li>
                    {!lawyerHubData?.some((item) => item.thread_id === thread.id) && (
                      <li
                        className="flex flex-row items-center gap-[10px]"
                        onClick={() => {
                          onPerformThreadAction(thread, "Delete", duration);
                        }}
                      >
                        <span>
                          <Trash className="my-auto text-[#A20009]" />
                        </span>
                        <span className="text-[#A20009] font-medium my-auto text-[16px] leading-[25px]">Delete</span>
                      </li>
                    )}
                  </ul>
                </div>
              </>
            )}
            {actionOnThread === "Rename" && (
              <>
                <div className="w-full flex flex-col gap-[12px]">
                  <div className="flex flex-col justify-between w-full gap-[24px]">
                    <TextArea
                      id="update-title"
                      name="updateTitle"
                      value={newTitle}
                      onChange={(e) => {
                        setNewTitle(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Enter" &&
                          (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                        ) {
                          updateThreadTitle(newTitle);
                        }
                      }}
                      className="px-[16px] py-[16px] rounded-[8px] text-[14px] font-[500] text-black  placeholder:text-black overflow-auto scrollbar-thin scrollbar-thumb-gray-500 !h-[54px] !border-[#18475A]"
                      placeholder="Add New Chat Title"
                    />
                    <div className="flex flex-row justify-end gap-[12px]">
                      {/* <button
                        className="text-[16px] font-[700]  bg-white  leading-[1] rounded-[5px] p-[12px] border border-black"
                        onClick={() => {
                          setActionOnThread("");
                          setRenameActiveId("");
                        }}
                        disabled={loading}
                        id="cancel-update-title-button"
                      >
                        <X size={15} className="mr-2 text-black mx-auto my-auto" weight="bold" />
                      </button> */}
                      <button
                        disabled={loading || !newTitle}
                        className="flex w-full h-[52px] justify-center items-center gap-[12px] text-[16px] font-[700]  bg-[#1B2B48] text-white rounded-[8px] p-[12px] border border-[#18475A]"
                        onClick={() => {
                          setLoading(true);
                          updateThreadTitle(newTitle);
                        }}
                        id="update-title-button"
                      >
                        {loading ? (
                          <Spinner size={16} className="animate-spin my-auto" color="white" />
                        ) : (
                          <>
                            <span className="font-medium text-[14px] font-sans text-white">Rename</span>
                            <Check size={15} weight="bold" className="my-auto mr-2 text-white" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {actionOnThread === "Delete" && <></>}
          </div>
        </div>
      </Modal>
      <DeleteThreadModal
        open={actionOnThread === "Delete"}
        setActionOnThread={setActionOnThread}
        threadInfo={updateThreadInfo}
        loading={updateActionLoading}
        setLoading={setUpdateActionLoading}
      />

      <ShareThreadModal
        open={actionOnThread === "Share"}
        setActionOnThread={setActionOnThread}
        actionOnThread={actionOnThread}
        threadInfo={updateThreadInfo}
        loading={updateActionLoading}
        setLoading={setUpdateActionLoading}
      />
    </>
  );
};

export default ChatActionsModal;
