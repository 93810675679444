import { Check, X } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
const DraftDocumentModalHeader = (props) => {
  const { closeModal, isDocumentDetailsScreen, concernedPartiesScreen, finishingTouchesScreen } = props;

  const [activeTab, setActiveTab] = React.useState([]);
  const [completedTabs, setCompletedTabs] = React.useState([]);
  const [pendingTabs, setPendingTabs] = React.useState([]);

  const selectedDocumentType = useSelector((state) => state.homeslice.selectedDocumentType);

  useEffect(() => {
    if (isDocumentDetailsScreen) {
      setActiveTab(["document"]);
      setCompletedTabs([]);
      setPendingTabs(["concerned-parties", "final-touches"]);
    }
    if (concernedPartiesScreen) {
      setActiveTab(["concerned-parties"]);
      setCompletedTabs(["document"]);
      setPendingTabs(["final-touches"]);
    }
    if (finishingTouchesScreen) {
      setActiveTab(["final-touches"]);
      setCompletedTabs(["document", "concerned-parties"]);
      setPendingTabs([]);
    }
  }, [isDocumentDetailsScreen, concernedPartiesScreen, finishingTouchesScreen]);

  const getTitle = () => {
    return "Draft a custom legal document";
    // if (isDocumentDetailsScreen) {
    //     return "Draft a custom legal document";
    // }
    // if (concernedPartiesScreen) {
    //     return "Draft a custom legal document";
    //     // return `Draft a ${selectedDocumentType.name}`;
    // }
    // if (finishingTouchesScreen) {
    //     return "Draft a custom legal document";
    //     // return "Draft a legal document with Inhouse AI";
    // }
  };

  const getClasses = (tab) => {
    if (activeTab.includes(tab)) {
      return "activated-tab";
    }
    if (completedTabs.includes(tab)) {
      return "completed-tab";
    }
    if (pendingTabs.includes(tab)) {
      return "non-activated-tab";
    }
  };

  // const getDocumentDetailsTab = () => {
  //     var classes = getClasses("document");
  //     return <>
  //         <div className={`flex flex-row gap-[12px] rounded-[90px] ${classes}`} id="document-details-tab">
  //             <div className="circle rounded-[50px] w-[24px] h-[24px] px-auto py-auto text-center">
  //                 {classes === "completed-tab" ? <Check size={16} className="text-[#137513] my-auto mx-auto" /> : 1}
  //             </div>
  //             <p className={`my-auto text-[14px] ${isDocumentDetailsScreen ? 'text-[#302F2F] font-[600]' : "text-[#838282]"}`}>
  //                 Document details
  //             </p>
  //         </div>
  //     </>
  // }
  // const getConcernedPartiesTab = () => {
  //     var classes = getClasses("concerned-parties");
  //     return <>
  //         <div className={`flex flex-row gap-[12px] rounded-[90px] ${classes}`} id="concerned-parties-tab">
  //             <div className="circle rounded-[50px] w-[24px] h-[24px] px-auto py-auto text-center">
  //                 {classes === "completed-tab" ? <Check size={16} className="text-[#137513] my-auto mx-auto" /> : 2}

  //             </div>
  //             <p className={`my-auto text-[14px] ${concernedPartiesScreen ? 'text-[#302F2F] font-[600]' : "text-[#838282]"}`}>
  //                 Party Selection
  //             </p>
  //         </div>
  //     </>
  // }
  const getFinishingTouchesTab = () => {
    var classes = getClasses("final-touches");

    return (
      <>
        <div className={`flex flex-row gap-[12px] rounded-[90px] ${classes}`} id="final-touches-tab">
          <div className="circle rounded-[50px] w-[24px] h-[24px] px-auto py-auto text-center">
            {classes === "completed-tab" ? <Check size={16} className="text-[#137513] my-auto mx-auto" /> : 3}
          </div>
          <p
            className={`my-auto text-[14px] ${finishingTouchesScreen ? "text-[#302F2F] font-[600]" : "text-[#838282]"}`}
          >
            Context
          </p>
        </div>
      </>
    );
  };
  return (
    <div className="flex h-[90px] py-[24px] px-[24px]">
      <div className="flex gap-[8px] w-full justify-between items-center">
        <h1 className="text-[20px] font-[600] leading-[28px] font-[Lora] italic text-[#011513]">{getTitle()}</h1>

        <X
          size={30}
          className="hover:cursor-pointer"
          color="black"
          onClick={() => closeModal()}
          id="cancel-draft-document-button"
        />
      </div>
      {/* <div className="flex mx-auto gap-[4px] w-full">
                {getDocumentDetailsTab()}
                <div className="border-[#B6B6B6] border-dashed border-[1px] w-[40px] align-items-center justify-items-center h-[1px] my-auto"></div>
                {getConcernedPartiesTab()}
                <div className="border-[#B6B6B6] border-dashed border-[1px] w-[40px] align-items-center justify-items-center h-[1px] my-auto"></div>
                {getFinishingTouchesTab()}

            </div> */}
    </div>
  );
};

export default DraftDocumentModalHeader;
