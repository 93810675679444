import { Briefcase } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackAnalytics } from "src/pages/helpers";
import { legalReviewButtonClickedIntent, setShowScheduleMeeting } from "src/pages/onboarding/onboardingSlice";

const TalkToDedicatedLawyerButton = ({ userMetadata }) => {
  const dispatch = useDispatch();
  const [metadata, setMetadata] = React.useState(userMetadata);

  const userdata = useSelector((state) => state.onboarding.userMetadata);
  const threadData = useSelector((state) => state.messageSlice.threadData);

  const buttonClicked = () => {
    var eventName;
    if (window.location.path === "/home") {
      eventName = "homepage:topnav:talk_to_dedicated_lawyer_button_clicked";
    } else {
      eventName = "chat:topnav:talk_to_dedicated_lawyer_button_clicked";
    }
    trackAnalytics(
      eventName,
      {
        user_email: metadata?.email,
        lawyer_email: metadata?.lawyer_info?.email,
        lawyer_name: metadata?.lawyer_info?.name,
      },
      metadata
    );
    dispatch(legalReviewButtonClickedIntent({ threadId: threadData.id }));
    console.log("showing scheduled meeting dedicated lawyer");
    dispatch(setShowScheduleMeeting({ show: true, lawyer_details: metadata.lawyer_info }));
  };

  useEffect(() => {
    if (userMetadata && Object.keys(userMetadata).length > 0) {
      setMetadata(userMetadata);
    } else {
      setMetadata(userdata);
    }
  }, [userMetadata, userdata]);

  return (
    <button
      className="bg-[#ECF5F3] md:bg-[#1B2B48] border  h-[30px] md:h-[48px] rounded-[8px] gap-[8px] md:gap-[12px]  md:py-[8px] px-[12px] flex flex-row justify-center items-center"
      onClick={() => buttonClicked()}
    >
      <span className="my-auto">
        <img
          src={metadata?.lawyer_info?.pre_signed_image_url}
          width="24"
          height="24"
          className="rounded-full my-auto w-[18px] md:w-[24px] h-[18px] md:h-[24px] border-white border-[1px]"
          alt={metadata?.lawyer_info?.name}
        />
      </span>
      <span className="md:text-white my-auto text-[12px] md:text-[14px] md:leading-[19.6px]">
        Talk to {metadata?.lawyer_info?.name}
      </span>
      <span className="my-auto">
        <Briefcase size={16} className="text-[#011513] md:text-white my-auto" />
      </span>
    </button>
  );
};

export default TalkToDedicatedLawyerButton;
