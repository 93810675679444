import React, { useEffect } from "react";
import Lottie from "react-lottie";

const Shimmer = (props) => {
  const [text, setText] = React.useState("");
  const { defaultOptions, sentences } = props;

  useEffect(() => {
    var cards = [];
    if (!sentences || sentences.length === 0) {
      cards = ["Reading chat", "Thinking", "Using Playbook", "Calling ChatGPT", "Drafting"];
    } else {
      cards = sentences;
    }
    for (let i = 0; i < cards.length; i++) {
      setTimeout(() => {
        setText(cards[i] + "...");
      }, 5000 * i);
    }
  }, [sentences]);

  // it should iterate these: “Reading chat, Thinking, Using Playbook, calling ChatGPT,  Drafting”
  return (
    <div className="mt-[12px] md:w-[736px] mx-auto">
      <div className=" flex flex-row justify-items-start gap-[10px] px-[14px]">
        <div className="w-[32px]">
          <Lottie options={defaultOptions} height={32} width={32} className="my-auto" />
        </div>
        <p className="text-[14px] font-[400] my-auto">{text}</p>
      </div>
    </div>
  );
};

export default Shimmer;
