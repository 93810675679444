import React from "react";
import { Check } from "@phosphor-icons/react";
import { useSelector } from "react-redux";
import { PLANS, PLANS_V2 } from "../Constants";

const DocUnlockedPrompt = () => {
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  return (
    <div className="flex flex-row bg-[#faf6e8] gap-[12px] rounded-[8px] px-[10px] py-[10px] my-[15px]">
      <span className="pt-1">
        <Check size={14} className="text-[#248370]" />
      </span>
      <p className="text-[14px] font-[500] leading-[25.6px] text-[#137513]">
        You’ve successfully unlocked the document by upgrading to {PLANS_V2[userMetadata?.subscription_type]?.baseName}!
      </p>
    </div>
  );
};

export default DocUnlockedPrompt;
