import { Check, Confetti, OfficeChair, SealCheck } from "@phosphor-icons/react";
import React, { useEffect } from "react";

import bannerBg from "src/images/WlcmToCounselBannerBg.svg";

import { PLANS, PLAN_TYPES, plans, userData } from "src/components/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserMetadata,
  setShowBusinessPlanUnlocked,
  setShowFindBestLawyer,
  setShowScheduleMeeting,
} from "src/pages/onboarding/onboardingSlice";
import { changePaymentSuccessModalView } from "src/pages/chatpage/chatSlice";
import { trackAnalytics } from "src/pages/helpers";
import applicationStore from "src/persistence/stores/RootStore";

const BusinessPlanUnlocked = () => {
  const dispatch = useDispatch();
  const selectedPaymentPlanName = useSelector((state) => state.chat.selectedPaymentPlanName);
  const showBusinessPlanUnlocked = useSelector((state) => state.onboarding.showBusinessPlanUnlocked);
  const email = useSelector((state) => state.homeslice.email);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  const [plan, setPlan] = React.useState(null);

  useEffect(() => {
    if (selectedPaymentPlanName) {
      setPlan(userData.paymentSuccessModal?.[selectedPaymentPlanName]);
    }
  }, [selectedPaymentPlanName, showBusinessPlanUnlocked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showBusinessPlanUnlocked) {
      console.log("showBusinessPlanUnlocked");
      trackAnalytics("Payment Successful", { user_email: email, plan: selectedPaymentPlanName }, userMetadata);
      setTimeout(() => {
        takeAction(selectedPaymentPlanName);
      }, 5000);
    }
  }, [showBusinessPlanUnlocked]); // eslint-disable-line react-hooks/exhaustive-deps

  const takeAction = (selectedPaymentPlanName) => {
    const metadata = applicationStore.getState().onboarding.userMetadata;
    const metadataFetchStatus = applicationStore.getState().onboarding.userMetadataFetchStatus;
    if (selectedPaymentPlanName === PLANS.SUBSCRIBER_BUSINESS) {
      // for the subscriber business plan, we are simply refreshing the page, and this would replace the unlock document prompt with the draft link cta
      // window.location.reload();
      var url = window.location.href;
      url += "?upgrade=true";
      window.location.href = url;
    } else if (selectedPaymentPlanName === PLANS.SUBSCRIBER_ENTERPRISE) {
      // for the enterprise plan, we will now wait for the lawyer details
      if (metadataFetchStatus === "success") {
        if (metadata?.lawyer_info) {
          dispatch(setShowScheduleMeeting({ show: true, lawyer_details: metadata?.lawyer_info }));
          dispatch(changePaymentSuccessModalView(false));
          dispatch(setShowBusinessPlanUnlocked(false));
        } else {
          dispatch(getUserMetadata());
          setTimeout(() => {
            takeAction(selectedPaymentPlanName);
          }, 3000);
        }
      } else {
        setTimeout(() => {
          takeAction(selectedPaymentPlanName);
        }, 3000);
      }
    }
  };

  return (
    <div
      className="h-[540px] rounded-[12px] px-[32px] py-[24px] bg-white shadow-[0px 2px 4px -2px #0000000F] mx-auto flex justify-center items-center gap-[28px] flex-col"
      style={{
        backgroundImage: `url(${bannerBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col gap-[8px]">
        <p className="text-center flex justify-center">
          <Confetti size={32} className="text-[#248370]" />
        </p>
        <p className="font-[lora] italic text-[32px] text-[#18475A] font-bold text-center leading-[38px]">
          {plan?.title}
        </p>
        {plan?.subTitle}
      </div>
      <div>{plan?.redirectText}</div>
      <div className="flex flex-col justify-center items-center w-full my-5">
        <div className="w-4 h-4 border-2 border-t-0 border-black rounded-full animate-spin"></div>
      </div>
    </div>
  );
};

export default BusinessPlanUnlocked;
