import { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changePaymentSuccessModalView } from "../../chatSlice";
import bannerBg from "../../../../images/WlcmToCounselBannerBg.svg";
import { Confetti } from "@phosphor-icons/react";
import { PLANS, PLANS_V2, userData } from "src/components/Constants";
import {
  getUserMetadata,
  setShowBusinessPlanUnlocked,
  setShowScheduleMeeting,
} from "src/pages/onboarding/onboardingSlice";
import applicationStore from "src/persistence/stores/RootStore";
import { trackAnalytics } from "src/pages/helpers";
import { useNavigate } from "react-router-dom";

const PaymentSuccessModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openPaymentSuccessModal = useSelector((state) => state.chat.openPaymentSuccessModal);
  const selectedPaymentPlanName = useSelector((state) => state.chat.selectedPaymentPlanName);
  const email = useSelector((state) => state.homeslice.email);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);

  const [plan, setPlan] = useState(null);

  useEffect(() => {
    if (openPaymentSuccessModal) {
      // console.log("openPaymentSuccessModal");
      trackAnalytics("Payment Successful", { user_email: email, plan: selectedPaymentPlanName }, userMetadata);
      setTimeout(() => {
        takeAction(selectedPaymentPlanName);
        dispatch(changePaymentSuccessModalView(false));
      }, 5000);
    }
  }, [openPaymentSuccessModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const takeAction = (selectedPaymentPlanName) => {
    const metadata = applicationStore.getState().onboarding.userMetadata;
    const metadataFetchStatus = applicationStore.getState().onboarding.userMetadataFetchStatus;
    if (selectedPaymentPlanName === PLANS.SUBSCRIBER_BUSINESS) {
      // for the subscriber business plan, we are simply refreshing the page, and this would replace the unlock document prompt with the draft link cta
      navigate(`${window.location.pathname}?upgrade=true`);
      // window.location.reload();
    } else if (selectedPaymentPlanName === PLANS.SUBSCRIBER_ENTERPRISE) {
      // for the enterprise plan, we will now wait for the lawyer details
      if (metadataFetchStatus === "success") {
        if (metadata?.lawyer_info) {
          console.log("showing scheduled meeting paymentsuccessmodal");
          dispatch(setShowScheduleMeeting({ show: true, lawyer_details: metadata?.lawyer_info }));
          dispatch(changePaymentSuccessModalView(false));
          dispatch(setShowBusinessPlanUnlocked(false));
        } else {
          dispatch(getUserMetadata());
          setTimeout(() => {
            takeAction(selectedPaymentPlanName);
          }, 3000);
        }
        setTimeout(() => {
          takeAction(selectedPaymentPlanName);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (selectedPaymentPlanName) {
      setPlan(PLANS_V2[selectedPaymentPlanName]);
    }
  }, [selectedPaymentPlanName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={openPaymentSuccessModal}
      //   className="draft-document-popup rounded overflow-hidden pb-[24px]"
      className="overflow-hidden !w-[889px]"
      footer={null}
    >
      <div
        className="h-[540px] rounded-[12px] px-[32px] py-[24px] bg-white shadow-[0px 2px 4px -2px #0000000F] mx-auto flex justify-center items-center gap-[28px] flex-col"
        style={{
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex flex-col gap-[8px]">
          <p className="text-center flex justify-center">
            <Confetti size={32} className="text-[#248370]" />
          </p>
          <p className="font-[lora] italic text-[32px] text-[#18475A] font-bold text-center leading-[38px]">
            {plan?.title}
          </p>
          {userData.paymentSuccessModal?.[plan?.name]?.subTitle}
        </div>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
