import Modal from "src/components/Modal";
import { Check, ArrowRight, SealCheck } from "@phosphor-icons/react";
import RightImage from "../../images/Pro-popup-banner-img.svg";
import { useDispatch, useSelector } from "react-redux";
import { setOpenUpgradeToProPopup } from "./threadsSlice";
import DocImage from "src/images/DocImage.png";
import { useEffect, useState } from "react";
import { PLANS, PLANS_V2 } from "src/components/Constants";
import { upgradeButtonClicked } from "../helpers";
import { setPlansToShow, setSourceModal } from "../onboarding/onboardingSlice";
import { setPaymentCancellationScreen } from "./chatSlice";

const UpgradeToProPopup = () => {
  const dispatch = useDispatch();
  const openUpgradeToProPopup = useSelector((state) => state.threads.openUpgradeToProPopup);
  const threadData = useSelector((state) => state.messageSlice.threadData);
  const email = useSelector((state) => state.homeslice.email);
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const plansToShow = useSelector((state) => state.onboarding.plansToShow);

  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);

  const onClose = () => {
    return dispatch(setOpenUpgradeToProPopup(false));
  };

  const handleUpgrade = () => {
    console.log("Upgrade to Pro");
    if (isCheckBoxChecked) {
    } else {
    }
    upgradeButtonClicked(dispatch, threadData, email, userMetadata, plansToShow, "dedicated_plan_popup");
  };

  useEffect(() => {
    if (userMetadata?.subscription_type && userMetadata?.subscription_type !== PLANS.INHOUSE_ASK) {
      var url = window.location.href;
      url += "?upgrade=true";
      window.location.href = url;
    }
  }, [userMetadata]);
  useEffect(() => {
    var key = "";
    if (isCheckBoxChecked) {
      key = PLANS.INHOUSE_COUNSEL;
    } else {
      key = PLANS.INHOUSE_PRO;
    }
    var data = PLANS_V2[key];
    dispatch(setPlansToShow(data));
  }, [isCheckBoxChecked]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Modal
      isOpen={openUpgradeToProPopup}
      bodyClassName={"py-0 !rounded-[16px]"}
      onClose={() => onClose()}
      contentAreaStyles={{ paddingLeft: "0px", paddingRight: "0px", borderRadius: "16px" }}
      bodyStyle={{ paddingBottom: "0px", paddingTop: "0px", borderRadius: "16px" }}
    >
      <div className="w-full md:w-[990px] md:h-[650px] flex md:flex-row flex-col rounded-[16px]">
        <div className="left w-full h-full p-12 flex flex-col justify-between">
          <div className="flex flex-col gap-[40px]">
            <div className="flex flex-col gap-[10px]">
              <p>
                <span className="bg-gradient-to-r from-[#4F45F4] via-[#7E1586] to-[#DA6390] bg-clip-text text-transparent font-medium font-sans text-[12px]">
                  Upgrade to Inhouse Pro
                </span>
              </p>
              <p className="font-[lora] font-bold italic text-[30px] text-[#353535]">
                Your Document is Ready
                {/* Unlock your Breach of <br /> Contract Notice at just <span className="text-[#248370]"> $49.</span> */}
              </p>
            </div>
            <div className="flex gap-[16px] flex-col">
              <ul className="flex gap-[20px] flex-col">
                <li className="flex gap-[10px]">
                  <div className="w-[30px] ">
                    <SealCheck size={19} weight="fill" className="text-[#248370]" />
                  </div>
                  <p className="font-normal text-[14px] font-sans text-[#353535]">
                    <b>Gain the Edge</b> - Strategically crafted line by line, and benchmarked against deal terms in
                    your industry.
                  </p>
                </li>
                <li className="flex gap-[10px]">
                  <div className="w-[30px] ">
                    <SealCheck size={19} weight="fill" className="text-[#248370]" />
                  </div>
                  <p className="font-normal text-[14px] font-sans text-[#353535]">
                    <b>Accuracy Guaranteed</b> - Our Legal AI is tuned through constant lawyer feedback for unmatched
                    accuracy.
                  </p>
                </li>
                <li className="flex ">
                  <div className="w-[30px]">
                    <SealCheck size={19} weight="fill" className="text-[#248370]" />
                  </div>
                  <p className="font-normal text-[14px] font-sans text-[#353535]">
                    <b>Full Control</b> - Save, edit and download
                  </p>
                </li>
                <li className="flex gap-[10px]">
                  <div className="w-[30px] ">
                    <SealCheck size={19} weight="fill" className="text-[#248370]" />
                  </div>
                  <p className="font-normal text-[14px] font-sans text-[#353535]">
                    <b>Get Unlimited Documents</b>- Draft any letter, contract, or legal document anytime with no
                    commitment, cancel anytime.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col bg-[#ECF5F3] rounded-t-[8px]">
            <div className="font-sans font-normal text-[12px]  pt-[14px] pb-[20px] px-[12px] w-full flex flex-row gap-[10px]">
              <input type="checkbox" class="peer hidden" checked={isCheckBoxChecked} />
              <span
                class="w-[20px] h-[20px] rounded-md border-[1px] border-[#248370] peer-checked:bg-[#248370] peer-checked:text-white flex items-center justify-center"
                onClick={(e) => {
                  setIsCheckBoxChecked(!isCheckBoxChecked);
                }}
              >
                {isCheckBoxChecked && <Check size={16} />}
              </span>

              <p>
                Get this reviewed by a lawyer for only <span className="font-[700] text-[#18475A]">$100</span> more
              </p>
            </div>
            <button
              className="w-full flex justify-center items-center gap-[12px] bg-[#1B2B48] p-[12px] rounded-[8px] text-white font-medium text-[14px] font-sans"
              onClick={handleUpgrade}
            >
              Access for {isCheckBoxChecked ? "$149" : "$49"}
              <span>
                <ArrowRight size={18} />
              </span>{" "}
            </button>
          </div>
        </div>
        <div className="right w-full h-full flex flex-col justify-between bg-[#f7f7f7] py-16">
          {threadData?.document?.id ? (
            <div className="flex flex-col gap-[16px] mt-[-40px]">
              <p className="w-full text-center text-[#686868] text-[12px]">Preview</p>
              <div className="flex flex-col w-full  mx-auto  px-3">
                <div className="rounded-t-[14px] flex flex-row w-full px-0 py-0 border border-[#E4E4E4] p-0">
                  <div className="left px-[24px] py-[12px] min-h-[56px] flex">
                    <img src={DocImage} alt="doc" className="w-[32px] h-[32px] object-contain my-auto" />
                  </div>
                  <div className="bg-white  right font-[600] text-[16px] px-[24px] py-[12px] w-full rounded-b-0 rounded-tr-[14px] my-auto min-h-[56px] ">
                    {threadData?.document?.doc_title_shorthand}
                  </div>
                </div>
                <div className="relative w-full h-[500px] border border-[#E4E4E4]">
                  <iframe
                    src={`https://docs.google.com/document/d/${threadData?.document?.id}/preview`}
                    className="absolute top-0 left-0 w-full h-full"
                    title={threadData?.document?.doc_title_shorthand}
                  ></iframe>
                </div>
              </div>
            </div>
          ) : (
            <img src={RightImage} alt="Rating" />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeToProPopup;
