import Modal from "src/components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setShowCallScheduledPopup } from "src/pages/onboarding/onboardingSlice";
import { X } from "@phosphor-icons/react";

const CallScheduledPopup = () => {
  const dispatch = useDispatch();
  const userMetadata = useSelector((state) => state.onboarding.userMetadata);
  const email = useSelector((state) => state.homeslice.email);
  const showCallScheduledPopup = useSelector((state) => state.onboarding.showCallScheduledPopup);

  const closeModal = () => {
    dispatch(setShowCallScheduledPopup(false));
  };
  return (
    <Modal
      isOpen={showCallScheduledPopup}
      bodyClassName={"py-0 !rounded-[16px] !px-[24px]"}
      contentAreaStyles={{ paddingLeft: "0px", paddingRight: "0px", borderRadius: "16px" }}
    >
      <div className="w-full md:max-w-[889px] flex justify-center items-center flex-col gap-[24px] px-[24px] py-[48px] ">
        <div className="cursor-pointer" onClick={() => closeModal()}>
          <X size={32} className="absolute top-5 right-5 cursor-pointer text-[#353535]" />
        </div>
        <img
          src={userMetadata?.lawyer_info?.pre_signed_image_url}
          alt="Lawyer"
          className="w-[92px] h-[92px] border rounded-full"
        />
        <hr />
        <p className="text-[16px] font-normal font-sans text-[#353535] text-center">
          Call scheduled with {userMetadata?.lawyer_info?.name} — your Inhouse Verified Legal Counsel
        </p>
          <p className="font-semibold font-sans text-[18px] text-[#18475A] text-center">17th Feb, 2025 @ 5:00PM</p>
          <hr className="w-[400px] border-gray-500 border-[1px]"/>
        <div className="max-w-[500px] w-full flex flex-col gap-[24px]">
          {/* <p className="text-[14px] font-normal font-sans text-[#353535] text-center">
            {userMetadata?.lawyer_info?.name} will understand your legal concerns over a 30-min conversation to help you
            get clarity on your legal concerns and reach a resolution.
          </p> */}
          <p className="text-[14px] font-normal font-sans text-[#353535] text-center">
          {userMetadata?.lawyer_info?.name} will review your chat and any documents prior to the meetingYou can check your e-mail <span className="text-[#18475A] font-medium">{email} </span> for meeting details{" "}
          </p>
        </div>

        <p className="flex justify-end w-full py-4 b">
          <button
            className={`bg-[#1B2B48] text-white font-medium rounded-lg flex items-center justify-center h-[48px] gap-[12px] py-[12px] px-[12px] disabled:!bg-[#949494]`}
            onClick={() => closeModal()}
          >
            <span className="my-auto w-[105px]">OK, got it!</span>
          </button>
        </p>
      </div>
    </Modal>
  );
};

export default CallScheduledPopup;
