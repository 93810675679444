import React from "react";
import ChatBoxComponent from "./components/ChatBoxComponent";
import AskToDraftModal from "src/components/chat/Modals/AskToDraftModal";
import UpSellFlowPopup from "../LawyerHub/UpsellFlowPopup";
import TopNav from "./Navs/TopNav";
import Lottie from "react-lottie";
import { renderMessage } from "src/components/DocumentViewer/helpers";
import { resetAndStartNewChat } from "../helpers";
import { Plus } from "@phosphor-icons/react";
import UpgradeToUnlockDocument from "./UpgradeToUnlockDocument";
import { useSelector } from "react-redux";
import { setCreationInProgress } from "./messageSlice";
import InhouseCounselIntro from "./InhouseCounselIntro";
import Shimmer from "src/components/Shimmers/Shimmer";

const TryPageChat = (props) => {
  const {
    threadData,
    messageListRef,
    inputMessage,
    setInputMessage,
    onsubmit,
    awaitingResponse,
    showAskToDraftModal,
    showSubscriptionPopup,
    showUpgradingPlan,
    showBusinessPlanUnlocked,
    showNextSteps,
    showScheduleMeeting,
    email,
    threadType,
    setSteps,
    userConfig,
    dispatch,
    defaultOptions,
    setPage,
    referenceDoc,
    handleFileChange,
    uploadPercentage,
    setReferenceDoc,
    setReferenceDocId,
    queryParams,
    generatingDraft,
  } = props;

  const isChatFull = useSelector((state) => state.messageSlice.isChatFull);

  const resetChat = () => {
    setPage("landing");
    resetAndStartNewChat(dispatch);
    setReferenceDocId(null);
    setReferenceDoc(null);
    dispatch(setCreationInProgress(false));
    localStorage.clear();
  };
  return (
    <>
      <TopNav page="try" resetChat={resetChat}></TopNav>
      <div className="flex flex-row w-full mt-[50px]">
        <div className="flex flex-col w-[100%]">
          {!threadData?.id ? (
            <div className="flex flex-col justify-center items-center h-screen gap-[40px]">
              <Lottie options={defaultOptions} height={80} width={80} />
              <p className="font-[500] text-[20px]">Initiating a new chat...</p>
            </div>
          ) : (
            <div className="flex justify-between flex-col h-[calc(100vh-50px)]">
              <>
                <div
                  className="w-full py-[24px] mx-auto overflow-y-auto gap-[12px] flex flex-col md:flex-row"
                  id="scrolling_div"
                  ref={messageListRef}
                >
                  <div className="hidden md:block w-full md:w-[calc((100vw-850px)/2)]">
                    <div
                      className="m-[20px] bg-[#748A8C14] rounded-[50%] flex h-[40px] w-[40px] cursor-pointer md:absolute"
                      onClick={resetChat}
                    >
                      <Plus size={20} className="mx-auto my-auto" />
                    </div>
                  </div>
                  <div className="md:px-0 md:w-[736px] w-full h-[calc(100vh-150px)] px-3">
                    {queryParams.get("utm_source") === "lawyergpt" && (
                      <div className="md:px-0 md:w-[736px] mx-auto">
                        <InhouseCounselIntro />
                      </div>
                    )}
                    {threadData?.messages.map((message, index) => (
                      <div className="md:px-0 md:w-[736px] mx-auto">
                        {renderMessage(
                          message,
                          index,
                          threadData,
                          "full",
                          email,
                          threadType,
                          false,
                          setSteps,
                          userConfig
                        )}
                      </div>
                    ))}
                    {threadData?.document?.isLocked && (
                      <div className="md:px-0 md:w-[736px] mx-auto">
                        <UpgradeToUnlockDocument
                          threadData={threadData}
                          dispatch={dispatch}
                          // metadata={}
                          email={email}
                        />
                      </div>
                    )}
                    {awaitingResponse && !generatingDraft && (
                      <div className="mt-[12px] md:w-[736px] mx-auto">
                        <div className=" flex flex-row justify-items-start gap-[10px] px-[14px]">
                          <div className="w-[32px]">
                            <Lottie options={defaultOptions} height={32} width={32} className="my-auto" />
                          </div>
                          <p className="text-[14px] font-[400] my-auto">Analyzing your request...</p>
                        </div>
                      </div>
                    )}
                    {generatingDraft && <Shimmer defaultOptions={defaultOptions} sentences={[]} />}
                  </div>
                </div>
              </>

              <ChatBoxComponent
                size="full"
                allowAttachReference={threadData?.messages?.length === 0}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                onsubmit={onsubmit}
                referenceDoc={referenceDoc}
                handleFileChange={handleFileChange}
                percentage={uploadPercentage}
                setReferenceDoc={setReferenceDoc}
                fileuploadSuccess={uploadPercentage}
                isChatFull={isChatFull}
                privacyLink={true}
                generatingDraft={generatingDraft}
              />
            </div>
          )}
        </div>
        {showAskToDraftModal && <AskToDraftModal />}

        {(showSubscriptionPopup ||
          showUpgradingPlan ||
          showBusinessPlanUnlocked ||
          showNextSteps ||
          showScheduleMeeting) && <UpSellFlowPopup />}
      </div>
    </>
  );
};

export default TryPageChat;
