import { ArrowSquareOut, Copy, ShieldCheck, VideoCamera } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import Button from "../Button";
import { setShowScheduleMeeting } from "src/pages/onboarding/onboardingSlice";
import { useDispatch } from "react-redux";

const LawyerDetails = ({ userMetadata }) => {
  const [specialities, setSpecialities] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userMetadata && userMetadata?.lawyer_info) {
      try {
        setSpecialities(userMetadata?.lawyer_info?.specialties.split(","));
      } catch (e) {
        console.log(e);
        setSpecialities(userMetadata?.lawyer_info?.specialties);
      }
    }
  }, [userMetadata]); // eslint-disable-line
  return (
    <div className="pt-6">
      <p className="text-lg font-semibold text-center">{userMetadata?.lawyer_info?.name}</p>
      <div className="flex items-center justify-center mt-6 ">
        <div className="w-20 h-20 overflow-hidden bg-gray-200 rounded-full">
          <img
            alt="lawyer pic"
            className="object-cover w-full h-full"
            src={userMetadata?.lawyer_info?.pre_signed_image_url}
          />
        </div>
      </div>

      <div className="px-6 mt-4 flex items-center justify-center  text-[#011513] font-semibold text-lg text-center">
        {userMetadata?.lawyer_info?.name} <ShieldCheck className="ml-2" size={18} />
      </div>
      <div className="px-6 mt-1 text-[#18475A]  flex justify-center  text-sm font-normal text-center">
        <div className="w-fit bg-[#14866F]/10 py-1 px-3 rounded-xl">
          <span className="font-semibold text-[#353535]">${userMetadata?.lawyer_info?.hourly_rate}/hr</span> for
          additional tasks
        </div>
      </div>

      <hr className="px-6 mt-6 text-gray-300 " />

      <div className="px-6 pb-6">
        <h6 className="text-xs mt-6 text-[#686868] font-medium">Education</h6>
        <p className="text-sm text-[#353535] font-medium mt-1">{userMetadata?.lawyer_info?.education}</p>

        <h6 className="text-xs mt-6 text-[#686868] font-medium">Experience</h6>
        <p className="text-sm text-[#353535] font-medium mt-1">{userMetadata?.lawyer_info?.experience}</p>

        <h6 className="text-xs mt-6 text-[#686868] font-medium">Jurisdiction of Practice</h6>
        <p className="text-sm text-[#353535] font-medium mt-1">{userMetadata?.lawyer_info?.state_bar}</p>

        <h6 className="text-xs mt-6 text-[#686868] font-medium">Specialization</h6>
        <p className="text-sm text-[#353535] font-medium mt-1">
          <ul>
            {specialities?.map((specialty, index) => (
              <li
                className="text-[#686868] rounded-xl bg-[#FAFAFA] whitespace-nowrap text-xs font-medium px-2 py-1 mt-2"
                key={index}
              >
                {specialty}
              </li>
            ))}
          </ul>
        </p>

        <h6 className="text-xs mt-6 text-[#686868] font-medium">Contact Information</h6>
        <div className="text-sm flex items-center text-[#353535] font-medium mt-1">
          <span className="font-semibold">Email</span> : {userMetadata?.lawyer_info?.email}{" "}
          <ArrowSquareOut className="ml-2" size={16} />
        </div>
        <div className="text-sm flex items-center text-[#353535] font-medium mt-1">
          <span className="font-semibold">Phone No </span> : {userMetadata?.lawyer_info?.phone_number}{" "}
          <Copy size={16} />
        </div>

        <Button
          onClick={() => {
            dispatch(
              setShowScheduleMeeting({
                show: true,
                lawyer_details: { calendly_link: userMetadata?.lawyer_info?.calendly_link },
              })
            );
          }}
          className={"w-full mt-6 flex justify-center"}
          text={
            <>
              <VideoCamera className="mr-2" size={16} />
              Schedule a call
            </>
          }
        />
      </div>
    </div>
  );
};

export default LawyerDetails;
