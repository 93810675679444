import Modal from "../../components/Modal";
import lawyersImage from "../../images/SignupPopupImg.svg";
import ratingStars from "../../images/Rating-stars.svg";
import { useDispatch, useSelector } from "react-redux";
// import { setOpenSignupPopup } from "./threadsSlice";
import { useEffect, useState } from "react";
import AuthenticationBase from "../UserAuthentication";
import { SignIn, SignUp, useUser } from "@clerk/clerk-react";
import InHouseLogo from "src/images/inhouse-logo-v2.png";
import { getCookie } from "../helpers";
import { setShowSignupToUpgradeModal, setSignupPopupSlide } from "../onboarding/onboardingSlice";

const SignupPopup = () => {
  const dispatch = useDispatch();
  const { isLoaded } = useUser();

  const openSignupPopup = useSelector((state) => state.onboarding.openSignupPopup);
  const intent = useSelector((state) => state.onboarding.intent);
  const slide = useSelector((state) => state.onboarding.signupPopupSlide);

  // const [slide, setSlide] = useState("signup");
  const [showMessage, setShowMessage] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [signinRedirectUrl, setSigninRedirectUrl] = useState(null);
  const [signinUrl, setSigninUrl] = useState(null);

  const [signupRedirectUrl, setSignupRedirectUrl] = useState(null);
  const [signupUrl, setSignupUrl] = useState(null);

  // redirect urls:
  // 1. when the intent was only to get the user to signup : no popup after signup / login
  // 2. when the intent was to upgrade to inhouse pro: show pro popup after signup / login
  // 3. when the intent was to upgrade to inhouse counsel: show counel popup after signup / login

  // login  --> post-auth -->(onboarding availablne) --> threadpage with popup
  // signup  --> post-auth -->(personal information not availablne) --> onboarding --> threadpage with popup

  useEffect(() => {
    if (openSignupPopup) {
      var threadId = JSON.parse(localStorage.getItem("thread_id"));
      switch (intent) {
        case "inhouse_pro":
          setSignupRedirectUrl(`/post-auth?next=pro&thread_id=${threadId}`);
          setSignupUrl(`/signup?next=pro&thread_id=${threadId}`);
          setRedirectUrl(`/post-auth?next=pro&thread_id=${threadId}`);
          setSigninRedirectUrl(`/post-auth?next=pro&thread_id=${threadId}`);
          setSigninUrl(`/signin?next=pro&thread_id=${threadId}`);
          break;
        case "inhouse_counsel":
          setSignupRedirectUrl(`/post-auth?next=counsel&thread_id=${threadId}`);
          setSignupUrl(`/signup?next=counsel&thread_id=${threadId}`);
          setRedirectUrl(`/post-auth?next=counsel&thread_id=${threadId}`);
          setSigninRedirectUrl(`/post-auth?next=counsel&thread_id=${threadId}`);
          setSigninUrl(`/signin?next=counsel&thread_id=${threadId}`);
          break;
        default:
          setSignupRedirectUrl(`/post-auth?thread_id=${threadId}`);
          setSignupUrl(`/signup?thread_id=${threadId}&next=counsel`);
          setRedirectUrl(`/post-auth?thread_id=${threadId}&next=counsel`);
          setSigninRedirectUrl(`/post-auth?thread_id=${threadId}&next=counsel`);
          setSigninUrl(`/signin?thread_id=${threadId}&next=counsel`);
          break;
      }
    }
  }, [openSignupPopup]);

  const closePopup = () => {
    // return dispatch(setOpenSignupPopup(false));
    dispatch(setShowSignupToUpgradeModal({ show: false, plans: {} }));
  };
  return (
    <Modal
      contentAreaStyles={{ paddingLeft: "0px", paddingRight: "0px", borderRadius: "16px" }}
      isOpen={openSignupPopup}
      bodyStyle={{ paddingBottom: "0px", borderRadius: "16px", paddingTop: "0px" }}
      className="w-[700px] h-full"
      onClose={() => {
        closePopup();
      }}
    >
      {/* <div className="flex justify-end px-[24px] py-[24px]">
        <p>
          fallbackRedirectUrl={redirectUrl}
          signInFallbackRedirectUrl={signinRedirectUrl}
          signInUrl={signinUrl}
        </p>
      </div> */}
      {slide === "default" && (
        <div className="w-full md:w-[454px] h-[550px] flex flex-col">
          <div className="top w-full h-[240px] bg-[#ECF5F3] flex flex-col justify-center items-center gap-[32px] px-[32px] py-[24px] ">
            <img src={lawyersImage} alt="Lawyer image" />
            <div>
              <img src={ratingStars} alt="Rating" />
              <p className="font-sans font-medium text-[10px] text-[#353535]">
                Rated by <span className="font-bold">2,400+ </span>clients
              </p>
            </div>
          </div>
          <div className="bottom w-full h-[310px] px-[32px] py-[24px] flex flex-col gap-[52px]">
            <div className="flex flex-col gap-[12px]">
              <p className="font-[lora] italic font-bold text-[24px] text-center text-[#111111]">
                Sign up to talk to an <br /> expert IP lawyer
              </p>
              <p className="text-[#686868] font-sans font-normal leading-[19px] text-center text-[14px]">
                Have an intellectual property lawyer help you <br /> with your legal queries.
              </p>
            </div>
            <div className="flex flex-col gap-[16px]">
              <button
                className="rounded-[24px] px-[24px] py-[16px] bg-[#1B2B48] text-white w-full text-[12px] font-medium text-center"
                onClick={() => {
                  dispatch(setSignupPopupSlide("signup"));
                }}
              >
                Sign Up
              </button>
              <p className="font-sans text-[12px] font-normal text-center text-[#666D80]">
                <p>
                  Already have an account?{" "}
                  <span
                    className="text-[#1B2B48] font-semibold"
                    onClick={() => {
                      dispatch(setSignupPopupSlide("login"));
                    }}
                  >
                    Log In
                  </span>
                </p>
              </p>
            </div>
          </div>
        </div>
      )}
      {slide === "signup" && (
        <AuthenticationBase>
          <div className="flex flex-col justify-between md:overflow-y-scroll md:px-10 px-auto mt-4">
            {isLoaded ? (
              <>
                <div>
                  {/* <a href="https://www.inhouse.app">
                    <img src={InHouseLogo} className="w-[120px] h-[42px] object-contain" />
                  </a> */}
                  <div className="px-auto mx-auto align-items-center items-center signup-pop-signup">
                    <SignUp
                      routing="virtual"
                      fallbackRedirectUrl={redirectUrl}
                      signInFallbackRedirectUrl={signinRedirectUrl}
                      signInUrl={signinUrl}
                    />
                    {showMessage && (
                      <p className="text-[12px] leading-[18px] text-[#686868] w-full relative   p-2">
                        By proceeding, you agree to our{" "}
                        <a
                          href="https://www.inhouse.app/terms-of-service"
                          rel="noopener noreferrer"
                          className="underline cursor-pointer"
                          target="blank"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.inhouse.app/privacy-policy"
                          rel="noopener noreferrer"
                          className="underline cursor-pointer"
                          target="blank"
                        >
                          {" "}
                          Privacy Policy
                        </a>{" "}
                        , including the use of your information and consent to receive SMS messages.
                      </p>
                    )}
                  </div>
                </div>
                {/* <p className="text-[#666D80] text-sm font-normal text-center">© 2025 Inhouse. All Rights Reserved</p> */}
              </>
            ) : (
              <div className="flex flex-col justify-between h-full px-10 py-10">
                <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin mx-auto my-auto"></div>
              </div>
            )}
          </div>
        </AuthenticationBase>
      )}
      {slide === "login" && (
        <AuthenticationBase>
          <div className="flex flex-col justify-between md:h-screen md:overflow-y-scroll md:px-10 md:py-6 px-auto py-auto">
            {isLoaded ? (
              <>
                <div>
                  <div className="px-auto mx-auto align-items-center items-center">
                    <SignIn
                      routing="virtual"
                      fallbackRedirectUrl={redirectUrl}
                      signInFallbackRedirectUrl={signinRedirectUrl}
                      signInUrl={signinUrl}
                    />
                    {showMessage && (
                      <p className="text-[12px] leading-[18px] text-[#686868] w-full relative -top-12  p-2">
                        By proceeding, you agree to our{" "}
                        <a
                          href="https://www.inhouse.app/terms-of-service"
                          rel="noopener noreferrer"
                          className="underline cursor-pointer"
                          target="blank"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.inhouse.app/privacy-policy"
                          rel="noopener noreferrer"
                          className="underline cursor-pointer"
                          target="blank"
                        >
                          {" "}
                          Privacy Policy
                        </a>{" "}
                        , including the use of your information and consent to receive SMS messages.
                      </p>
                    )}
                  </div>
                </div>
                <p className="text-[#666D80] text-sm font-normal text-center">© 2025 Inhouse. All Rights Reserved</p>
              </>
            ) : (
              <div className="flex flex-col justify-between h-full px-10 py-10">
                <div className="w-8 h-8 border-2 border-t-0 border-[#000] rounded-full animate-spin mx-auto my-auto"></div>
              </div>
            )}
          </div>
        </AuthenticationBase>
      )}
    </Modal>
  );
};

export default SignupPopup;
